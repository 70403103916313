import { formatLocalDateTime } from '@/lib/date';

import { Icon } from '../../components/icon';
import { IconButton } from '../../components/button';
import { List, ListItem } from '../common';
import { getCreatedAtLabel } from '../utils';

import styles from './list.module.scss';

export interface UserListItem {
  userId: string;
  email: string;
  addedAt: string;
  role: { roleId: string; name: string };
}

interface UserListProps {
  users: UserListItem[];
  canEdit: boolean;
  onEdit: (user: UserListItem) => void;
}

export const UserList = (props: UserListProps) => (
  <List
    numColumns={props.canEdit ? 5 : 4}
    columnWidths={props.canEdit ? { 0: 'min-content', [-1]: 'min-content' } : { 0: 'min-content' }}>
    {props.users
      .sort((a, b) => (a.addedAt > b.addedAt ? 1 : -1))
      .map((user) => (
        <ListItem key={user.userId}>
          <div>
            <div className={styles.avatar}>
              <Icon name="User" size={16} />
            </div>
          </div>
          <div className={styles.email}>{user.email}</div>
          <div className={styles.role}>{user.role.name}</div>
          <div className={styles.createdAt} title={formatLocalDateTime(user.addedAt)}>
            {getCreatedAtLabel(user.addedAt)}
          </div>
          {props.canEdit && (
            <div>
              <IconButton
                icon="Edit3"
                title="Edit user"
                iconSize="regular"
                onClick={() => props.onEdit(user)}
              />
            </div>
          )}
        </ListItem>
      ))}
  </List>
);
