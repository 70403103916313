import { RefObject, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ScrollToButton } from '../../components/button';

import styles from './exploration.module.scss';
import { headerHeight } from '../../components/header/header.module.scss';

const HEADER_HEIGHT = parseInt(headerHeight, 10);

export const ScrollToSelected = ({
  cellIndex,
  cellRef,
  direction,
  onClick,
}: {
  cellIndex: number | null;
  cellRef: RefObject<Element | null>;
  direction?: 'up' | 'down';
  onClick: () => void;
}) => {
  const [cellDirection, setCellDirection] = useState<'up' | 'down' | null>(null);
  useEffect(() => {
    const onScroll = () => {
      if (cellRef.current === null) {
        return null;
      }
      const { top, bottom } = cellRef.current.getBoundingClientRect();
      if (bottom < HEADER_HEIGHT) {
        setCellDirection('up');
      } else if (top > window.innerHeight) {
        setCellDirection('down');
      } else {
        setCellDirection(null);
      }
    };
    onScroll();
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll);
  });
  if (cellIndex === null || cellRef.current === null) {
    return null;
  }
  return (
    <aside
      className={classNames(styles.scrollToSelected, {
        [styles.visible]:
          cellDirection !== null && (direction === undefined || direction === cellDirection),
      })}>
      <ScrollToButton label="Selected block" direction={cellDirection ?? 'up'} onClick={onClick} />
    </aside>
  );
};
