import { Fragment, useState } from 'react';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { range, uniq } from 'lodash';
import { BarRounded } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { Text } from '@visx/text';
import { Group } from '@visx/group';
import { AxisLeft } from '@visx/axis';
import { Grid } from '@visx/grid';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { ScaleLinear } from 'd3-scale';

import classNames from 'classnames';

import { formatPropertyValue } from '@/explore/utils/format';

import { BasicLegend } from '../legend';
import { ChartTooltip } from '../common';

import commonStyles, {
  gridLineColor,
  funnelBarColor1,
  funnelBarColor2,
  funnelBarColor3,
  funnelBarColor4,
  funnelBarColor5,
  funnelBarColor6,
  funnelTextColor1,
  funnelTextColor2,
  funnelTextColor3,
  funnelTextColor4,
  funnelTextColor5,
  funnelTextColor6,
  funnelBarHoverColor,
} from '../charts.module.scss';
import styles from './funnel-chart.module.scss';

type FunnelTooltipData = {
  uniques: number;
  dropoff?: number;
  retained?: number;
  label?: string;
};

interface FunnelChartProps {
  data: { label: string; values: { label: string; value: number }[] }[];
  height?: number;
  isResized: boolean;
}

type ChartProps = FunnelChartProps & { parent: { width: number; height: number }; keys: string[] };

const ChartLeft = 40;

const Chart = (props: ChartProps) => {
  const {
    data,
    parent: { width, height },
    keys,
  } = props;

  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<FunnelTooltipData>();

  if (data.length === 0) {
    return null;
  }

  const stepCount = data.length;

  const chartWidth = width;
  const chartHeight = height - 30;

  const maxValue = Math.max(...data.map((step) => step.values.map((item) => item.value)).flat());
  const valueScale = scaleLinear({
    domain: [0, maxValue],
    range: [0, chartHeight],
    round: true,
  });

  const relativeMaxValues = data.reduce(
    (acc, step) => {
      step.values.forEach((item) => {
        acc[item.label] = Math.max(acc[item.label] ?? item.value, item.value);
      });

      return acc;
    },
    {} as Record<string, number>,
  );
  const relativeValueScales = Object.keys(relativeMaxValues).reduce<
    Record<string, ScaleLinear<number, number>>
  >((acc, key) => {
    acc[key] = scaleLinear({ domain: [0, relativeMaxValues[key]], range: [0, chartHeight] });

    return acc;
  }, {});

  const stepScale = scaleBand({
    domain: range(0, stepCount),
    range: [0, chartWidth - ChartLeft],
    padding: 0.05,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [
      funnelBarColor1,
      funnelBarColor2,
      funnelBarColor3,
      funnelBarColor4,
      funnelBarColor5,
      funnelBarColor6,
    ],
  });
  const labelColorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [
      funnelTextColor1,
      funnelTextColor2,
      funnelTextColor3,
      funnelTextColor4,
      funnelTextColor5,
      funnelTextColor6,
    ],
  });

  const handleMouseMove = (event: any, data: FunnelTooltipData) => {
    const coords = localPoint(event.target, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.y, tooltipTop: coords.x, tooltipData: data });
    return true;
  };

  const grouped = data[0].values.length > 1;

  return (
    <div className={styles.funnelChart}>
      <svg width={width} height={height}>
        <AxisLeft
          left={0}
          scale={valueScale}
          hideTicks
          hideAxisLine
          tickValues={[0.2 * maxValue, 0.4 * maxValue, 0.6 * maxValue, 0.8 * maxValue]}
          tickLabelProps={() => ({
            dx: 12,
            dy: 7,
            textAnchor: 'start',
            verticalAnchor: 'middle',
          })}
          tickClassName={commonStyles.tickLabel}
          tickFormat={(n) => Math.round(((maxValue - n.valueOf()) / maxValue) * 100) + '%'}
        />
        <Grid
          width={width}
          height={chartHeight}
          xScale={stepScale}
          yScale={valueScale}
          rowTickValues={[0.2 * maxValue, 0.4 * maxValue, 0.6 * maxValue, 0.8 * maxValue, maxValue]}
          stroke={gridLineColor}
          numTicksColumns={0}
        />
        <Group left={ChartLeft}>
          {data.map((step, stepIndex) => {
            const prevStep = data[stepIndex - 1];
            return (
              <Fragment key={stepIndex}>
                {step.values.map((item, itemIndex) => {
                  const x =
                    stepScale.step() * stepIndex +
                    (stepScale.bandwidth() / step.values.length + 4) * itemIndex;
                  const adjustedValueScale = relativeValueScales[item.label];
                  const scaledHeight = adjustedValueScale(item.value);
                  const y = chartHeight - scaledHeight;
                  const barColor = colorScale(item.label);
                  const textColor = labelColorScale(item.label);
                  const width = stepScale.bandwidth() / step.values.length;
                  const prevItem = prevStep?.values.find(
                    (prevItem) => prevItem.label === item.label,
                  );
                  const dropOffHeight = prevItem
                    ? adjustedValueScale(prevItem.value - item.value)
                    : 0;
                  const label = `${
                    prevItem
                      ? prevItem.value > 0
                        ? Math.round((item.value / prevItem.value) * 100)
                        : 0
                      : 100
                  }% (${formatPropertyValue(item.value, { type: 'Integer' })})`;
                  const itemId = `${stepIndex}-${itemIndex}`;
                  return (
                    <Fragment key={itemIndex}>
                      <Group
                        left={x}
                        top={y}
                        onMouseOver={() => setHoveredItemId(itemId)}
                        onMouseMove={(e) =>
                          handleMouseMove(e, {
                            retained: prevItem ? item.value / prevItem.value : 1,
                            uniques: item.value,
                            label: grouped ? item.label : undefined,
                          })
                        }
                        onMouseOut={() => {
                          hideTooltip();
                          setHoveredItemId(null);
                        }}>
                        <BarRounded
                          x={0}
                          y={0}
                          width={width}
                          height={scaledHeight}
                          fill={barColor}
                          stroke={funnelBarHoverColor}
                          strokeWidth={hoveredItemId === itemId ? 1 : 0}
                          radius={4}
                          top
                        />
                        <Text
                          verticalAnchor="start"
                          x={6}
                          y={6}
                          fill={textColor}
                          style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }}>
                          {label}
                        </Text>
                      </Group>
                      {prevItem !== undefined && dropOffHeight !== 0 && (
                        <Group
                          onMouseOver={() => setHoveredItemId(step.values.length + itemId)}
                          onMouseMove={(e) =>
                            handleMouseMove(e, {
                              dropoff: 1 - item.value / prevItem.value,
                              uniques: prevItem.value - item.value,
                              label: grouped ? item.label : undefined,
                            })
                          }
                          onMouseOut={() => {
                            hideTooltip();
                            setHoveredItemId(null);
                          }}>
                          <LinearGradient
                            id={`gradient-${barColor}`}
                            from={barColor}
                            to={barColor}
                            fromOpacity={0.4}
                            toOpacity={0.2}
                          />
                          <BarRounded
                            x={x}
                            y={y - dropOffHeight}
                            width={width}
                            height={dropOffHeight - 2}
                            fill={`url(#gradient-${barColor})`}
                            stroke={funnelBarHoverColor}
                            strokeWidth={hoveredItemId === step.values.length + itemId ? 1 : 0}
                            radius={4}
                            all
                          />
                        </Group>
                      )}
                    </Fragment>
                  );
                })}
                <Group left={stepIndex * stepScale.step()} top={chartHeight + 12}>
                  <Text
                    verticalAnchor="start"
                    fill="#F0F0F2"
                    style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 12 }}>
                    {`${stepIndex + 1} ${step.label}`}
                  </Text>
                </Group>
              </Fragment>
            );
          })}
        </Group>
      </svg>
      {tooltipOpen && (
        <ChartTooltip
          top={tooltipLeft ?? 0}
          left={tooltipTop ?? 0}
          offsetX={30}
          title={
            tooltipData?.dropoff !== undefined ? (
              <>
                <b>{Math.round((tooltipData?.dropoff ?? 0) * 100)}%</b> dropoff
              </>
            ) : (
              <>
                <b>{Math.round((tooltipData?.retained ?? 0) * 100)}%</b> retained
              </>
            )
          }>
          <div>{tooltipData?.label}</div>
          <div>
            <b>{formatPropertyValue(tooltipData?.uniques ?? 0, { type: 'Integer' })}</b> uniques
          </div>
        </ChartTooltip>
      )}
      {keys.length > 1 && (
        <div className={styles.footer}>
          <BasicLegend
            items={keys.map((key) => ({
              label: key,
              color: colorScale(key),
            }))}
          />
        </div>
      )}
    </div>
  );
};

export const FunnelChart = (props: FunnelChartProps) => {
  const keys = uniq(props.data.map((step) => step.values.map((item) => item.label)).flat());

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(commonStyles.singleChart, {
        [commonStyles.customHeight]: props.isResized,
        [commonStyles.legendPadding]: keys.length > 1,
      })}>
      {(parent) => <Chart {...props} parent={parent} keys={keys} />}
    </ParentSize>
  );
};
