import { useState } from 'react';

import { LocalSettingKey, useLocalSetting } from '@/lib/hooks/use-local-setting';

import { CloseButton, InlineButton } from '../button';
import { Penguin } from '../penguin';

import styles from './tip.module.scss';

interface TipProps {
  disableKey?: LocalSettingKey;
  children: React.ReactNode;
}

export function Tip({ disableKey, children }: TipProps) {
  const [isRemoved, setIsRemoved] = useState(false);
  const [disabledAt, setDisabledAt] = useLocalSetting<string | undefined>(
    disableKey ?? null,
    undefined,
  );

  if (isRemoved || disabledAt !== undefined) {
    return null;
  }

  return (
    <div className={styles.tip} tabIndex={-1}>
      <Penguin className={styles.penguin} />
      <div className={styles.content}>
        {children}
        {disableKey !== undefined && (
          <InlineButton
            className={styles.disableButton}
            onClick={() => {
              setIsRemoved(true);
              setDisabledAt(new Date().toISOString());
            }}>
            Don’t remind me again
          </InlineButton>
        )}
      </div>
      <CloseButton
        iconSize="regular"
        className={styles.closeButton}
        onClick={() => setIsRemoved(true)}
        tabIndex={-1}
      />
    </div>
  );
}
