import { createContext, useCallback, useContext, useState } from 'react';

import { useCreateAnalyticsEventMutation } from '@/graphql';

import { useSelectedAccount } from '../accounts/context';

const AnalyticsContext = createContext<{
  properties: { [key: string]: unknown };
}>({
  properties: {},
});

const useAnalyticsContext = () => useContext(AnalyticsContext);

/**
 * Allows setting extra/default properties that will get tracked with all events in child components.
 */
export const AnalyticsContextProvider = ({
  properties,
  children,
}: {
  properties: { [key: string]: unknown };
  children: React.ReactNode;
}) => {
  return <AnalyticsContext.Provider value={{ properties }}>{children}</AnalyticsContext.Provider>;
};

const PageViewContext = createContext<{
  pageViewId?: string;
}>({
  pageViewId: undefined,
});

const usePageViewContext = () => useContext(PageViewContext);

/**
 * Provides analytics tracking calls in children with a unique pageViewId until the component
 * is destroyed.
 */
export const PageViewContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageViewId] = useState<string>(crypto.randomUUID());
  return <PageViewContext.Provider value={{ pageViewId }}>{children}</PageViewContext.Provider>;
};

export const useTrackEvent = () => {
  const [createEvent] = useCreateAnalyticsEventMutation();
  const selectedAccount = useSelectedAccount();
  const { properties: contextProperties } = useAnalyticsContext();
  const { pageViewId } = usePageViewContext();

  return useCallback(
    (eventName: string, properties?: { [key: string]: unknown }) => {
      createEvent({
        variables: {
          accountId: selectedAccount.accountId,
          input: {
            name: eventName,
            properties: { ...contextProperties, pageViewId, ...properties },
          },
        },
        fetchPolicy: 'no-cache',
      });
    },
    [createEvent, contextProperties, pageViewId, selectedAccount.accountId],
  );
};
