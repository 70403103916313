import classNames from 'classnames';

import styles from './common.module.scss';

/**
 * Utility function for imitating colspan for the List component.
 * Usage: <ListItem> ... <div style={listColSpan(2)}>...</div> ... </ListItem>
 */
export const listColSpan = (span: number) => ({
  gridColumnEnd: `span ${span}`,
});

/**
 * Utility function for imitating rowspan for the List component.
 * Usage: <ListItem> ... <div style={listRowSpan(2)}>...</div> ... </ListItem>
 */
export const listRowSpan = (span: number) => ({
  gridRowEnd: `span ${span}`,
});

interface ListItemProps {
  className?: string;
  children: React.ReactNode;
}

export const ListItem = (props: ListItemProps) => (
  <li className={classNames(styles.listItem, props.className)}>{props.children}</li>
);

interface ListProps {
  numColumns: number;
  className?: string;
  children: React.ReactNode;
  columnWidths?: { [key: number]: string };
}

/**
 * Common list component that aligns and auto-sizes columns.
 *
 * Pass in { [columnIndex]: '<width>' } to columnWidths to override the auto-sizing.
 * Also supports negative indexes counting backwards.
 *
 * The children of this component should be ListItems as rows with {numColumns} children
 * of any type acting as cells.
 */
export const List = (props: ListProps) => {
  const { numColumns, className, children, columnWidths } = props;
  const generateGridColumns = (numColumns: number) => {
    let columns = new Array(numColumns).fill('auto');
    if (columnWidths) {
      columns = columns.map((c, i) => columnWidths[i] ?? columnWidths[i - numColumns] ?? c);
    }
    return columns.join(' ');
  };

  return (
    <ul
      className={classNames(styles.list, className)}
      style={{ gridTemplateColumns: generateGridColumns(numColumns) }}>
      {children}
    </ul>
  );
};
