import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullPageLoader } from '../../components/loader';
import { useAuth } from '../../auth/auth-context';

export const LogoutPage = () => {
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const logOutCalled = useRef(false);

  useEffect(() => {
    if (!logOutCalled.current) {
      logOut();
    }

    logOutCalled.current = true;
  }, [navigate, logOut]);

  return <FullPageLoader />;
};
