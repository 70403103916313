import { formatDistance } from 'date-fns';
import { useCopyToClipboard } from 'usehooks-ts';

import { AccessToken } from '@/graphql';
import { formatLocalDateTime } from '@/lib/date';
import { isNil } from '@/lib/utils';

import { IconButton } from '../../components/button';
import { Alert } from '../../components/alert';
import { List, ListItem, listColSpan } from '../common';

import styles from './list.module.scss';

const getExpiresLabel = (expiresAt?: string | null) => {
  if (expiresAt === undefined || expiresAt === null) {
    return 'No expiration date';
  }

  const date = new Date(expiresAt);

  if (date <= new Date()) {
    return 'Expired at ' + date.toLocaleDateString();
  }

  return `Expires ${formatDistance(date, new Date(), { addSuffix: true })}`;
};

const getLastUsedLabel = (lastUsedAt?: string | null) => {
  if (lastUsedAt === undefined || lastUsedAt === null) {
    return 'Never';
  }

  return `Last used ${formatDistance(new Date(lastUsedAt), new Date(), {
    addSuffix: true,
  })}`;
};

interface CreatedAlertProps {
  token: string;
}

const CreatedAlert = (props: CreatedAlertProps) => {
  const { token } = props;
  const [copiedValue, handleCopy] = useCopyToClipboard();

  return (
    <Alert title="Token Created" kind="success" iconName="Check">
      <div className={styles.alertContent}>
        <div>This is the only time you will be able to see the token. Please copy it now.</div>
        <div className={styles.token}>{token}</div>

        {copiedValue !== null ? (
          <div>Copied!</div>
        ) : (
          <div className={styles.copyButton} onClick={() => handleCopy(token)}>
            Copy token
          </div>
        )}
      </div>
    </Alert>
  );
};

interface AccessTokenListProps {
  accessTokens: AccessToken[];
  createdAccessToken?: {
    accessToken: AccessToken;
    token: string;
  };
  onDelete: (id: string) => void;
}

export const AccessTokenList = (props: AccessTokenListProps) => {
  const { accessTokens, createdAccessToken, onDelete } = props;
  return (
    <List numColumns={5} columnWidths={{ [-1]: 'min-content' }}>
      {accessTokens.map(({ name, preview, accessTokenId, expiresAt, lastUsedAt }) => {
        return (
          <ListItem key={accessTokenId}>
            <div className={styles.name}>{name}</div>
            <div className={styles.preview}>{preview}...</div>
            <div>{getExpiresLabel(expiresAt)}</div>
            <div
              className={styles.lastUsed}
              title={isNil(lastUsedAt) ? '' : formatLocalDateTime(lastUsedAt)}>
              {getLastUsedLabel(lastUsedAt)}
            </div>
            <div>
              <IconButton
                icon="Trash2"
                title="Delete API key"
                iconSize="regular"
                onClick={() => onDelete(accessTokenId)}
              />
            </div>
            {createdAccessToken &&
              createdAccessToken.accessToken.accessTokenId === accessTokenId && (
                <div className={styles.alertWrapper} style={{ ...listColSpan(5) }}>
                  <CreatedAlert token={createdAccessToken.token} />
                </div>
              )}
          </ListItem>
        );
      })}
    </List>
  );
};
