import { first } from 'lodash';

import { PipelineEditorError } from '../edit-pipeline/errors';
import {
  Aggregation,
  AggregationExtendedType,
  AggregationType,
  Field,
  Fields,
  Metric,
  Property,
  aggregationZod,
} from '../types';
import { fieldToOption, nameToKey } from '../edit-pipeline/utils';
import {
  isExprAggregation,
  isKeyedAggregation,
  isMetricAggregation,
  isSortedAggregation,
} from '../pipeline/operation';
import { isDateField } from '../components/visualisation/utils';

export interface AggregationOption {
  label: string;
  value: AggregationExtendedType;
  kind: 'key' | 'empty' | 'expr';
  fieldTypes?: string[];
}

export const AggregationOptions: AggregationOption[] = [
  { label: 'Count', value: 'count', kind: 'empty' },
  { label: 'Sum', value: 'sum', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Count Distinct', value: 'count_distinct', kind: 'key' },
  { label: 'Minimum', value: 'min', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Maximum', value: 'max', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Earliest', value: 'earliest', kind: 'key', fieldTypes: ['Date'] },
  { label: 'Latest', value: 'latest', kind: 'key', fieldTypes: ['Date'] },
  { label: 'First Value', value: 'first', kind: 'key' },
  { label: 'Last Value', value: 'last', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] }, // Only for timeSeries
  { label: 'Average', value: 'avg', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Median', value: 'median', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
];

const getFieldsForAggregationType = (type: AggregationExtendedType, fields: Fields) => {
  const aggregationOption = AggregationOptions.find((a) => a.value === type);

  return aggregationOption === undefined
    ? []
    : fields.filter(({ type }) => aggregationOption.fieldTypes?.includes(type ?? '') ?? true);
};

export const getFieldsForAggregation = (aggregation: Aggregation, fields: Fields) =>
  getFieldsForAggregationType(
    aggregationTypeToAggregationExtendedType(
      aggregation.type,
      isKeyedAggregation(aggregation) ? fields.find((f) => f.key === aggregation.key) : undefined,
    ),
    fields,
  );

export const getFieldOptionsForAggregationType = (type: AggregationExtendedType, fields: Fields) =>
  getFieldsForAggregationType(type, fields).map(fieldToOption);

export const getAggregationOptionsForFields = (
  fields: Fields,
  aggregationOptions = AggregationOptions,
) => {
  return aggregationOptions.filter(({ fieldTypes }) =>
    fields.some(({ type }) => fieldTypes?.includes(type ?? '') ?? true),
  );
};

/**
 * Convert a normal aggregation type to an extended aggregation type for display.
 * The extended type includes types like `earlier` and `latest` that are converted back
 * into `min` and `max` for the backend.
 * Whether a type `min` should be `min` or `earlier` is determined by the type of the
 * field referenced by the aggregation.
 */
export const aggregationTypeToAggregationExtendedType = (
  aggregationType: AggregationType,
  field?: Field,
) =>
  AggregationOptions.find(
    (option) =>
      aggregationExtendedTypeToAggregationType(option.value) === aggregationType &&
      (option.fieldTypes === undefined || option.fieldTypes.includes(field?.type ?? '')),
  )?.value ?? aggregationType;

/**
 * Convert an extended aggregation type to a normal aggregation type for backend.
 */
export const aggregationExtendedTypeToAggregationType = (
  extendedType: AggregationExtendedType,
): AggregationType => {
  switch (extendedType) {
    case 'earliest':
      return 'min';
    case 'latest':
      return 'max';
    default:
      return extendedType;
  }
};

export const getAggregationLabel = (type: AggregationExtendedType, field?: Field) => {
  const fieldName = field?.name ?? 'Unknown';
  switch (type) {
    case 'count':
      return 'Count';
    case 'count_distinct':
      return `Count of ${fieldName} (distinct)`;
    case 'sum':
      return `Sum of ${fieldName}`;
    case 'min':
      return `Min ${fieldName}`;
    case 'max':
      return `Max ${fieldName}`;
    case 'earliest':
      return `Earliest ${fieldName}`;
    case 'latest':
      return `Latest ${fieldName}`;
    case 'avg':
      return `Average ${fieldName}`;
    case 'median':
      return `Median ${fieldName}`;
    case 'first':
      return `First Value of ${fieldName}`;
    case 'last':
      return `Last Value of ${fieldName}`;
    case 'custom':
      return 'Custom Formula';
    default:
      return 'Unknown Value';
  }
};

const generateMetricAggregationProperty = (metric: Metric) => {
  return {
    key: metric.metricId,
    name: metric.name,
  };
};

const generateKeyedAggregationProperty = (
  aggregationType: AggregationType,
  field: Field,
  relationName?: string,
) => {
  return {
    key: `${aggregationType}_${field.key}`,
    name: `${getAggregationLabel(aggregationType, field)}${
      relationName !== undefined ? ` (${relationName})` : ''
    }`,
  };
};

const generateExprAggregationProperty = () => {
  return {
    key: 'custom',
    name: 'Custom Formula',
  };
};

const generateCountAggregationProperty = (relationName?: string) => {
  if (relationName !== undefined) {
    return {
      key: `count_${nameToKey(relationName)}`,
      name: `Number of ${relationName}`,
    };
  }

  return {
    key: 'count',
    name: 'Count',
  };
};

export const generateAggregationProperty = (
  aggregation: Aggregation,
  fields: Field[],
  metrics: Metric[],
  relationName?: string,
): Property => {
  if (isKeyedAggregation(aggregation)) {
    const field = fields.find((f) => f.key === aggregation.key);
    if (field === undefined) {
      throw new PipelineEditorError(`Aggregation references unknown field '${aggregation.key}'`);
    }
    return generateKeyedAggregationProperty(aggregation.type, field, relationName);
  }

  if (isMetricAggregation(aggregation)) {
    const metric = metrics.find((m) => m.metricId === aggregation.metricId);
    if (metric === undefined) {
      throw new PipelineEditorError(
        `Aggregation references unknown metric ${aggregation.metricId}`,
      );
    }
    return generateMetricAggregationProperty(metric);
  }

  if (isExprAggregation(aggregation)) {
    return generateExprAggregationProperty();
  }

  return generateCountAggregationProperty(relationName);
};

const getNextAggregationType = (
  fields: Fields,
  allowedAggregationOptions = AggregationOptions,
): AggregationExtendedType => {
  const aggregationOptions = getAggregationOptionsForFields(fields, allowedAggregationOptions);
  if (fields.length === 0) {
    throw new PipelineEditorError('No fields available for aggregation');
  }

  const aggregationOption =
    aggregationOptions.find(({ fieldTypes }) =>
      fields.some(
        (field) =>
          fieldTypes === undefined || (field.type !== 'Date' && fieldTypes.includes(field.type)),
      ),
    ) ?? first(aggregationOptions);

  return aggregationOption?.value ?? 'count';
};

export const getDefaultAggregation = (
  fields: Fields,
  metrics: Metric[],
  relationName?: string,
  allowedAggregationOptions = AggregationOptions,
): Aggregation => {
  const type = getNextAggregationType(fields, allowedAggregationOptions);

  if (type === 'count') {
    return {
      type: 'count',
      property: generateCountAggregationProperty(relationName),
    };
  }

  if (type === 'metric') {
    const firstMetric = first(metrics);
    if (firstMetric === undefined) {
      throw new PipelineEditorError('No metrics available for aggregation');
    }
    return {
      type: 'metric',
      metricId: firstMetric.metricId,
      property: generateMetricAggregationProperty(firstMetric),
    };
  }

  if (type === 'custom') {
    return {
      type: 'custom',
      value: { expression: '', version: '1' },
      property: generateExprAggregationProperty(),
    };
  }

  if (type === 'earliest' || type === 'latest') {
    const firstField = fields.find(isDateField) ?? first(fields);
    if (firstField === undefined) {
      throw new PipelineEditorError('No date fields available for aggregation');
    }

    const convertedType = type === 'earliest' ? 'min' : 'max';

    return {
      type: convertedType,
      key: firstField.key,
      property: generateKeyedAggregationProperty(convertedType, firstField, relationName),
    };
  }

  if (type === 'first' || type === 'last') {
    const firstField = first(fields);
    if (firstField === undefined) {
      throw new PipelineEditorError('No fields available for aggregation');
    }

    return {
      type,
      key: firstField.key,
      sort: [
        {
          key: firstField.key,
          direction: 'ASC',
        },
      ],
      property: generateKeyedAggregationProperty(type, firstField, relationName),
    };
  }

  const firstField = first(getFieldsForAggregationType(type, fields));
  if (firstField === undefined) {
    throw new PipelineEditorError('No fields available for aggregation');
  }

  return {
    type,
    key: firstField.key,
    property: generateKeyedAggregationProperty(type, firstField, relationName),
  };
};

export const ensureValidAggregation = (
  aggregation: Aggregation,
  fields: Field[],
  metrics: Metric[],
  relationName?: string,
): Aggregation => {
  const validation = aggregationZod.safeParse(aggregation);

  if (!validation.success) {
    return getDefaultAggregation(fields, metrics, relationName);
  }

  aggregation = validation.data;

  if (aggregation.type === 'count') {
    return {
      type: 'count',
      property: aggregation.property,
    };
  }

  if (aggregation.type === 'custom') {
    return {
      type: 'custom',
      value: aggregation.value,
      property: aggregation.property,
    };
  }

  if (aggregation.type === 'metric') {
    const metric = metrics.find((metric) => metric.metricId === aggregation.metricId);
    return metric ? aggregation : getDefaultAggregation(fields, metrics, relationName);
  }

  const defaultTargetField = first(getFieldsForAggregation(aggregation, fields));
  if (defaultTargetField === undefined) {
    return getDefaultAggregation(fields, metrics, relationName);
  }

  if (aggregation.key === undefined) {
    return {
      ...aggregation,
      key: defaultTargetField.key,
    };
  }

  const field = fields.find((f) => f.key === aggregation.key);
  if (!field) {
    return {
      ...aggregation,
      key: defaultTargetField.key,
    };
  }

  return aggregation;
};

export const ensureValidAggregations = (
  aggregations: Aggregation[],
  fields: Field[],
  metrics: Metric[],
): Aggregation[] => {
  return aggregations.map((aggregation) => ensureValidAggregation(aggregation, fields, metrics));
};

export const changeAggregationType = (
  aggregation: Aggregation,
  newExtendedType: AggregationExtendedType,
  fields: Field[],
  metrics: Metric[],
): Aggregation => {
  const hadDefaultProperty =
    aggregation.property.name === generateAggregationProperty(aggregation, fields, metrics).name;

  const newType = aggregationExtendedTypeToAggregationType(newExtendedType);
  if (newType === 'metric') {
    return aggregation;
  }

  if (newType === 'custom') {
    return {
      type: newType,
      value: { expression: '', version: '1' },
      property: hadDefaultProperty ? generateExprAggregationProperty() : aggregation.property,
    };
  }

  if (newType === 'count') {
    return {
      type: newType,
      property: hadDefaultProperty ? generateCountAggregationProperty() : aggregation.property,
    };
  }

  const validFields = getFieldsForAggregationType(newExtendedType, fields);
  const prevField = isKeyedAggregation(aggregation)
    ? fields.find((f) => f.key === aggregation.key)
    : undefined;
  const field = validFields.find((f) => f.key === prevField?.key) ?? first(validFields);

  if (field === undefined) {
    return aggregation;
  }

  if (newType === 'first' || newType === 'last') {
    const defaultSortField = fields.find(({ type }) => type === 'Date') ?? first(fields);
    const sort = isSortedAggregation(aggregation)
      ? aggregation.sort
      : [
          {
            key: defaultSortField?.key ?? '',
            direction: 'ASC' as const,
          },
        ];
    return {
      type: newType,
      key: field.key,
      property: hadDefaultProperty
        ? generateKeyedAggregationProperty(newType, field)
        : aggregation.property,
      sort,
    };
  }

  return {
    type: newType,
    key: field.key,
    property: hadDefaultProperty
      ? generateKeyedAggregationProperty(newType, field)
      : aggregation.property,
  };
};

export const buildMetricAggregation = (metric: Metric): Aggregation => {
  return {
    type: 'metric',
    property: { key: nameToKey(metric.name), name: metric.name },
    metricId: metric.metricId,
  };
};
