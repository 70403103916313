import { MouseEventHandler, useState } from 'react';

import { Button } from '../../components/button';
import { Input } from '../../components/form/input';

import styles from './create-form.module.scss';

interface CreateAccessTokenFormProps {
  loading: boolean;
  onSubmit: (name: string) => void;
  onCancel: MouseEventHandler;
}

export const CreateAccessTokenForm = (props: CreateAccessTokenFormProps) => {
  const { loading, onSubmit, onCancel } = props;
  const [name, setName] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!loading && name.length > 0) {
      onSubmit(name);
      setName('');
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.title}>Name your token</div>
      <div className={styles.fields}>
        <Input
          size="large"
          type="text"
          placeholder="Name.."
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          autoFocus
        />
      </div>
      <div>
        <Button variant="primary" size="large" type="submit" loading={loading}>
          Generate
        </Button>
        <Button variant="secondary" size="large" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
