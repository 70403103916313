import { IconProps } from './types';

export const TableIcon = ({ size = 12, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <path d="M3.5 6.04898H7.35021V7.34898H3.5V6.04898Z" fill={color} />
    <path d="M7.35021 8.65183H3.5V9.95183H7.35021V8.65183Z" fill={color} />
    <path d="M3.49989 11.2518H7.3501V12.5518H3.49989V11.2518Z" fill={color} />
    <path d="M12.4999 6.04898H8.6498V7.34898H12.4999V6.04898Z" fill={color} />
    <path d="M3.49989 3.44898H7.34989V4.74898H3.49989V3.44898Z" fill={color} />
    <path d="M12.4999 3.44898H8.64989V4.74898H12.4999V3.44898Z" fill={color} />
    <path d="M8.6498 8.65183H12.4999V9.95183H8.6498V8.65183Z" fill={color} />
    <path d="M12.4999 11.2518H8.6498V12.5518H12.4999V11.2518Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0.953125C1.89543 0.953125 1 1.84855 1 2.95312V13.0477C1 14.1522 1.89543 15.0477 3 15.0477H13C14.1046 15.0477 15 14.1522 15 13.0477V2.95312C15 1.84856 14.1046 0.953125 13 0.953125H3ZM13.7 2.95312C13.7 2.56653 13.3866 2.25312 13 2.25312H3C2.6134 2.25312 2.3 2.56653 2.3 2.95312V13.0477C2.3 13.4343 2.6134 13.7477 3 13.7477H13C13.3866 13.7477 13.7 13.4343 13.7 13.0477V2.95312Z"
      fill={color}
    />
  </svg>
);
