type BlockProps = {
  size?: number;
  color?: string;
};

export const Block = ({ size = 16, color = 'currentColor', ...rest }: BlockProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path d="M2.5 6.04898H6.35021V7.34898H2.5V6.04898Z" fill={color} />
    <path d="M6.35021 8.65183H2.5V9.95183H6.35021V8.65183Z" fill={color} />
    <path d="M2.49989 11.2518H6.3501V12.5518H2.49989V11.2518Z" fill={color} />
    <path d="M11.4999 6.04898H7.6498V7.34898H11.4999V6.04898Z" fill={color} />
    <path d="M2.49989 3.44898H6.34989V4.74898H2.49989V3.44898Z" fill={color} />
    <path d="M11.4999 3.44898H7.64989V4.74898H11.4999V3.44898Z" fill={color} />
    <path d="M7.6498 8.65183H11.4999V9.95183H7.6498V8.65183Z" fill={color} />
    <path d="M11.4999 11.2518H7.6498V12.5518H11.4999V11.2518Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.953125C0.895431 0.953125 0 1.84855 0 2.95312V13.0477C0 14.1522 0.89543 15.0477 2 15.0477H12C13.1046 15.0477 14 14.1522 14 13.0477V2.95312C14 1.84856 13.1046 0.953125 12 0.953125H2ZM12.7 2.95312C12.7 2.56653 12.3866 2.25312 12 2.25312H2C1.6134 2.25312 1.3 2.56653 1.3 2.95312V13.0477C1.3 13.4343 1.6134 13.7477 2 13.7477H12C12.3866 13.7477 12.7 13.4343 12.7 13.0477V2.95312Z"
      fill={color}
    />
  </svg>
);
