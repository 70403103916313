import { useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './sortable-item.module.scss';

const inputFieldSelector = ['textarea:focus', 'input:focus', 'select:focus', 'button:focus'].join(
  ',',
);

interface SortableItemProps {
  index: number;
  scope: string;
  draggedIndex: number | null;
  draggable?: boolean;
  setDraggedIndex: (index: number | null) => void;
  onReorder: (fromIndex: number, toIndex: number, after: boolean) => void;
  children: React.ReactNode;
  className?: string;
  classNames?: {
    dragBefore: string;
    dragAfter: string;
  };
}

export const SortableItem = (props: SortableItemProps) => {
  const { index, draggedIndex, draggable = true } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  // Allow dragging within inputs to work as expected
  const [isInputFieldFocused, setInputFieldFocused] = useState<boolean>(false);

  const onBlur = () => {
    setInputFieldFocused(false);
  };

  const onFocus = () => {
    setInputFieldFocused(element.current?.querySelector(inputFieldSelector) !== null);
  };

  // Defines different "sortable groups" and allows us to check for it in dragOver and dragEnter events
  const dataKey = `application/x-sup-sort-${props.scope}`;

  const isDragged = draggedIndex === index;
  const isDragBefore = isDragOver && (draggedIndex ?? index) > index;
  const isDragAfter = isDragOver && (draggedIndex ?? index) < index;

  return (
    <div
      className={classNames(props.className, {
        [styles.dragBefore]: isDragBefore,
        [styles.dragAfter]: isDragAfter,
        [styles.isDragged]: isDragged,
        [props.classNames?.dragBefore ?? '']: isDragBefore,
        [props.classNames?.dragAfter ?? '']: isDragAfter,
      })}
      ref={element}
      onBlur={onBlur}
      onFocus={onFocus}
      draggable={draggable && !isInputFieldFocused}
      onDragStart={(event) => {
        event.stopPropagation(); // Allow nested sortables
        event.dataTransfer.clearData();
        event.dataTransfer.setData(dataKey, index.toString());
        event.dataTransfer.effectAllowed = 'move';

        props.setDraggedIndex(index);
      }}
      onDragEnd={() => {
        setIsDragOver(false);
        props.setDraggedIndex(null);
      }}
      onDragOver={(event) => {
        event.dataTransfer.dropEffect = 'move';
        if (event.dataTransfer.types.indexOf(dataKey) !== -1) {
          event.preventDefault();
        }
      }}
      onDragEnter={(event) => {
        if (event.dataTransfer.types.indexOf(dataKey) !== -1) {
          setIsDragOver(true);
        }
      }}
      onDragLeave={(event) => {
        if (element.current && !element.current.contains(event.relatedTarget as Node)) {
          setIsDragOver(false);
        }
      }}
      onDrop={(event) => {
        event.preventDefault();
        const target = index;
        const source = parseInt(event.dataTransfer.getData(dataKey), 10);

        setIsDragOver(false);

        if (isNaN(source) || source === target) {
          return;
        }

        props.setDraggedIndex(null);
        props.onReorder(source, target, isDragAfter);
      }}>
      {props.children}
    </div>
  );
};
