import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
  User as FirebaseUser,
  applyActionCode as firebaseApplyActionCode,
  sendEmailVerification as firebaseSendEmailVerification,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { isEqual } from 'lodash';

import { auth } from '../lib/firebase-init';
import { FullPageLoader } from '../components/loader';
import { User } from './types';

interface AuthContext {
  user: User | null;
  getToken: () => Promise<string>;
  logOut: () => Promise<void>;
  sendEmailVerification: () => Promise<void>;
  applyActionCode: (code: string) => Promise<User | null>;
}

const sendEmailVerification = async () => {
  if (auth.currentUser !== null) {
    await firebaseSendEmailVerification(auth.currentUser);
  }
};

const defaultContextValue = Symbol();

const AuthContext = createContext<AuthContext | typeof defaultContextValue>(defaultContextValue);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === defaultContextValue) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }
  return context;
};

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const getToken = (firebaseUser: FirebaseUser | null) => async () => {
  if (firebaseUser) {
    const decodedUser = await firebaseUser.getIdTokenResult();
    const forceRefresh = decodedUser.claims.email_verified !== firebaseUser.emailVerified;
    return await firebaseUser.getIdToken(forceRefresh);
  }
  return 'missing-token';
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [firebaseUser, firebaseLoading] = useAuthState(auth);
  const [user, setUser] = useState<User | null>(null);

  const logOut = useCallback(async () => {
    await auth.signOut();
    window.location.assign('/');
  }, []);

  const applyActionCode = useCallback(async (code: string) => {
    await firebaseApplyActionCode(auth, code);
    if (auth.currentUser === null) {
      return null;
    }
    await auth.currentUser.reload();

    const user = {
      email: auth.currentUser.email ?? '',
      emailVerified: auth.currentUser.emailVerified,
    };

    setUser(user);

    return user;
  }, []);

  useEffect(() => {
    const listener = auth.onIdTokenChanged(async (firebaseUser: FirebaseUser | null) => {
      if (firebaseUser !== null) {
        setUser((prevUser) => {
          const newUser = {
            email: firebaseUser.email ?? '',
            emailVerified: firebaseUser.emailVerified,
          };
          if (isEqual(prevUser, newUser)) {
            return prevUser;
          }
          return newUser;
        });
      } else {
        setUser(null);
      }
    });

    return () => {
      listener();
    };
  }, []);

  if (firebaseLoading) {
    return <FullPageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        getToken: getToken(firebaseUser ?? null),
        logOut,
        sendEmailVerification,
        applyActionCode,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
