import {
  barColor1,
  barColor2,
  barColor3,
  barColor4,
  barColor5,
  barColor6,
} from './charts.module.scss';

export const chartColors = [barColor1, barColor2, barColor3, barColor4, barColor5, barColor6];
export const getChartColor = (index: number) => chartColors[index % chartColors.length];

export const increaseBrightness = (hex: string, percent: number) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  const adjustBrightness = (color: number) => {
    const newColor = color + (255 - color) * (percent / 100);
    return Math.round(newColor).toString(16).padStart(2, '0'); // Ensure two digits
  };

  return `#${adjustBrightness(r)}${adjustBrightness(g)}${adjustBrightness(b)}`;
};
