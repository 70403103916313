import { useEffect, useMemo, useRef } from 'react';
import { Variables } from '@gosupersimple/penguino';

import { CodeMirror, CodeMirrorRef } from '@/components/codemirror';
import { Field, Model } from '@/explore/types';
import { FieldGroup, isField, isFieldGroup } from '@/explore/pipeline/utils';
import {
  convertFieldsForPenguinoContext,
  dehumanizeExpression,
  humanizeExpression,
} from '@/explore/utils/penguino';

import { penguino } from './penguino';
import { penguinoStateProvider, updatePenguinoState } from './penguino/state';

import styles from './penguino-input.module.scss';

export const PenguinoVersion = '1';

interface PenguinoInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  fields: (Field | FieldGroup)[];
  variables: Variables;
  model?: Model;
  requiredType?: string;
}

export const PenguinoInput = (props: PenguinoInputProps) => {
  const { variables, requiredType } = props;

  const codeEditorRef = useRef<CodeMirrorRef>(null);

  // Add default fields to context in a keyless group so both `prop(myField)` and `prop(myTable.myField)`
  // become `prop("My Field (on My Table)")`
  const defaultFieldGroup = props.fields
    .filter(isFieldGroup)
    .find((group) => group.key === props.model?.modelId);

  const fields = useMemo(
    () =>
      convertFieldsForPenguinoContext([
        ...props.fields.filter(isFieldGroup),
        { fields: props.fields.filter(isField) },
        ...(defaultFieldGroup !== undefined ? [{ ...defaultFieldGroup, key: undefined }] : []),
      ]),
    [props.fields, defaultFieldGroup],
  );

  const humanizedValue = useMemo(
    () => humanizeExpression(props.value, fields),
    [props.value, fields],
  );

  useEffect(() => {
    const view = codeEditorRef.current?.view;
    if (view === undefined) {
      return;
    }

    updatePenguinoState(view, { fields, variables, requiredType });
  }, [fields, variables, requiredType]);

  return (
    <CodeMirror
      ref={codeEditorRef}
      value={humanizedValue}
      placeholder={props.placeholder}
      onChange={(value) => props.onChange(dehumanizeExpression(value, fields))}
      className={styles.penguinoInput}
      autoFocus
      extensions={[penguinoStateProvider(), penguino()]}
      onInitialize={(view) => updatePenguinoState(view, { fields, variables, requiredType })}
    />
  );
};
