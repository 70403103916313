import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { useOverlayContainer } from './context';

export const Overlay = ({ children }: { children: ReactNode }) => {
  const overlayContainer = useOverlayContainer();

  if (overlayContainer === null) {
    return null;
  }

  return createPortal(children, overlayContainer);
};
