import classNames from 'classnames';

import { Icon, IconProps } from '../icon';

import styles from './badge.module.scss';

export const Badge = ({
  label,
  bgColor = 'black',
  textColor = 'white',
  iconName,
}: {
  label: string;
  bgColor?: string;
  textColor?: string;
  iconName?: IconProps['name'];
}) => (
  <div className={styles.badge} style={{ color: textColor, backgroundColor: bgColor }}>
    {iconName !== undefined && (
      <span className={styles.icon}>
        <Icon name={iconName} size={14} />
      </span>
    )}
    <span className={styles.label}>{label}</span>
  </div>
);

interface ReferenceBadgeProps {
  label: string;
  bgColor?: string;
  textColor?: string;
  iconName?: IconProps['name'];
  onRemove?: () => void;
}

export const ReferenceBadge = (props: ReferenceBadgeProps) => {
  const { label, bgColor, textColor, iconName, onRemove } = props;
  return (
    <div
      className={classNames([styles.badge, styles.referenceBadge])}
      style={{ color: textColor, backgroundColor: bgColor }}>
      {iconName !== undefined && (
        <span className={styles.icon}>
          <Icon name={iconName} size={14} />
        </span>
      )}
      <span className={styles.label} title={label}>
        {label}
      </span>
      {onRemove !== undefined && <Icon name="X" size={14} onClick={onRemove} />}
    </div>
  );
};
