import { ScaleLinear, ScaleTime } from 'd3-scale';

import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';

type LineSeriesProps = {
  data: { date: Date; value: number }[];
  strokeColor: string;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
};

export const LineSeries = (props: LineSeriesProps) => {
  const { data, strokeColor, valueScale, dateScale } = props;

  return (
    <LinePath
      data={data}
      x={({ date }) => dateScale(date) ?? 0}
      y={({ value }) => valueScale(value) ?? 0}
      stroke={strokeColor}
      strokeOpacity={1}
      curve={curveMonotoneX}
    />
  );
};
