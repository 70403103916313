import { IconProps } from './types';

export const EventIcon = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    {...rest}>
    <path
      fill={color}
      d="M5.334 12.024a.375.375 0 0 0-.375.375v2.5c0 .208.168.375.375.375h2.5a.375.375 0 0 0 .375-.375v-2.5a.375.375 0 0 0-.375-.375h-2.5Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.333 1.208c.46 0 .833.373.833.833v.834h1.667a2.5 2.5 0 0 1 2.5 2.5V17.04a2.5 2.5 0 0 1-2.5 2.5H4.166a2.5 2.5 0 0 1-2.5-2.5V5.375a2.5 2.5 0 0 1 2.5-2.5h1.667V2.04a.833.833 0 1 1 1.667 0v.834h5V2.04c0-.46.373-.833.833-.833Zm-10 4.167c0-.46.373-.834.833-.834h1.667v.834a.833.833 0 1 0 1.667 0V4.54h5v.834a.833.833 0 1 0 1.666 0V4.54h1.667c.46 0 .833.373.833.834v2.5H3.333v-2.5Zm0 11.666c0 .46.373.834.833.834h11.667c.46 0 .833-.373.833-.834v-7.5H3.333v7.5Z"
      clipRule="evenodd"
    />
  </svg>
);
