import classnames from 'classnames';

import { forwardRef } from 'react';

import styles from './checkbox.module.scss';

interface CheckboxProps {
  onChange: (options: { checked: boolean; value?: string }) => void;
  checked?: boolean;
  value?: string;
  id?: string;
  className?: classnames.Argument;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const Checkbox = (props: CheckboxProps) => (
  <input
    type="checkbox"
    id={props.id}
    checked={props.checked ?? false}
    value={props.value}
    disabled={props.disabled}
    onChange={(e) => props.onChange({ checked: e.target.checked, value: e.target.value })}
    onClick={props.onClick}
    className={classnames(styles.checkbox, { [styles.checked]: props.checked }, props.className)}
  />
);

interface CheckboxWithLabelProps extends CheckboxProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

export const CheckboxWithLabel = forwardRef(function CheckboxWithLabel(
  props: CheckboxWithLabelProps,
  ref?: React.Ref<HTMLLabelElement>,
) {
  const { className, onClick, ...checkboxProps } = props;
  return (
    <label className={classnames(styles.checkboxWithLabel, className)} ref={ref} onClick={onClick}>
      <Checkbox {...checkboxProps} />
      {props.children}
    </label>
  );
});
