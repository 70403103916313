import { IconProps } from './types';

export const Combine = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M1 3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.997H9.674v1.33A1.33 1.33 0 0 0 11.004 5H13a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-2H3a2 2 0 0 1-2-2V3Zm7.667 1.997h-1.33v1.33h1.33v-1.33ZM6.331 7.335h-1.33v1.33h1.33v-1.33Zm-1.33 2.337h1.33a1.33 1.33 0 0 1-1.33 1.33v-1.33Zm0-3.345c0-.735.596-1.33 1.33-1.33v1.33h-1.33Zm6.003 2.21a.665.665 0 1 0-1.33 0v1.135H8.54a.665.665 0 0 0 0 1.33h1.134v1.135a.665.665 0 1 0 1.33 0v-1.135h1.133a.665.665 0 0 0 0-1.33h-1.133V8.536Z"
      clipRule="evenodd"
    />
  </svg>
);
