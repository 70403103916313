export const FunnelIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5H5V32.5H10V7.5ZM5 5C3.61929 5 2.5 6.11929 2.5 7.5V32.5C2.5 33.8807 3.61929 35 5 35H10C11.3807 35 12.5 33.8807 12.5 32.5V7.5C12.5 6.11929 11.3807 5 10 5H5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 17.5H17.5V32.5H22.5V17.5ZM17.5 15C16.1193 15 15 16.1193 15 17.5V32.5C15 33.8807 16.1193 35 17.5 35H22.5C23.8807 35 25 33.8807 25 32.5V17.5C25 16.1193 23.8807 15 22.5 15H17.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 27.5H30V32.5H35V27.5ZM30 25C28.6193 25 27.5 26.1193 27.5 27.5V32.5C27.5 33.8807 28.6193 35 30 35H35C36.3807 35 37.5 33.8807 37.5 32.5V27.5C37.5 26.1193 36.3807 25 35 25H30Z"
      fill={color}
    />
  </svg>
);
