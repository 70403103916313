import classNames from 'classnames';

import { Button } from '../button';

import styles from './form.module.scss';

type TagSelectProps = {
  options: { label: string; value: string }[];
  value: string[];
  className?: string;
  isMultiSelect?: boolean;
  onChange: (value: string[]) => void;
};

export const TagSelect = ({
  options,
  value,
  onChange,
  className,
  isMultiSelect = false,
}: TagSelectProps) => {
  const handleClickOption = (v: string) => {
    onChange(
      isMultiSelect
        ? options
            .filter(
              (o) =>
                (o.value !== v && value.includes(o.value)) || (o.value === v && !value.includes(v)),
            )
            .map((o) => o.value)
        : [v],
    );
  };
  return (
    <ul className={classNames([styles.tagSelect, className])} aria-multiselectable={isMultiSelect}>
      {options.map(({ label, value: v }) => {
        const isSelected = value.includes(v);
        return (
          <li key={v} aria-selected={isMultiSelect ? isSelected : undefined}>
            <Button
              variant="outlined"
              size="small"
              type="button"
              onClick={() => handleClickOption(v)}
              className={classNames(styles.tagButton, {
                [styles.active]: isSelected || !isMultiSelect,
              })}>
              {label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
