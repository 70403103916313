import { ReferenceBadge } from '@/components/badge';

import type { VariableDefinition } from '@/explore/types';
import { getVariableColor, getVariableKeyFromExpression } from '@/explore/utils';

import {
  variableAlternateColor1,
  variableAlternateColor2,
  variableAlternateColor3,
  variableAlternateColor4,
  variableAlternateColor5,
  variableAlternateColor6,
  variableColor1,
  variableColor2,
  variableColor3,
  variableColor4,
  variableColor5,
  variableColor6,
} from '@/explore/exploration/exploration.module.scss';

interface VariableReferenceBadgeProps {
  value: string;
  variables: VariableDefinition[];
  onRemove: () => void;
}

export const VariableReferenceBadge = (props: VariableReferenceBadgeProps) => {
  const { value, variables, onRemove } = props;
  const key = getVariableKeyFromExpression(value) ?? '';
  const variableExists = variables.some((variable) => variable.key === key);
  return (
    <ReferenceBadge
      label={variableExists ? key : `❗ Missing variable: ${key}`}
      iconName={variableExists ? 'VariableInstance' : undefined}
      textColor={getVariableColor(key, variables, [
        variableColor1,
        variableColor2,
        variableColor3,
        variableColor4,
        variableColor5,
        variableColor6,
      ])}
      bgColor={getVariableColor(key, variables, [
        variableAlternateColor1,
        variableAlternateColor2,
        variableAlternateColor3,
        variableAlternateColor4,
        variableAlternateColor5,
        variableAlternateColor6,
      ])}
      onRemove={onRemove}
    />
  );
};
