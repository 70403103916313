import { IconProps } from './types';

export const VariableInstanceIcon = ({
  size = 12,
  color = 'currentColor',

  ...rest
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M2.19559 2.5878C2.32061 2.46278 2.49018 2.39254 2.66699 2.39254H8.66699C8.8438 2.39254 9.01337 2.46278 9.1384 2.5878C9.26342 2.71282 9.33366 2.88239 9.33366 3.0592V3.72587C9.33366 3.88092 9.38659 4.02361 9.47537 4.13685L9.48589 4.14993C9.60817 4.2981 9.79322 4.39254 10.0003 4.39254C10.2135 4.39254 10.4032 4.29251 10.5253 4.13685C10.535 4.12445 10.5443 4.11169 10.5531 4.09861C10.625 3.99221 10.667 3.86394 10.667 3.72587V3.0592C10.667 2.52877 10.4563 2.02006 10.0812 1.64499C9.70613 1.26992 9.19742 1.0592 8.66699 1.0592H2.66699C2.13656 1.0592 1.62785 1.26992 1.25278 1.64499C0.877706 2.02006 0.666992 2.52877 0.666992 3.0592V9.0592C0.666992 9.58964 0.877706 10.0983 1.25278 10.4734C1.62785 10.8485 2.13656 11.0592 2.66699 11.0592H3.33366C3.48875 11.0592 3.63147 11.0062 3.74473 10.9174L3.75772 10.907C3.90589 10.7847 4.00033 10.5996 4.00033 10.3925C4.00033 10.1794 3.90034 9.98969 3.74473 9.86765C3.7323 9.8579 3.71951 9.84859 3.7064 9.83973C3.6 9.76784 3.47173 9.72587 3.33366 9.72587H2.66699C2.49018 9.72587 2.32061 9.65563 2.19559 9.53061C2.07056 9.40559 2.00033 9.23602 2.00033 9.0592V3.0592C2.00033 2.88239 2.07056 2.71282 2.19559 2.5878Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34473 7.73685C5.34473 6.63228 6.24016 5.73685 7.34473 5.73685H13.3337C14.4383 5.73685 15.3337 6.63228 15.3337 7.73685V13.7259C15.3337 14.8304 14.4383 15.7259 13.3337 15.7259H7.34473C6.24016 15.7259 5.34473 14.8304 5.34473 13.7259V7.73685ZM9.81948 8.29255C10.0504 7.89255 10.6278 7.89255 10.8587 8.29255L12.8506 11.7426C13.0815 12.1426 12.7928 12.6426 12.3309 12.6426H8.34723C7.88535 12.6426 7.59668 12.1426 7.82762 11.7426L9.81948 8.29255Z"
      fill={color}
    />
  </svg>
);
