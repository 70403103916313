import { useMemo } from 'react';

import { Account, useAccountContext } from '@/lib/accounts/context';

import { Dropdown, DropdownMenuItem } from '../dropdown';
import { Icon } from '../icon';

import styles from './header.module.scss';

interface AccountMenuProps {
  Component: typeof DefaultAccountMenu | typeof MobileAccountMenu;
  accountSettingsUrl: string;
  onClickLogOut: () => void;
  onSwitchAccount?: (accountId: string) => void;
}

interface AccountMenuComponentProps {
  accounts: Account[];
  selectedAccount: Account;
  accountSettingsUrl: string;
  onClickAccount: (accountId: string) => void;
  onClickLogOut: () => void;
}

export const AccountMenu = (props: AccountMenuProps) => {
  const accountContext = useAccountContext();
  const selectedAccount = accountContext.account;

  const accounts = useMemo(
    () => accountContext.accounts.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [accountContext.accounts],
  );

  if (selectedAccount === null) {
    return <></>;
  }

  const handleClickAccount = (accountId: string) => {
    accountContext.switchAccount(accountId);
    props.onSwitchAccount && props.onSwitchAccount(accountId);
  };

  return (
    <props.Component
      accounts={accounts}
      selectedAccount={selectedAccount}
      onClickAccount={handleClickAccount}
      accountSettingsUrl={props.accountSettingsUrl}
      onClickLogOut={props.onClickLogOut}
    />
  );
};

export const DefaultAccountMenu = ({ accounts, selectedAccount }: AccountMenuComponentProps) => {
  const menuItems: DropdownMenuItem[] = accounts.map<DropdownMenuItem>((account) => ({
    label: account.name,
    type: 'link',
    href: `/${account.slug}`,
    icon:
      account.accountId === selectedAccount.accountId ? (
        <Icon name="Check" size={16} />
      ) : (
        <div className={styles.emptyIcon} />
      ),
    className: account.accountId === selectedAccount.accountId ? styles.activeItem : styles.item,
  }));

  return (
    <Dropdown
      className={styles.accountMenu}
      items={menuItems}
      align="left"
      trigger={(isOpen, setIsOpen) => (
        <button className={styles.accountMenuButton} onClick={() => setIsOpen(!isOpen)}>
          <div>{selectedAccount.name}</div>
          <Icon name="ChevronDown" size={16} />
        </button>
      )}
    />
  );
};

export const MobileAccountMenu = ({
  accounts,
  selectedAccount,
  onClickAccount,
}: AccountMenuComponentProps) => {
  return (
    <ul className={styles.mobileAccountMenu}>
      {accounts.map(({ accountId, name }) => (
        <li key={accountId}>
          <button
            disabled={accountId === selectedAccount?.accountId}
            onClick={() => {
              onClickAccount(accountId);
            }}>
            {accountId === selectedAccount?.accountId && <Icon name="Check" size={16} />}
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
};
