import { Token, TokenType, getFunctionDefinitionByName } from '@gosupersimple/penguino';

export function getTokenAtPos<T extends { start: number; end: number }>(tokens: T[], pos: number) {
  return tokens.find((token) => token.start <= pos && token.end >= pos) ?? null;
}

export function getTokenWithIndexAtPos(tokens: Token[], pos: number) {
  const idx = tokens.findIndex((token) => token.start <= pos && token.end >= pos);
  return [tokens[idx], idx] as const;
}

export function isStringToken(token: Token) {
  return token.type === TokenType.String;
}

export function getFunctionDefinitionByTokenAtPosition(tokens: Token[], pos: number) {
  const functionToken = getOuterFunctionToken(tokens, pos);
  if (functionToken === undefined) {
    return;
  }
  const functionDefinition = getFunctionDefinitionByName(functionToken.lexeme);
  if (functionDefinition === undefined) {
    return;
  }

  const argumentAt = getArgumentIndexAt(tokens, pos);

  return { functionDefinition, argumentAt };
}

export function getOuterFunctionToken(tokens: Token[], pos: number) {
  const lastOpenParenIndex = getLastOpenParenIndex(tokens, getTokenIndexAt(tokens, pos) + 1);
  if (lastOpenParenIndex === -1) {
    return undefined;
  }

  const previousToken = tokens.at(lastOpenParenIndex - 1);
  return previousToken?.type === TokenType.Identifier ? previousToken : undefined;
}

function getLastOpenParenIndex(tokens: Token[], pos: number): number {
  if (pos < 0) {
    return -1;
  }

  for (let i = pos - 1; i >= 0; i--) {
    if (tokens[i].type === TokenType.LeftParen) {
      return i;
    } else if (tokens[i].type === TokenType.RightParen) {
      i = getLastOpenParenIndex(tokens, i);
    }
  }

  return -1;
}

function getArgumentIndexAt(tokens: Token[], position: number) {
  const tokenIdx = getTokenIndexAt(tokens, position);
  let argIndex = 0;
  let parenCount = 0;

  for (let i = tokenIdx; i >= 0; i--) {
    const token = tokens[i];

    if (token.type === TokenType.RightParen) {
      parenCount++;
    } else if (token.type === TokenType.LeftParen) {
      parenCount--;
    } else if (token.type === TokenType.Comma && parenCount === 0) {
      argIndex++;
    }
  }

  return argIndex;
}

function getTokenIndexAt(tokens: Token[], pos: number) {
  return tokens.findIndex((token) => token.start <= pos && token.end >= pos);
}

export function getFunctionParameterBounds(tree: Token[], pos: number) {
  const [currentNode, idx] = getTokenWithIndexAtPos(tree, pos);

  const prevLeftParen = findOpenParen(tree, idx);
  const nextRightParen = findCloseParen(tree, idx);

  if (prevLeftParen !== null && nextRightParen === null) {
    return { from: prevLeftParen.end, to: currentNode.end };
  }

  if (prevLeftParen !== null && nextRightParen !== null) {
    return { from: prevLeftParen.end, to: nextRightParen.start };
  }

  return { from: currentNode.start, to: currentNode.end };
}

const findOpenParen = (tokens: Token[], startIdx: number): Token | null => {
  for (let i = startIdx, depth = 0; i >= 0; i--) {
    const token = tokens.at(i);
    if (token?.type === TokenType.RightParen) {
      depth++;
    } else if (token?.type === TokenType.LeftParen) {
      if (depth === 0) {
        return token;
      }
      depth--;
    }
  }
  return null;
};

const findCloseParen = (tokens: Token[], startIdx: number): Token | null => {
  for (let i = startIdx + 1, depth = 0; i < tokens.length; i++) {
    const token = tokens.at(i);
    if (token?.type === TokenType.LeftParen) {
      depth++;
    } else if (token?.type === TokenType.RightParen) {
      if (depth === 0) {
        return token;
      }
      depth--;
    }
  }

  return null;
};
