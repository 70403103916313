import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useBlocker } from 'react-router-dom';

import { ConfirmationModal } from '@/components/confirmation-modal';

interface DirtyContext {
  setDirty: (dirty: boolean) => void;
  confirmUnsavedChangesIfNeeded: (confirm: { onConfirm: () => void }) => void;
}

const defaultContextValue = Symbol();

const DirtyContext = createContext<DirtyContext | typeof defaultContextValue>(defaultContextValue);

export const useDirtyContext = () => {
  const context = useContext(DirtyContext);
  if (context === defaultContextValue) {
    throw new Error('useDirtyContext must be used within a DirtyContextProvider');
  }
  return context;
};

interface DirtyContextProviderProps {
  children: React.ReactNode;
}

export const DirtyContextProvider = (props: DirtyContextProviderProps) => {
  const dirtyRef = useRef(false);
  const [modalData, setModalData] = useState<{ onConfirm: () => void } | null>(null);

  const blocker = useBlocker(() => dirtyRef.current);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) =>
      dirtyRef.current && event.preventDefault();
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const confirmUnsavedChangesIfNeeded = (confirm: { onConfirm: () => void }) => {
    if (dirtyRef.current) {
      return setModalData({
        onConfirm: () => {
          setDirty(false);
          confirm.onConfirm();
        },
      });
    }
    confirm.onConfirm();
  };

  const setDirty = (dirty: boolean) => {
    dirtyRef.current = dirty;
  };

  return (
    <DirtyContext.Provider
      value={{
        setDirty,
        confirmUnsavedChangesIfNeeded,
      }}>
      {modalData || blocker.state === 'blocked' ? (
        <ConfirmationModal
          title="You have unapplied changes"
          description="Continuing will discard them. Are you sure you want to continue?"
          submitLabel="Yes, discard changes"
          cancelLabel="No, keep editing"
          onSubmit={() => {
            modalData?.onConfirm();
            blocker.proceed?.();
            setModalData(null);
            setDirty(false);
            return Promise.resolve();
          }}
          onClose={() => {
            blocker.reset?.();
            setModalData(null);
          }}
        />
      ) : null}
      {props.children}
    </DirtyContext.Provider>
  );
};
