import { IconProps } from './types';

export const HorizontalEqualsIcon = ({ size = 14, color = 'currentColor' }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none">
    <path fill={color} d="M3 3h8v2.667H3zM3 8.334h8v2.667H3z" />
  </svg>
);
