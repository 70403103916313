import { ReactNode } from 'react';
import classNames from 'classnames';

import { FormInputSize } from '../types';

import styles from './toggle.module.scss';

interface ToggleProps {
  checked: boolean;
  onChange?: (value: boolean) => void;
  size?: FormInputSize;
  disabled?: boolean;
  children: ReactNode;
}
export const Toggle = ({
  checked,
  onChange,
  size = 'regular',
  disabled = false,
  children,
}: ToggleProps) => {
  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.sizeSmall]: size === 'small',
        [styles.sizeLarge]: size === 'large',
      })}>
      <label className={styles.toggle}>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => onChange?.(!checked)}
          disabled={disabled}
        />
        <span className={styles.slider} />
        <div>{children}</div>
      </label>
    </div>
  );
};
