import { Fields, SimpleVisualisation } from '../../../types';
import { SearchInput } from '../../../../components/form/search-input';
import { fieldToOption } from '../../../edit-pipeline/utils';
import { isNumericField, setVisualisationValueKeys } from '../utils';

interface SimpleVisualisationOptionsFormProps {
  visualisation: SimpleVisualisation;
  onChange: (visualisation: SimpleVisualisation) => void;
  fields: Fields;
}

export const SimpleVisualisationOptionsForm = (props: SimpleVisualisationOptionsFormProps) => {
  const { visualisation, onChange, fields } = props;

  return (
    <>
      {visualisation.valueKeys.map((valueKey, idx) => (
        <SearchInput
          key={idx}
          options={fields.filter(isNumericField).map(fieldToOption)}
          value={valueKey}
          onChange={(value) => {
            const valueKeys = visualisation.valueKeys.map((v, i) => (i === idx ? value : v));
            onChange(setVisualisationValueKeys(visualisation, valueKeys));
          }}
        />
      ))}
      <SearchInput
        options={fields.map(fieldToOption)}
        value={visualisation.mainAxisKey}
        onChange={(value) => {
          onChange({
            ...visualisation,
            mainAxisKey: value,
          });
        }}
      />
    </>
  );
};
