export const Alert = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.036 1.253a1.965 1.965 0 0 1 2.644.693l.002.003 5.55 9.264.005.009a1.966 1.966 0 0 1-1.68 2.948H2.443a1.966 1.966 0 0 1-1.68-2.948l.005-.01 5.55-9.266c.176-.289.422-.527.717-.693ZM8 2.31a.655.655 0 0 0-.56.314l-5.544 9.257a.655.655 0 0 0 .56.979h11.089a.654.654 0 0 0 .56-.98L8.56 2.627v-.002A.655.655 0 0 0 8 2.31Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M8 4.998c.362 0 .655.293.655.655v2.62a.655.655 0 0 1-1.31 0v-2.62c0-.362.293-.655.655-.655ZM7.345 10.894c0-.361.293-.655.655-.655h.007a.655.655 0 1 1 0 1.31H8a.655.655 0 0 1-.655-.655Z"
      clipRule="evenodd"
    />
  </svg>
);
