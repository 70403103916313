export const DownArrowOutlinedIcon = ({
  size = 12,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path d="M13.7929 5L8 10.7929L2.20711 5L13.7929 5Z" stroke={color} />
  </svg>
);
