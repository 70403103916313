import { useEffect } from 'react';

import { useTrackEvent } from '@/lib/analytics';
import { useSelectedAccount } from '@/lib/accounts/context';

import { useGAPageview } from '@/lib/ga/use-ga';

import { useOnboardingStepCheck } from '../use-onboarding-step-check';
import { OnboardingStep } from '../types';

import styles from './step-complete.module.scss';

const CurrentStep: OnboardingStep = 'complete';

export const OnboardingComplete = () => {
  useOnboardingStepCheck(CurrentStep);
  const trackEvent = useTrackEvent();
  useGAPageview();
  const account = useSelectedAccount();

  useEffect(() => {
    trackEvent('Onboarding Completed', { accountId: account.accountId });
  }, [trackEvent, account.accountId]);

  return (
    <div className={styles.wrapper}>
      <img src="/onboarding-complete.svg" alt="Success" />
      <h1>
        <b>We have a connection!</b>
      </h1>
      <h1>We’ll take it over from here and get everything nice and comfy for you.</h1>
      <p>You will get an email as soon as everything is ready.</p>
    </div>
  );
};
