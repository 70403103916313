import { convertModelTypes } from '@/explore/input';

import { useAccountContext } from '../lib/accounts/context';
import { Loader } from '../components/loader';

import { useAccountSettingsQuery } from '../graphql';
import { AccessTokensSection } from './access-tokens';
import { CliSection } from './cli/section';
import { UsersSection } from './users/section';
import { ConnectionsSection } from './connections/section';
import { NavigationSection } from './navigation/section';
import { AlertsSection } from './alerts';
import { useTitle } from '../lib/hooks/use-title';

import styles from './settings.module.scss';

export const AccountSettingsPage = () => {
  useTitle('Account Settings');
  const { account, hasPermission } = useAccountContext();

  const { data, refetch, loading } = useAccountSettingsQuery({
    variables: { accountId: account.accountId },
  });

  return (
    <div className={styles.wrapper}>
      <h1>Account Settings</h1>
      <div className={styles.accountId}>
        Account ID: <pre>{account.accountId}</pre>
      </div>
      <div className={styles.sections}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <UsersSection
              accountId={account.accountId}
              currentUserId={data?.account?.me.userId ?? ''}
              canEdit={hasPermission('MANAGE_USERS')}
              users={data?.account?.users ?? []}
            />
            <AccessTokensSection accessTokens={data?.accessTokens ?? []} refetch={refetch} />
            <CliSection />
            <NavigationSection
              navigation={data?.account?.navigation ?? []}
              explorations={data?.account?.explorations ?? []}
              canEdit={hasPermission('MANAGE_ACCOUNT')}
            />
            <ConnectionsSection connections={data?.account?.connections ?? []} />
            <AlertsSection
              accountId={account.accountId}
              alertConfigurations={data?.account?.alertConfigurations ?? []}
              models={convertModelTypes(data?.account?.models) ?? []}
              metrics={data?.account?.metrics ?? []}
              refetch={refetch}
              canEdit={hasPermission('MANAGE_ALERTS')}
            />
          </>
        )}
      </div>
    </div>
  );
};
