import { useEffect, useState } from 'react';

import {
  breakpointXsm,
  breakpointSm,
  breakpointMd,
  breakpointLg,
  breakpointXl,
  breakpoint2xl,
} from './hooks.module.scss';

export enum Breakpoint {
  xsm = 0,
  sm,
  md,
  lg,
  xl,
  xxl,
  infinity,
}

const BREAKPOINTS = [
  { id: Breakpoint.xsm, maxWidth: parseInt(breakpointXsm, 10) },
  { id: Breakpoint.sm, maxWidth: parseInt(breakpointSm, 10) },
  { id: Breakpoint.md, maxWidth: parseInt(breakpointMd, 10) },
  { id: Breakpoint.lg, maxWidth: parseInt(breakpointLg, 10) },
  { id: Breakpoint.xl, maxWidth: parseInt(breakpointXl, 10) },
  { id: Breakpoint.xxl, maxWidth: parseInt(breakpoint2xl, 10) },
];

const getBreakPoint = (width: number) => {
  return BREAKPOINTS.find((b) => width < b.maxWidth)?.id ?? Breakpoint.infinity;
};

const getScreenSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  breakpoint: getBreakPoint(window.innerWidth),
});

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};
