import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  ReactElement,
  ReactNode,
} from 'react';
import classnames from 'classnames';

import { Icon } from '../icon';
import { CloseButton, InlineButton } from '../button';

import styles from './toast.module.scss';

export type AddToastFn = (toast: ToastData) => void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToastContext = createContext((_toast: ToastData) => {});

export const useToastContext = () => useContext(ToastContext);

interface ToastData {
  kind: 'success' | 'error';
  title: string;
  icon?: ReactElement;
  content: (removeToast: () => void) => ReactNode;
}

export function ToastContextProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<(ToastData & { toastedAt: Date })[]>([]);
  const [isMouseHovering, setIsMouseHovering] = useState(false);

  const cleanupToast = useCallback(() => {
    const remainingToast = toasts.filter(
      ({ toastedAt }) => Date.now() - toastedAt.getTime() < 5000,
    );
    if (remainingToast.length < toasts.length) {
      setToasts(remainingToast);
    }
  }, [toasts]);

  useEffect(() => {
    if (toasts.length > 0 && !isMouseHovering) {
      const interval = setInterval(cleanupToast, 1000);
      return () => clearInterval(interval);
    }
  }, [toasts, isMouseHovering, cleanupToast]);

  const addToast: AddToastFn = useCallback(
    (toast: ToastData) => setToasts((toasts) => [...toasts, { ...toast, toastedAt: new Date() }]),
    [setToasts],
  );

  const removeToast = useCallback(
    (i: number) => setToasts((toasts) => [...toasts.slice(0, i), ...toasts.slice(i + 1)]),
    [setToasts],
  );

  return (
    <ToastContext.Provider value={addToast}>
      <div
        className={styles.toasts}
        onMouseEnter={() => setIsMouseHovering(true)}
        onMouseLeave={() => setIsMouseHovering(false)}>
        {toasts.map(({ content, icon, title, kind }, idx) => (
          <div
            key={idx}
            className={classnames(styles.toast, {
              [styles.toastSuccess]: kind === 'success',
              [styles.toastError]: kind === 'error',
            })}>
            {icon ?? <Icon name="Info" className={styles.icon} />}
            <div className={styles.content}>
              <div className={styles.title}>{title}</div>
              <div className={styles.body}>{content(() => removeToast(idx))}</div>
            </div>
            <CloseButton
              iconSize="large"
              className={styles.closeButton}
              onClick={() => removeToast(idx)}
            />
          </div>
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
}

/**
 * Utility function for undo toasts.
 */
export const createBackNavigationToast = (
  title: string,
  caption: string,
  buttonLabel: string,
  kind: ToastData['kind'] = 'success',
): ToastData => ({
  kind,
  title,
  icon: <Icon name="Check" size={32} style={{ height: '24px' }} />,
  content: (removeToast) => {
    return (
      <span>
        {caption}{' '}
        <InlineButton
          color="white"
          onClick={() => {
            history.back();
            removeToast();
          }}>
          {buttonLabel}
        </InlineButton>
      </span>
    );
  },
});
