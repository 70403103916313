export const LineChartIcon = ({
  size = 12,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.47187 8.5C3.89062 8.89062 4.525 8.89062 4.9125 8.5L6.5 6.91563L8.29313 8.70875C8.4875 8.90312 8.74375 9 9 9C9.25625 9 9.51188 8.90234 9.70687 8.70703L14.5006 3.91328C14.8909 3.52297 14.8909 2.88984 14.5007 2.49922C14.11 2.10859 13.4769 2.10859 13.0866 2.49916L9 6.5875L7.20625 4.79375C6.81563 4.40312 6.18281 4.40312 5.79219 4.79375L3.47187 7.0875C3.10938 7.475 3.10938 8.10938 3.47187 8.5ZM15 12H2V0.971875C2 0.447812 1.55219 0 1 0C0.447812 0 0 0.447812 0 0.971875V13C0 13.55 0.45 14 1 14H15C15.5531 14 16 13.5531 16 13C16 12.4469 15.5531 12 15 12Z"
      fill={color}
    />
  </svg>
);
