import { createContext, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate, useLocation, useParams, Navigate, Link } from 'react-router-dom';
import { pickBy } from 'lodash';

import { useAccountsListQuery } from '../../generated/graphql';
import { FullPageLoader } from '../../components/loader';
import { accountOnboardingBasePath, shouldRedirectToOnboarding } from '../../onboarding/utils';
import { hasPermission } from './utils';
import { ErrorBanner } from '../../components/banner';

export interface Account {
  accountId: string;
  slug: string;
  name: string;
  timezone?: string | null;
  isOnboarded: boolean;
  features?: Record<string, boolean>;
  me: {
    role: {
      permissions: { permissionId: string }[];
    };
  };
}

const LS_SELECTED_ACCOUNT_KEY = 'selectedAccount';

interface AccountContext {
  account: Account;
  accounts: Account[];
  switchAccount(accountId: string): void;
  hasPermission(permission: string): boolean;
  enabledFeatures: string[];
}

const defaultContextValue = Symbol();

const AccountContext = createContext<AccountContext | typeof defaultContextValue>(
  defaultContextValue,
);

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (context === defaultContextValue) {
    throw new Error('useAccountContext must be used within a AccountContextProvider');
  }
  return context;
};

const findAccountBySlug = (slug: string, accounts: Account[]) =>
  accounts.find((account) => account.slug === slug);

const findAccountById = (accountId: string, accounts: Account[]) =>
  accounts.find((account) => account.accountId === accountId);

const getLocalStorageAccountId = () => window.localStorage.getItem(LS_SELECTED_ACCOUNT_KEY);

const setLocalStorageAccountId = (accountId: string) =>
  window.localStorage.setItem(LS_SELECTED_ACCOUNT_KEY, accountId);

const prependSlug = (slug: string, path: string) => `/${slug}/${trimPath(path)}`;

const replaceSlug = (slug: string) => `/${slug}`;

const trimPath = (path: string) => (path[0] === '/' ? path.slice(1) : path);

interface AccountContextProviderProps {
  children: React.ReactNode;
}

export const AccountContextProvider = ({ children }: AccountContextProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account: selectedAccountSlug } = useParams();

  const { data, loading, error } = useAccountsListQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.me?.userId !== undefined) {
      const userId = data?.me?.userId;
      Sentry.setUser({ id: userId, email: data?.me?.email });
    }
  }, [data?.me]);

  // Account selection validation
  useEffect(() => {
    const accounts = data?.accounts ?? null;
    if (accounts === null || accounts.length === 0) {
      return;
    }

    const account =
      findAccountBySlug(selectedAccountSlug ?? '', accounts) ??
      findAccountById(getLocalStorageAccountId() ?? '', accounts) ??
      accounts[0];

    if (account.slug !== selectedAccountSlug) {
      navigate(replaceSlug(account.slug));
    }
  }, [selectedAccountSlug, data?.accounts, navigate, location.pathname]);

  const account = findAccountBySlug(selectedAccountSlug ?? '', data?.accounts ?? []) ?? null;

  // Account selection side effects
  useEffect(() => {
    if (account === null) {
      return;
    }
    const accountId = account.accountId;
    setLocalStorageAccountId(accountId);

    Sentry.setTags({
      account_id: accountId,
      account_name: account.name,
      account_slug: account.slug,
    });
  }, [account]);

  const switchAccount = (accountId: string) => {
    const account = findAccountById(accountId, data?.accounts ?? []);
    if (account === undefined) {
      throw new Error(`Cannot switch account to ${accountId}. Account not found.`);
    }
    navigate(replaceSlug(account.slug));
  };

  if (loading) {
    return <FullPageLoader />;
  }

  if (error) {
    return <Navigate to="/login" />;
  }

  if (account === null) {
    return null;
  }

  if (shouldRedirectToOnboarding(account, location.pathname)) {
    return <Navigate to={accountOnboardingBasePath(account)} />;
  }

  const accountData = {
    account,
    accounts: data?.accounts ?? [],
    switchAccount,
    hasPermission: (permission: string) => hasPermission(account.me.role, permission),
    enabledFeatures: Object.keys(pickBy(account.features, Boolean) ?? {}) ?? [],
  };

  return <AccountContext.Provider value={accountData}>{children}</AccountContext.Provider>;
};

export const RedirectToAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading, error } = useAccountsListQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const accounts = data?.accounts ?? null;
    if (accounts === null || accounts.length === 0) {
      return;
    }

    const account = findAccountById(getLocalStorageAccountId() ?? '', accounts) ?? accounts[0];
    navigate(prependSlug(account.slug, location.pathname), { replace: true });
  }, [data?.accounts, navigate, location.pathname]);

  if (loading) {
    return <FullPageLoader />;
  }

  if (error) {
    return <ErrorBanner description="Loading account data failed. Please try again later." />;
  }

  const accounts = data?.accounts ?? null;
  if (accounts === null || accounts.length === 0) {
    return (
      <ErrorBanner
        title="No accounts found"
        description={
          <>
            <p>You do not have access to any accounts. Please contact your administrator.</p>
            <p>
              <Link to="/logout">Log out</Link>
            </p>
          </>
        }
      />
    );
  }

  return null;
};

export function useSelectedAccount() {
  const accountData = useAccountContext();
  return accountData.account;
}

export function useAccountTimezone() {
  const { account } = useAccountContext();
  return account.timezone ?? 'UTC';
}

export function useBuildAccountUrl() {
  const accountData = useAccountContext();
  return (path: string) => prependSlug(accountData.account.slug, path);
}
