import { curveMonotoneX } from '@visx/curve';
import { AreaStack as VisxAreaStack, LinePath } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { Fragment } from 'react';

import { LinearGradient } from '@visx/gradient';

import { generateStackedChartDataByKeys } from './utils';
import { TimeSeriesData } from '../grouped-chart/types';
import { increaseBrightness } from '../utils';

type AreaStackProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
};

export const AreaStack = (props: AreaStackProps) => {
  const { data, keys, dateScale, valueScale } = props;

  const stackedData = generateStackedChartDataByKeys(data, keys);

  return (
    <>
      {data.series.map(({ key, color }) => (
        <LinearGradient
          key={key}
          id={`gradient-${color}`}
          from={color}
          to={color}
          fromOpacity={0.8}
          toOpacity={0.2}
        />
      ))}
      <VisxAreaStack
        keys={keys}
        data={stackedData}
        order="reverse"
        x={(d) => dateScale(d.data.date)}
        y0={(d) => valueScale(d[0])}
        y1={(d) => valueScale(d[1]) ?? valueScale.range()[1]}
        curve={curveMonotoneX}>
        {({ stacks, path }) =>
          stacks.map((stack) => {
            const color = data.series.find(({ key }) => key === stack.key)?.color ?? '';
            return (
              <Fragment key={`stack-${stack.key}`}>
                <path d={path(stack) ?? ''} fill={`url(#gradient-${color})`} fillOpacity={1} />
                <LinePath
                  data={stack.map((item) => ({
                    date: item.data.date,
                    value: item.at(1) ?? 0,
                  }))}
                  x={({ date }) => dateScale(date) ?? 0}
                  y={({ value }) => valueScale(value) ?? 0}
                  stroke={increaseBrightness(color, 20)}
                  strokeOpacity={1}
                  curve={curveMonotoneX}
                />
              </Fragment>
            );
          })
        }
      </VisxAreaStack>
    </>
  );
};
