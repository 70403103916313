import { Fragment, useMemo } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { AxisLeft } from '@visx/axis';

import classNames from 'classnames';

import { NoMatchBanner } from '@/components/banner';
import { formatPropertyValue } from '@/explore/utils/format';
import { useExplorationCellContext } from '@/explore/exploration/exploration-cell-context';
import { Field, ValueClickHandler } from '@/explore/types';

import { Label, TooltipData, ChartTooltipSingleValue } from '../common';
import { SeriesMetaData } from '../grouped-chart/types';
import { increaseBrightness } from '../utils';

import commonStyles, { gridLineColor, barColor } from '../charts.module.scss';
import styles from './histogram-chart.module.scss';

export interface HistogramDataItem {
  bucket_low: number;
  bucket_high: number;
  freq: number;
  bucket: number;
}

const horizontalPadding = 25;
const verticalPadding = 10;
const yAxisLabelOffset = 20;
const paddingAroundLabel = 10;
const textHeight = 20;
const numTicks = 5;

const defaultHeight = 100;

const formatLabel = ({
  bucketHigh,
  bucketLow,
  field,
}: {
  bucketHigh: number | null;
  bucketLow: number | null;
  field: Field;
}) => {
  if (bucketLow === null || bucketHigh === null) {
    return null;
  }

  return bucketLow === bucketHigh
    ? formatPropertyValue(bucketLow, { field, style: 'compact' })
    : `[${formatPropertyValue(bucketLow, { field, style: 'compact' })} ... ${formatPropertyValue(bucketHigh, { field, style: 'compact' })})`;
};

type HistogramVisProps = {
  data: {
    data: HistogramDataItem[];
    series: SeriesMetaData;
  };
  categoryLabel: string;
  valueLabel: string;
  field: Field;
  onValueClick?: ValueClickHandler;
};

const Chart = ({
  data,
  parent: { width, height = defaultHeight },
  categoryLabel,
  field,
  valueLabel,
  onValueClick,
}: HistogramVisProps & { parent: { width: number; height: number } }) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
    return true;
  };

  // do not show null bucket if it's empty
  const filteredData = useMemo(
    () => data.data.filter(({ bucket_low, freq }) => bucket_low !== null || freq > 0),
    [data],
  );

  const xMax = width - horizontalPadding;
  const yMax = height - verticalPadding * 2 - paddingAroundLabel * 2 - textHeight;
  const maxFreq = Math.max(...filteredData.map((d) => d.freq));

  const xScale = useMemo(
    () =>
      scaleBand<number>({
        range: [0, xMax],
        round: true,
        domain: filteredData.map(({ bucket }) => bucket),
        paddingOuter: 0.4,
      }),
    [xMax, filteredData],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        domain: [0, maxFreq],
      }),
    [yMax, maxFreq],
  );

  if (filteredData.length === 0) {
    return <NoMatchBanner />;
  }

  return (
    <div className={styles.histogramChart}>
      <ChartTooltipSingleValue
        tooltipTop={tooltipTop}
        tooltipLeft={tooltipLeft}
        tooltipData={tooltipData}
        tooltipOpen={tooltipOpen}
        categoryLabel={categoryLabel}
        valueLabel={valueLabel}
        seriesColor={barColor}
      />
      <svg width={width} height={height}>
        <GridRows
          top={verticalPadding * 2}
          width={width}
          scale={yScale}
          numTicks={numTicks}
          height={yMax}
          stroke={gridLineColor}
        />
        <AxisLeft
          left={horizontalPadding + yAxisLabelOffset}
          top={verticalPadding * 2}
          scale={yScale}
          numTicks={numTicks}
          hideTicks
          hideAxisLine
          hideZero
          tickClassName={commonStyles.tickLabel}
          tickLabelProps={() => ({
            dy: 10,
            textAnchor: 'end',
          })}
          tickFormat={(n) => formatPropertyValue(n.valueOf(), { type: 'Number', style: 'compact' })}
        />
        <Group>
          {filteredData.map(
            ({ bucket_low: bucketLow, bucket_high: bucketHigh, freq, bucket }, idx) => {
              const label = formatLabel({ bucketLow, bucketHigh, field });
              const value = freq;

              const barWidth = xScale.bandwidth();
              // if there is a value but it's too small to show, show a 2px bar at a minimum
              const barSize = value === null || value === 0 ? 0 : Math.max(yMax - yScale(value), 2);

              const barX = horizontalPadding + (xScale(bucket) ?? 0);
              const barY = yMax - barSize + verticalPadding * 2;

              const isLast = idx === filteredData.length - 1;

              const gap = xScale.step() - xScale.bandwidth();

              return (
                <Fragment key={bucket}>
                  {barSize > 0 && (
                    <>
                      <BarRounded
                        x={barX}
                        y={barY}
                        width={barWidth - 4}
                        height={barSize}
                        fill={data.series.color ?? 'url(#grad-brand)'}
                        onMouseMove={(e) =>
                          handleMouseOver(e, {
                            label,
                            value,
                            type: field.type,
                            format: field.format,
                          })
                        }
                        onMouseOut={hideTooltip}
                        radius={5}
                        top
                        onClick={(e) => {
                          const { clientX, clientY } = e;
                          const range = {
                            start: bucketLow,
                            end: bucketHigh,
                          };
                          onValueClick?.(clientX, clientY, field.key, range);
                        }}
                      />
                      {data.series.showValues && (
                        <text
                          className={styles.valueLabel}
                          x={barX + barWidth / 2}
                          y={barY}
                          dx={-4}
                          dy={-10}
                          fill={increaseBrightness(data.series.color, 20)}>
                          {formatPropertyValue(value, {
                            type: data.series.type,
                            format: data.series.format,
                            style: 'compact',
                          })}
                        </text>
                      )}
                    </>
                  )}
                  <Label
                    verticalAnchor="middle"
                    x={barX - gap / 2}
                    textAnchor="middle"
                    y={height - paddingAroundLabel - textHeight / 2}
                    hideTooltip={hideTooltip}
                    label={formatPropertyValue(bucketLow, {
                      field,
                      style: 'compact',
                    })}
                    value={value}
                    width={barWidth}
                  />
                  {isLast && (
                    <Label
                      verticalAnchor="middle"
                      x={barX + barWidth + gap / 2}
                      textAnchor="middle"
                      y={height - paddingAroundLabel - textHeight / 2}
                      hideTooltip={hideTooltip}
                      label={formatPropertyValue(bucketHigh, {
                        field,
                        style: 'compact',
                      })}
                      value={value}
                      width={barWidth}
                    />
                  )}
                </Fragment>
              );
            },
          )}
        </Group>
      </svg>
    </div>
  );
};

export const Histogram = (
  props: HistogramVisProps & {
    isResized?: boolean;
  },
) => {
  const { isTableVisible } = useExplorationCellContext();

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(commonStyles.singleChart, {
        [commonStyles.customHeight]: !isTableVisible && props.isResized,
      })}>
      {(parent) => <Chart {...props} parent={parent} />}
    </ParentSize>
  );
};
