import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format, isValid, endOfDay } from 'date-fns';

import { Button } from '@/components/button';
import { Input } from '@/components/form/input';

import styles from './custom-time-range-picker.module.scss';

interface CustomTimeRangePickerProps {
  onApply: (fromDate?: Date, toDate?: Date) => void;
  onCancel: () => void;
  defaultFrom: Date;
  defaultTo: Date;
}

export const CustomTimeRangePicker = (props: CustomTimeRangePickerProps) => {
  const { onApply, onCancel, defaultFrom, defaultTo } = props;
  const [fromDate, setFromDate] = useState<Date | undefined>(defaultFrom);
  const [fromMonth, setFromMonth] = useState<Date>(defaultFrom);
  const [toDate, setToDate] = useState<Date | undefined>(defaultTo);
  const [toMonth, setToMonth] = useState<Date>(defaultTo);

  return (
    <div className={styles.customTimeRangePickerWrapper}>
      <div className={styles.customTimeRangePicker}>
        <div>
          <div className={styles.customTimeRangePickerLabel}>
            From
            <Input
              type="text"
              size="regular"
              value={format(fromDate as Date, 'y-MM-dd')}
              onChange={(e) => {
                const date = new Date(e.target.value);

                if (isValid(date) && date < (toDate as Date)) {
                  setFromDate(date);
                  setFromMonth(date);
                }
              }}
            />
          </div>
          <DayPicker
            fromYear={2015}
            toDate={toDate}
            month={fromMonth}
            onMonthChange={(month) => setFromMonth(month)}
            defaultMonth={defaultFrom}
            mode="single"
            onSelect={setFromDate}
            selected={fromDate}
            weekStartsOn={1}
            required
          />
        </div>
        <div>
          <div className={styles.customTimeRangePickerLabel}>
            To
            <Input
              type="text"
              size="regular"
              value={format(toDate as Date, 'y-MM-dd')}
              onChange={(e) => {
                const date = new Date(e.target.value);

                if (isValid(date) && date > (fromDate as Date)) {
                  setToDate(endOfDay(date));
                  setToMonth(date);
                }
              }}
            />
          </div>
          <DayPicker
            fromDate={fromDate}
            toDate={new Date()}
            month={toMonth}
            onMonthChange={(month) => setToMonth(month)}
            mode="single"
            onSelect={(date: Date | undefined) => setToDate(endOfDay(date as Date))}
            selected={toDate}
            weekStartsOn={1}
            required
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => onApply(fromDate, toDate)}
          disabled={!isValid(fromDate) || !isValid(toDate)}>
          Apply
        </Button>
        <Button onClick={() => onCancel()} variant="outlined">
          Cancel
        </Button>
      </div>
    </div>
  );
};
