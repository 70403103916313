import { forwardRef } from 'react';

import classNames from 'classnames';

import { FormInputSize } from '../types';

import style from './input.module.scss';

export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'size' | 'ref'
  > {
  highlighted?: boolean;
  size?: FormInputSize;
}

export const Input = forwardRef(function Input(
  { highlighted, size = 'small', ...props }: InputProps,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <input
      draggable={false}
      ref={ref}
      {...props}
      className={classNames(
        {
          [style.input]: true,
          [style.sizeSmall]: size === 'small',
          [style.sizeMedium]: size === 'regular',
          [style.sizeLarge]: size === 'large',
          [style.highlighted]: highlighted,
        },
        props.className,
      )}
    />
  );
});
