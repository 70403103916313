import { useEffect } from 'react';

import { useSelectedAccount } from '@/lib/accounts/context';
import { OnboardingStateKey, useUpdateOnboardingStateMutation } from '@/graphql';
import { useTrackEvent } from '@/lib/analytics';
import { useGAPageview } from '@/lib/ga/use-ga';

import { Step, StepSection } from '../components';
import { useOnboardingStepCheck } from '../use-onboarding-step-check';
import { OnboardingStep } from '../types';
import { WelcomeSurveyForm, WelcomeSurveyFormData } from './welcome-survey-form';

import styles from './step-welcome-survey.module.scss';

const CurrentStep: OnboardingStep = 'welcomeSurvey';

export const WelcomeSurveyStep = () => {
  const { check: checkOnboardingState } = useOnboardingStepCheck(CurrentStep);

  const [updateOnboardingStateMutation, { data, loading }] = useUpdateOnboardingStateMutation();
  const account = useSelectedAccount();
  const trackEvent = useTrackEvent();
  useGAPageview();

  useEffect(() => {
    trackEvent('Onboarding Welcome Survey Step Opened', { accountId: account.accountId });
  }, [trackEvent, account.accountId]);

  const handleSubmit = async (data: WelcomeSurveyFormData) => {
    await updateOnboardingStateMutation({
      variables: {
        accountId: account.accountId,
        key: OnboardingStateKey.WelcomeSurvey,
        value: data,
      },
    });

    trackEvent('Onboarding Welcome Survey Submitted', { accountId: account.accountId, data });

    checkOnboardingState();
  };

  const surveySubmitted = data !== undefined;

  return (
    <Step subTitle="Let’s set up your account" className={styles.panel}>
      <StepSection title="Welcome!">
        <WelcomeSurveyForm onSubmit={handleSubmit} loading={loading || surveySubmitted} />
      </StepSection>
    </Step>
  );
};
