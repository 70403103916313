import { z } from 'zod';

export type Literal = string | number | boolean | null | undefined;
export type Json = Literal | { [key: string]: Json } | Json[];

export const timeRange = z.union([
  z.literal('custom'),
  z.literal('7d'),
  z.literal('30d'),
  z.literal('90d'),
  z.literal('1y'),
  z.literal('ytd'),
  z.literal('mtd'),
  z.literal('prevmonth'),
  z.literal('prevyear'),
]);
export type TimeRange = z.infer<typeof timeRange>;

export enum TimePrecision {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export const timePrecision = z.nativeEnum(TimePrecision);
