import styles from './google-button.module.scss';

interface GoogleButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export const GoogleButton = ({ title, onClick, disabled }: GoogleButtonProps) => (
  <button type="button" className={styles.googleBtn} onClick={onClick} disabled={disabled}>
    {title}
  </button>
);
