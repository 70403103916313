import { Outlet, useNavigate } from 'react-router-dom';

import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { ContextMenuContextProvider } from '../context-menu/context-menu-context';
import { OverlayContextProvider } from '../overlay/context';
import { LayoutContextProvider } from './layout-context';

import styles from './sidebar-layout.module.scss';

export const SidebarLayout = () => {
  const navigate = useNavigate();

  return (
    <ContextMenuContextProvider>
      <div className={styles.layout}>
        <OverlayContextProvider>
          <LayoutContextProvider onClickLogOut={() => navigate('/logout')}>
            <div className={styles.main}>
              <div className={styles.content}>
                <ErrorBoundary
                  fallback={({ resetError }) => (
                    <GenericFallback fullscreen resetOnNavigate resetError={resetError} />
                  )}>
                  <Outlet />
                </ErrorBoundary>
              </div>
            </div>
          </LayoutContextProvider>
        </OverlayContextProvider>
      </div>
    </ContextMenuContextProvider>
  );
};
