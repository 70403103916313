import { first } from 'lodash';
import { common } from '@gosupersimple/types';

import { Field } from '../types';
import { Select } from '../../components/form/select';
import { fieldToOption } from './utils';
import { SearchInput } from '../../components/form/search-input';
import { getDefaultSorting } from '../utils/slicing';

interface SortingEditorProps {
  fields: Field[];
  sorting: common.Sorting[];
  setSorting(sort: common.Sorting[]): void;
}

export const SortingEditor = (props: SortingEditorProps) => {
  const { fields, sorting, setSorting } = props;

  const sortItem = first(sorting) ?? getDefaultSorting(fields);

  return (
    <>
      <SearchInput
        options={fields.map(fieldToOption)}
        value={sortItem.key}
        onChange={(key) =>
          setSorting([
            {
              ...sortItem,
              key,
            },
          ])
        }
      />
      <div style={{ width: '50%' }}>
        <Select
          options={[
            { label: 'Asc', value: 'ASC' },
            { label: 'Desc', value: 'DESC' },
          ]}
          value={sortItem.direction}
          onChange={(value) =>
            setSorting([
              {
                ...sortItem,
                direction: value as common.Sorting['direction'],
              },
            ])
          }
        />
      </div>
    </>
  );
};
