import { useState } from 'react';

import { Icon } from '@/components/icon';
import { TooltipBase } from '@/components/tooltip';

import styles from './visualisation.module.scss';

export const DataWarning = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={styles.dataWarning}>
      <div
        className={styles.warningHeader}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}>
        <Icon name="AlertTriangle" size={24} />
        Unable to display full data
      </div>
      {isHovered && (
        <TooltipBase>
          Unable to display full chart data due to too many data points.
          <br /> Consider adding a filter or adjusting grouping options.
        </TooltipBase>
      )}
    </div>
  );
};
