import { IconProps } from '../icon';

export type FormInputSize = 'small' | 'regular' | 'large';

export interface Option {
  value: string;
  label: string;
  title?: string;
  disabled?: boolean;
  icon?: IconProps['name'];
}

export interface OptionGroup {
  label: string;
  options: Option[];
}

export type SelectOption = Option | OptionGroup;

export type SelectOptions = SelectOption[];

export const isOptionGroup = (item: Option | OptionGroup): item is OptionGroup => 'options' in item;
