import { Metric } from '../types';

export const formatMetricLabel = (metricName: string) => `Metric: ${metricName}`;

export const metricById = (metrics: Metric[], metricId: string) =>
  metrics.find((metric) => metric.metricId === metricId);

export const metricsByModelId = (metrics: Metric[], modelId: string) =>
  metrics.filter((metric) => metric.definition.modelId === modelId);

export const metricAggregationOptions = (metrics: Metric[]) =>
  metrics.map((metric) => ({ label: formatMetricLabel(metric.name), value: metric.metricId }));
