import { Scanner, Token, TokenType } from '@gosupersimple/penguino';
import {
  Tree,
  NodeType,
  TreeFragment,
  NodeSet,
  Input,
  Parser,
  PartialParse,
  NodePropSource,
} from '@lezer/common';

enum Type {
  Expression = 0,
  Identifier,
  LeftParen,
  RightParen,
  Boolean,
  Comma,
  String,
  Number,
  Null,
  Whitespace,
}

export class PartialPenguinoParser implements PartialParse {
  stoppedAt: number | null = null;

  constructor(
    readonly parser: PenguinoParser,
    readonly input: Input,
    readonly ranges: readonly { from: number; to: number }[],
  ) {}

  get parsedPos() {
    return this.input.length;
  }

  private getTokenType(token: Token) {
    switch (token.type) {
      case TokenType.LeftParen:
        return this.parser.nodeSet.types[Type.LeftParen];
      case TokenType.RightParen:
        return this.parser.nodeSet.types[Type.RightParen];
      case TokenType.Comma:
      case TokenType.Period:
        return this.parser.nodeSet.types[Type.Comma];
      case TokenType.Identifier:
        return this.parser.nodeSet.types[Type.Identifier];
      case TokenType.String:
        return this.parser.nodeSet.types[Type.String];
      case TokenType.Number:
        return this.parser.nodeSet.types[Type.Number];
      case TokenType.True:
      case TokenType.False:
        return this.parser.nodeSet.types[Type.Boolean];
      case TokenType.Null:
        return this.parser.nodeSet.types[Type.Null];
      case TokenType.Whitespace:
      case TokenType.Unknown:
      case TokenType.EOF:
      default:
        return this.parser.nodeSet.types[Type.Identifier];
    }
  }

  advance() {
    const input = this.input.read(0, this.input.length);
    const tokens = new Scanner(input, { safe: true }).scanTokens();

    return new Tree(
      this.parser.nodeSet.types[Type.Expression],
      tokens.map((token) => new PenguinoTokenTree(this.getTokenType(token), token)),
      tokens.map(({ start }) => start),
      input.length,
    );
  }

  stopAt(pos: number) {
    if (this.stoppedAt !== null && this.stoppedAt < pos) {
      throw new RangeError("Can't move stoppedAt forward");
    }
    this.stoppedAt = pos;
  }
}

export class PenguinoParser extends Parser {
  constructor(readonly nodeSet: NodeSet) {
    super();
  }

  createParse(
    input: Input,
    _fragments: readonly TreeFragment[],
    ranges: readonly { from: number; to: number }[],
  ): PartialParse {
    return new PartialPenguinoParser(this, input, ranges);
  }
}

export class PenguinoTokenTree extends Tree {
  constructor(
    tokenType: NodeType,
    readonly token: Token,
  ) {
    super(tokenType, [], [0], token.end - token.start);
  }

  static isPenguinoTokenTree(value: any): value is PenguinoTokenTree {
    return value instanceof PenguinoTokenTree;
  }
}

const nodeTypes = Object.entries(Type)
  .filter(([, id]) => !isNaN(Number(id)))
  .map(([name, id]) => NodeType.define({ id: Number(id), name, top: name === 'Expression' }));

export const penguinoParser = (highlighting: NodePropSource) =>
  new PenguinoParser(new NodeSet(nodeTypes).extend(highlighting));
