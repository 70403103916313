import { generatePipelineId } from '../pipeline/utils';
import { Exploration, PipelineOperation, Model } from '../types';
import { generateCellId } from './exploration';

export const isModelExploration = (exploration: Exploration) =>
  getModelIdFromModelExploration(exploration) !== undefined;

export const getModelIdFromModelExploration = (exploration: Exploration) =>
  exploration.options?.explorationForModelId;

export const getModelExploration = (
  model: Pick<Model, 'name' | 'modelId' | 'labels' | 'description'>,
  operations?: PipelineOperation[],
): Exploration => ({
  explorationId: model.modelId,
  name: model.name,
  description: model.description,
  labels: model.labels,
  parameters: [],
  options: {
    explorationForModelId: model.modelId,
  },
  view: {
    cells: [
      {
        id: generateCellId(),
        kind: 'records',
        title: model.name,
        pipeline: {
          pipelineId: generatePipelineId(),
          baseModelId: model.modelId,
          operations: operations ?? [],
        },
      },
    ],
  },
});
