import { useLayoutEffect, useRef, useState } from 'react';

import { DropdownMenu, DropdownMenuItem } from '../dropdown';

import styles from './context-menu.module.scss';

const PADDING = 10;

export const ContextMenu = ({
  items,
  ...props
}: {
  items: DropdownMenuItem[];
  x: number;
  y: number;
}) => {
  const element = useRef<HTMLDivElement>(null);

  const [x, setX] = useState(props.x);
  const [y, setY] = useState(props.y);

  useLayoutEffect(() => {
    if (element.current) {
      const { width, height } = element.current.getBoundingClientRect();
      const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

      if (x + width + PADDING > screenWidth) {
        setX(screenWidth - width - PADDING);
      }

      if (y + height + PADDING > screenHeight) {
        setY(screenHeight - height - PADDING);
      }
    }
  }, [x, setX, y, setY]);

  return (
    <div ref={element} className={styles.contextMenu} style={{ left: `${x}px`, top: `${y}px` }}>
      <DropdownMenu items={items} className={styles.dropdownMenu} />
    </div>
  );
};
