export const ChevronDownThinIcon = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"
    />
  </svg>
);
