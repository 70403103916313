import { first } from 'lodash';
import { ApolloError } from '@apollo/client';

export const isTimeoutError = (error: ApolloError) => {
  if (error.networkError && 'statusCode' in error.networkError) {
    // Load balancer timeout reached
    return error.networkError.statusCode === 502;
  }

  return first(error.graphQLErrors)?.extensions.code === 'QUERY_TIMEOUT';
};
