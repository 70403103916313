import { ScaleLinear, ScaleTime } from 'd3-scale';

import { AreaClosed, LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';

type AreaSeriesProps = {
  data: { date: Date; value: number }[];
  fillColor: string;
  strokeColor: string;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
};

export const AreaSeries = (props: AreaSeriesProps) => {
  const { data, fillColor, strokeColor, valueScale, dateScale } = props;

  return (
    <Group>
      <LinearGradient
        id={`gradient-${fillColor}`}
        from={fillColor}
        to={fillColor}
        fromOpacity={1}
        toOpacity={0.3}
      />
      <AreaClosed
        data={data}
        x={({ date }) => dateScale(date) ?? 0}
        y0={({ value }) => valueScale(value) ?? 0}
        y1={() => valueScale(0)}
        yScale={valueScale}
        fill={`url(#gradient-${fillColor})`}
        curve={curveMonotoneX}
      />
      <LinePath
        data={data}
        x={({ date }) => dateScale(date) ?? 0}
        y={({ value }) => valueScale(value) ?? 0}
        stroke={strokeColor}
        strokeOpacity={1}
        curve={curveMonotoneX}
      />
    </Group>
  );
};
