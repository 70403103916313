const dataSymbol = Symbol('path-store-trunk');

type InternalMap<K, V> = Map<K | symbol, InternalMap<K, V> | V>;

export class ArrayKeyedMap<K, V> {
  private root: InternalMap<K, V>;

  public size: number;

  constructor(initialEntries: [K[], V][] = []) {
    this.root = new Map();
    this.size = 0;
    for (const [k, v] of initialEntries) {
      this.set(k, v);
    }
  }

  set(path: K[], value: V) {
    let map = this.root;
    for (const item of path) {
      let nextMap = map.get(item) as InternalMap<K, V> | undefined;
      if (nextMap === undefined) {
        // Create next map if none exists
        nextMap = new Map();
        map.set(item, nextMap);
      }
      map = nextMap;
    }

    if (!map.has(dataSymbol)) {
      this.size += 1;
    }
    map.set(dataSymbol, value);
    return this;
  }

  has(path: K[]) {
    let map = this.root;
    for (const item of path) {
      const nextMap = map.get(item) as InternalMap<K, V> | undefined;
      if (nextMap) {
        map = nextMap;
      } else {
        return false;
      }
    }
    return map.has(dataSymbol);
  }

  get(path: K[]): V | undefined {
    let map = this.root;
    for (const item of path) {
      const nextMap = map.get(item) as InternalMap<K, V> | undefined;
      if (nextMap === undefined) {
        return undefined;
      }
      map = nextMap;
    }
    return map.get(dataSymbol) as V | undefined;
  }

  delete(path: K[]) {
    let map = this.root;
    const stack = [];
    for (const item of path) {
      const nextMap = map.get(item) as InternalMap<K, V> | undefined;
      if (nextMap !== undefined) {
        stack.unshift({ parent: map, child: nextMap, item });
        map = nextMap;
      } else {
        // Nothing to delete
        return false;
      }
    }

    // Reached end of path.  Delete data, if it exists.
    const hadPreviousValue = map.delete(dataSymbol);
    // If something was deleted, decrement size and go through the stack of
    // visited maps, trimming any that are now empty.
    if (hadPreviousValue) {
      this.size -= 1;
      for (const { parent, child, item } of stack) {
        if (child.size === 0) {
          parent.delete(item);
        }
      }
    }
    return hadPreviousValue;
  }

  clear() {
    this.root.clear();
    this.size = 0;
  }

  hasPrefix(path: K[]) {
    let map = this.root;
    for (const item of path) {
      const nextMap = map.get(item) as InternalMap<K, V> | undefined;
      if (nextMap === undefined) {
        return false;
      }
      map = nextMap;
    }
    return true;
  }
}
