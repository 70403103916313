import { IconButton } from '../../button';

import styles from './form-field.module.scss';

interface FormFieldProps {
  children: React.ReactNode;
  label: string;
  error?: string;
  onRemove?(): void;
}

export const FormField = ({ children, label, error, onRemove }: FormFieldProps) => (
  <div className={styles.formField}>
    <label className={styles.label}>{label}</label>
    <div className={styles.content}>
      <div className={styles.row}>
        {children}
        {onRemove !== undefined && (
          <IconButton icon="Trash2" title="Remove" size="small" onClick={onRemove} />
        )}
      </div>
      {error !== undefined && <div className={styles.fieldError}>{error}</div>}
    </div>
  </div>
);
