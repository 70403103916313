import { IconProps } from './types';

export const Model = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 29.2008C21.4558 29.2008 27.5 26.8727 27.5 24.0008V6.00078C27.5 3.1289 21.4558 0.800781 14 0.800781C6.54416 0.800781 0.5 3.1289 0.5 6.00078V24.0008C0.500017 26.8727 6.54417 29.2008 14 29.2008ZM22.5395 4.93669C23.5216 5.31499 24.1521 5.69983 24.5017 6.00078C24.1521 6.30174 23.5216 6.68657 22.5395 7.06487C20.492 7.85354 17.4737 8.40078 14 8.40078C10.5263 8.40078 7.508 7.85354 5.4605 7.06487C4.47838 6.68657 3.84789 6.30173 3.4983 6.00078C3.84789 5.69983 4.47838 5.31499 5.4605 4.93669C7.508 4.14803 10.5263 3.60078 14 3.60078C17.4737 3.60078 20.492 4.14803 22.5395 4.93669ZM24.5017 9.26866C22.0268 10.4472 18.2419 11.2008 14 11.2008C9.75813 11.2008 5.9732 10.4472 3.4983 9.26865L3.49831 24.0008C3.8479 24.3017 4.4784 24.6866 5.4605 25.0649C7.508 25.8535 10.5263 26.4008 14 26.4008C17.4737 26.4008 20.492 25.8535 22.5395 25.0649C23.5216 24.6866 24.1521 24.3017 24.5017 24.0008L24.5017 9.26866Z"
      fill={color}
    />
  </svg>
);
