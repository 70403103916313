export const SearchIcon = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33301 3.31899C4.75568 3.31899 2.66634 5.40833 2.66634 7.98566C2.66634 10.563 4.75568 12.6523 7.33301 12.6523C9.91034 12.6523 11.9997 10.563 11.9997 7.98566C11.9997 5.40833 9.91034 3.31899 7.33301 3.31899ZM1.33301 7.98566C1.33301 4.67195 4.0193 1.98566 7.33301 1.98566C10.6467 1.98566 13.333 4.67195 13.333 7.98566C13.333 11.2994 10.6467 13.9857 7.33301 13.9857C4.0193 13.9857 1.33301 11.2994 1.33301 7.98566Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6283 11.2809C10.8886 11.0206 11.3107 11.0206 11.5711 11.2809L14.4711 14.1809C14.7314 14.4413 14.7314 14.8634 14.4711 15.1237C14.2107 15.3841 13.7886 15.3841 13.5283 15.1237L10.6283 12.2237C10.3679 11.9634 10.3679 11.5413 10.6283 11.2809Z"
      fill={color}
    />
  </svg>
);
