import { createContext, useContext, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';

import { useAccountContext } from '@/lib/accounts/context';

import { Exploration } from '../types';
import { generateExplorationId } from '../utils';
import { ExplorationStateStore } from './types';
import { createApolloStore } from './apollo-store';
import { createInMemoryStore } from './in-memory-store';

interface ExplorationStorageContext {
  setExploration: (exploration: Exploration) => Exploration;
  getExploration: (explorationId: string) => Promise<Exploration | undefined>;
}

const defaultContextValue = Symbol();

const ExplorationStorageContext = createContext<
  ExplorationStorageContext | typeof defaultContextValue
>(defaultContextValue);

export const useExplorationStorageContext = () => {
  const context = useContext(ExplorationStorageContext);
  if (context === defaultContextValue) {
    throw new Error(
      'useExplorationStorageContext must be used within a ExplorationStorageProvider',
    );
  }
  return context;
};

interface ExplorationStorageProviderProps {
  explorations: Exploration[];
  children: React.ReactNode;
}

export const ExplorationStorageProvider = ({
  explorations,
  children,
}: ExplorationStorageProviderProps) => {
  const client = useApolloClient();
  const { account } = useAccountContext();

  const stores = useMemo(
    () => [createInMemoryStore(explorations), createApolloStore(client, account.accountId)],
    [explorations, client, account],
  );

  const updateStores = (stores: ExplorationStateStore[], exploration: Exploration) => {
    for (const store of stores) {
      store.setExploration(exploration);
    }
  };

  const setExploration = (exploration: Exploration) => {
    const newExploration = {
      ...exploration,
      explorationId: generateExplorationId(),
      options: { ...exploration.options, previousStateId: exploration.explorationId },
    };

    updateStores(stores, newExploration);

    return newExploration;
  };

  const getExploration = async (explorationId: string) => {
    const upstream: ExplorationStateStore[] = [];

    for (const store of stores) {
      const exploration = await store.getExploration(explorationId);

      if (exploration !== undefined) {
        updateStores(upstream, exploration);

        return exploration;
      }

      upstream.push(store);
    }
  };

  return (
    <ExplorationStorageContext.Provider value={{ setExploration, getExploration }}>
      {children}
    </ExplorationStorageContext.Provider>
  );
};
