import { createContext, useContext } from 'react';
import { useApolloClient } from '@apollo/client';

import { EnumOptions, Exploration, Metric, Model } from './types';
import { EnumOptionsQuery, EnumOptionsQueryVariables } from '../graphql';
import * as queries from '../generated/operation-documents';
import { getModelOrThrow } from './model/utils';

interface MetadataContext {
  models: Model[];
  explorations: Exploration[];
  metrics: Metric[];
  getModel: (modelId: string) => Model;
  getEnumOptions: (modelId: string, fieldKey: string) => Promise<EnumOptions>;
}

const defaultContextValue = Symbol();

const MetadataContext = createContext<MetadataContext | typeof defaultContextValue>(
  defaultContextValue,
);

// Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/pull/24509#issuecomment-1545820830
export const useMetadataContext = () => {
  const context = useContext(MetadataContext);
  if (context === defaultContextValue) {
    throw new Error('useMetadataContext must be used within a MetadataContextProvider');
  }
  return context;
};

interface MetadataContextProviderProps {
  accountId: string;
  models: Model[];
  explorations: Exploration[];
  metrics: Metric[];
  children: React.ReactNode;
}

// The purpose of this context is to provide customer configuration to downstream components. For example,
// explorations and models.
export const MetadataContextProvider = (props: MetadataContextProviderProps) => {
  const { accountId, models, explorations, metrics } = props;

  // Cannot use useEnumOptionsLazyQuery directly here because it will cause infinite loop. Falling
  // back to Apollo client works.
  const client = useApolloClient();

  const getEnumOptions = async (modelId: string, propertyKey: string) => {
    const { data } = await client.query<EnumOptionsQuery, EnumOptionsQueryVariables>({
      query: queries.EnumOptions,
      variables: { accountId, modelId, propertyKey },
    });

    return data?.account?.model?.property?.enumOptions ?? [];
  };

  return (
    <MetadataContext.Provider
      value={{
        models,
        explorations,
        metrics,
        getModel: (modelId) => getModelOrThrow(models, modelId),
        getEnumOptions,
      }}>
      {props.children}
    </MetadataContext.Provider>
  );
};
