import { useState } from 'react';

import { format } from 'date-fns';

import { DateRangeOptions, getDatesFromTimeRange } from '@/lib/date';
import { TimePrecision, TimeRange } from '@/lib/types';
import { CustomTimeRangePicker } from '@/explore/components/custom-time-range-picker';

import { Select } from '../select';
import { Button } from '../../button';

import styles from './date-range-selector.module.scss';

const DateFormat = 'MMM d, yyyy';
const ShortDateFormat = 'MMM d';

const getDateRangeLabel = (startDate: Date, endDate: Date) => {
  if (startDate.getFullYear() === endDate.getFullYear()) {
    return `${format(startDate, ShortDateFormat)} - ${format(endDate, DateFormat)}`;
  }
  return `${format(startDate, DateFormat)} - ${format(endDate, DateFormat)}`;
};

interface DateRangeSelectorProps {
  range: TimeRange;
  precision: TimePrecision;
  startDate: Date;
  endDate: Date;
  onChange: ({
    range,
    precision,
    startDate,
    endDate,
  }: {
    range: TimeRange;
    precision: TimePrecision;
    startDate: Date;
    endDate: Date;
  }) => void;
}

export const DateRangeSelector = (props: DateRangeSelectorProps) => {
  const { range, precision, startDate, endDate, onChange } = props;

  const [showCustomPicker, setShowCustomPicker] = useState(false);

  const options =
    range === 'custom'
      ? [
          {
            label: getDateRangeLabel(startDate, endDate),
            value: 'custom',
          },
          ...DateRangeOptions.slice(1),
        ]
      : DateRangeOptions;

  return (
    <>
      <div className={styles.dateRangeInput}>
        <Select
          value={range}
          onChange={(range: TimeRange) => {
            if (range === 'custom') {
              onChange({
                range,
                precision,
                startDate,
                endDate,
              });
              setShowCustomPicker(true);
              return;
            }
            onChange({
              range,
              precision,
              ...getDatesFromTimeRange(range, precision),
            });
            setShowCustomPicker(false);
          }}
          options={options}
        />
        {range === 'custom' && (
          <Button variant="outlined" onClick={() => setShowCustomPicker(true)} size="small">
            Change
          </Button>
        )}
        <Select
          options={[
            { value: TimePrecision.Daily, label: 'Daily' },
            { value: TimePrecision.Weekly, label: 'Weekly' },
            { value: TimePrecision.Monthly, label: 'Monthly' },
          ]}
          value={precision}
          onChange={(newPrecision: TimePrecision) => {
            onChange({
              range,
              precision: newPrecision,
              ...getDatesFromTimeRange(range, newPrecision),
            });
          }}
        />
      </div>
      {showCustomPicker && (
        <div className={styles.customTimeRangePicker}>
          <CustomTimeRangePicker
            defaultFrom={startDate}
            defaultTo={endDate}
            onCancel={() => setShowCustomPicker(false)}
            onApply={(fromDate, toDate) => {
              onChange({
                range: 'custom',
                precision,
                startDate: fromDate ?? new Date(),
                endDate: toDate ?? new Date(),
              });
              setShowCustomPicker(false);
            }}
          />
        </div>
      )}
    </>
  );
};
