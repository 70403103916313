import { scaleLinear, scaleTime } from '@visx/scale';
import { NumberValue, ScaleTime } from 'd3-scale';

/**
 * Creates a time scale with padding.
 * @param config The scale time configuration.
 * @param padding The padding to add to the scale.
 * @returns The time scale with padding.
 */
export function scaleTimeWithPadding(
  config: Parameters<typeof scaleLinear<number>>[0],
  padding: number,
): ScaleTime<number, number> {
  config = config ?? {};
  padding = padding ?? 0;
  config.range = [config.range?.[0] ?? 0, (config.range?.[1] ?? 0) - 2 * padding];

  const _scale = scaleTime(config);

  function scale(value2: Date | NumberValue) {
    return _scale(value2) + padding;
  }
  scale.domain = _scale.domain;
  scale.invert = (value: number) => _scale.invert(value - padding);
  scale.range = _scale.range;
  scale.rangeRound = _scale.rangeRound;
  scale.clamp = _scale.clamp;
  scale.interpolate = _scale.interpolate;
  scale.rangeRound = _scale.rangeRound;
  scale.ticks = _scale.ticks;
  scale.tickFormat = _scale.tickFormat;
  scale.nice = _scale.nice;
  scale.copy = _scale.copy;
  scale.unknown = _scale.unknown;

  return scale;
}
