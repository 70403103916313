import { CodePreview } from '../code-preview';

interface YamlPreviewProps {
  yaml: string;
  onClose: () => void;
}

export const YamlPreview = (props: YamlPreviewProps) => (
  <CodePreview code={props.yaml} language="YAML" onClose={props.onClose} />
);
