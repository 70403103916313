import { AreaStack as VisxAreaStack } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';

import { TimeSeriesData } from '../grouped-chart/types';
import { generateStackedChartDataByKeys } from './utils';
import { SeriesValues } from './series-values';

type LineStackProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  reverse?: boolean;
  avoidOverflow?: boolean;
};

export const StackValues = (props: LineStackProps) => {
  const { data, keys, dateScale, valueScale, reverse = false, avoidOverflow } = props;

  const stackedData = generateStackedChartDataByKeys(data, keys);

  const showValues: boolean[] = data.series.map((series) => series.showValues);

  return (
    <VisxAreaStack
      keys={keys}
      data={stackedData}
      order={reverse ? 'reverse' : undefined}
      x={(d) => dateScale(d.data.date)}
      y0={(d) => valueScale(d[0])}
      y1={(d) => valueScale(d[1]) ?? valueScale.range()[0]}>
      {({ stacks }) => {
        return stacks.map((stack, index) => {
          if (!showValues[index]) {
            return null;
          }
          const series = data.series.find(({ key }) => key === stack.key);
          if (series === undefined) {
            throw new Error('Cannot find series');
          }
          return (
            <SeriesValues
              key={series.key}
              series={series}
              data={stack.map((item) => ({
                date: item.data.date,
                cumulativeValue: item.at(1) ?? 0,
                value: (item.at(1) ?? 0) - (item.at(0) ?? 0),
              }))}
              dateScale={dateScale}
              valueScale={valueScale}
              avoidOverflow={avoidOverflow}
            />
          );
        });
      }}
    </VisxAreaStack>
  );
};
