import { omit } from 'lodash';

import { CellViewOptions } from '@/explore/types';
import { Json } from '@/lib/types';

export const setViewOption = <T extends { viewOptions?: CellViewOptions }>(
  cell: T,
  key: string,
  value: Json,
): T => {
  if (value === undefined) {
    return removeViewOption(cell, key);
  }
  return {
    ...cell,
    viewOptions: {
      ...cell.viewOptions,
      [key]: value,
    },
  };
};

export const removeViewOption = <T extends { viewOptions?: CellViewOptions }>(
  cell: T,
  key: string,
): T => {
  return {
    ...cell,
    viewOptions: omit(cell.viewOptions, key),
  };
};

export const setViewOptions = <T extends { viewOptions?: CellViewOptions }>(
  cell: T,
  values: Record<string, Json>,
): T => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    return setViewOption(acc, key, value);
  }, cell);
};
