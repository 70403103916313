import { Exploration } from '../types';

interface LinkableProperty {
  key: string;
  relation?: { key: string; modelId?: string } | null;
  pk?: boolean;
}

export interface LinkingProperty extends LinkableProperty {
  buildLink?: (record: Record<string, unknown>) => string;
}

export const isExplorationReferencedByProperty = <T extends LinkableProperty>(
  exploration: Exploration,
  property: T,
) =>
  exploration.parameters.some(
    ({ key, modelId }) => key === property.relation?.key && modelId === property.relation?.modelId,
  );

export const getLinkableExplorations = <T extends LinkableProperty>(
  properties: T[],
  explorations: Exploration[],
) => {
  const isParameterReferencedByProperty = (parameter: Exploration['parameters'][number]) =>
    properties.some(
      ({ relation }) => relation?.key === parameter.key && relation?.modelId === parameter.modelId,
    );

  const allParametersReferencedByProperties = (exploration: Exploration) =>
    exploration.parameters.length > 0 &&
    exploration.parameters.every(isParameterReferencedByProperty);

  return explorations
    .filter(allParametersReferencedByProperties)
    .sort((a, b) => a.parameters.length - b.parameters.length);
};
