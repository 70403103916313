import { useDeleteExplorationMutation, useUpsertExplorationMutation } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';

import { exportExploration } from '../output';
import { Exploration } from '../types';
import { generateExplorationId } from '../utils';

export const useExplorationUpsert = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useUpsertExplorationMutation();

  const upsertExploration = async (exploration: Exploration) => {
    const newExplorationId =
      exploration.options?.explorationForModelId === exploration.options?.explorationSourceId
        ? generateExplorationId()
        : (exploration.options?.explorationSourceId ?? exploration.explorationId);

    const input = exportExploration({ ...exploration, explorationId: newExplorationId });

    const result = await mutation({
      variables: { accountId: account.accountId, input },
      refetchQueries: ['Explorations', 'Navigation'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }

    const explorationId = result.data?.upsertExploration.explorationId;
    if (explorationId === undefined) {
      throw new Error('Failed to upsert exploration');
    }

    return { explorationId };
  };

  return { upsertExploration, loading, error };
};

export const useExplorationDelete = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useDeleteExplorationMutation();

  const deleteExploration = async (explorationId: string) => {
    await mutation({
      variables: { accountId: account.accountId, explorationId },
      refetchQueries: ['Explorations'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }
  };

  return { deleteExploration, loading, error };
};
