import { Section } from '../common';
import { ConnectionListItem, ConnectionList } from './list';

import commonStyles from '../common/common.module.scss';

type ConnectionsSectionProps = { connections: ConnectionListItem[] };

export const ConnectionsSection = ({ connections }: ConnectionsSectionProps) => {
  return (
    <Section title="Connections">
      {connections.length === 0 ? (
        <div className={commonStyles.emptyView}>
          No custom connections configured for your account.
        </div>
      ) : (
        <ConnectionList connections={connections} />
      )}
    </Section>
  );
};
