import { useState } from 'react';

/**
 * A hook to use the Intersection Observer API.
 */
type QueryLoadConditionReturn = [
  /**
   * The onCompleted callback function.
   */
  onCompleted: () => void,
  /**
   * The onError callback function.
   */
  onError: () => void,
  /**
   * A boolean indicating if the query should be skipped.
   */
  skip: boolean,
];

/**
 * Based on the conditions, decide whether to skip or load the GraphQL query. Keeps track of whether the query has
 * already been loaded. Query should not be skipped in subsequent renders if it has already been loaded once.
 *
 * @param loadIf list of conditions that must be true for the query to be loaded
 * @returns helpers to pass onto GraphQL query hook
 */
export const useQueryLoadCondition = (...loadIf: boolean[]): QueryLoadConditionReturn => {
  const [isAlreadyLoaded, setIsAlreadyLoaded] = useState(false);

  const onCompleted = () => setIsAlreadyLoaded(true);
  const onError = () => setIsAlreadyLoaded(true);

  const skip = !isAlreadyLoaded && !loadIf.every((x) => x);

  return [onCompleted, onError, skip];
};
