export const getMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }

  return String(error);
};

export * from './boundary';
export * from './generic-fallback';
