import { useGAPageview } from '@/lib/ga/use-ga';

import { Loader } from '../../components/loader';
import { useOnboardingStepCheck } from '../use-onboarding-step-check';

export const OnboardingStart = () => {
  useOnboardingStepCheck('start');
  useGAPageview();

  return <Loader />;
};
