import { useCallback } from 'react';

import { useContextMenu } from '@/components/context-menu/context-menu-context';
import { DropdownMenuItem } from '@/components/dropdown';
import { useExplorationCellContext } from '@/explore/exploration/exploration-cell-context';
import { useExplorationContext } from '@/explore/exploration/exploration-context';

import { DataTableProperty } from '../types';
import { buildFilterMenuItem, buildGroupByMenuItem } from './menu-items';

interface OpenColumnContextMenuParams {
  position: {
    x: number;
    y: number;
  };
  property: DataTableProperty;
  canEditPipeline: boolean;
}

export const useColumnContextMenu = () => {
  const { openContextMenu } = useContextMenu();
  const { openEditor, openAddForm } = useExplorationContext();
  const { cellIndex } = useExplorationCellContext();

  const buildMenu = useCallback(
    (property: DataTableProperty, canEditPipeline: boolean) => {
      const menuItems: DropdownMenuItem[] = [];

      if (canEditPipeline) {
        menuItems.push(buildFilterMenuItem(cellIndex, openEditor, openAddForm, property));
        menuItems.push(buildGroupByMenuItem(cellIndex, openEditor, openAddForm, property));
      }

      return menuItems;
    },
    [openEditor, cellIndex, openAddForm],
  );

  return (params: OpenColumnContextMenuParams) => {
    const { position, property, canEditPipeline } = params;
    openContextMenu({
      position,
      items: buildMenu(property, canEditPipeline),
    });
  };
};
