import { MouseEventHandler, MouseEvent } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../../components/icon';
import {
  Token,
  OperationDescription,
  descriptionToString,
} from '../components/pipeline/operation-description';
import { IconButton } from '../../components/button';

import styles from './pipeline.module.scss';

interface SavedOperationProps {
  icon: IconProps['name'];
  title: string;
  description: Token;
  onClick?: () => void;
  onRemove?: MouseEventHandler;
  clickable?: boolean;
  onChangeDisabled?: (e: MouseEvent, disabled: boolean) => void;
  disabled?: boolean;
}

export const SavedOperation = ({
  icon,
  title,
  description,
  onClick,
  onRemove,
  onChangeDisabled,
  disabled = false,
}: SavedOperationProps) => (
  <div
    className={classNames(styles.savedOperation, {
      [styles.clickable]: !disabled && Boolean(onClick),
      [styles.disabled]: disabled,
    })}
    onClick={disabled ? undefined : onClick}>
    <div className={styles.operationIcon}>
      <Icon name={icon} size={12} />
    </div>
    <div className={styles.operationTitle} title={title}>
      {title}
    </div>
    <div className={styles.operationDescription} title={descriptionToString(description)}>
      <OperationDescription
        description={description}
        classNames={{ expression: styles.expression }}
      />
    </div>
    <div className={styles.actions}>
      {onChangeDisabled &&
        (disabled ? (
          <IconButton
            icon="EyeOff"
            title="Enable operation"
            className={classNames(styles.action, styles.highlight)}
            onClick={(event) => onChangeDisabled(event, false)}
          />
        ) : (
          <IconButton
            icon="Eye"
            title="Disable operation"
            className={styles.action}
            onClick={(event) => onChangeDisabled(event, true)}
          />
        ))}
      {onRemove && (
        <IconButton
          icon="Trash2"
          title="Remove operation"
          className={styles.action}
          disabled={disabled}
          onClick={disabled ? undefined : onRemove}
        />
      )}
    </div>
  </div>
);
