import { notNil } from '../lib/utils';
import { QuerySortBy, QuerySortDirection } from '../generated/graphql';

type Edge<T> =
  | {
      cursor?: string | null;
      node?: T | null;
    }
  | null
  | undefined;

type Connection<T> = {
  edges?: Edge<T>[];
  pageInfo?: {
    hasNextPage?: boolean | null;
    endCursor?: string | null;
  };
};

export const getNodes = <T>(items: Connection<T> | undefined): T[] =>
  (items ? (items.edges ?? []) : []).map((edge) => edge?.node ?? null).filter(notNil);

export const isEmptyNodeList = <T>(items: Connection<T> | undefined): boolean =>
  items?.edges?.length === 0;

const isSort = (sort: { key: string; direction: string }): sort is QuerySortBy =>
  sort.direction === QuerySortDirection.Asc || sort.direction === QuerySortDirection.Desc;

export const mapSort = (sort: { key: string; direction: string }[] | undefined): QuerySortBy[] =>
  (sort ?? []).filter(isSort).map(({ key, direction }) => ({ key, direction }));
