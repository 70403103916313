import { useCallback } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { useAccountTimezone, useBuildAccountUrl } from '@/lib/accounts/context';
import { useContextMenu } from '@/components/context-menu/context-menu-context';
import { DropdownMenuItem } from '@/components/dropdown';
import { useToastContext } from '@/components/toast';
import { isValidLink } from '@/explore/utils';
import { useExplorationCellContext } from '@/explore/exploration/exploration-cell-context';
import { useExplorationContext } from '@/explore/exploration/exploration-context';

import { DataTableProperty } from '../types';
import {
  buildCopyValueMenuItem,
  buildDrilldownMenuItem,
  buildFilterMenuItem,
  buildLinkMenuItem,
  buildOpenDetailViewMenuItem,
} from './menu-items';

interface OpenCellContextMenuParams {
  position: {
    x: number;
    y: number;
  };
  row: Record<string, unknown>;
  property: DataTableProperty;
  value: unknown;
  canEditPipeline: boolean;
}

export const useCellContextMenu = () => {
  const [, copyToClipboard] = useCopyToClipboard();
  const { openContextMenu } = useContextMenu();
  const addToast = useToastContext();
  const timezone = useAccountTimezone();
  const buildAccountUrl = useBuildAccountUrl();
  const { openEditor, openAddForm, closeEditor, scrollToCell } = useExplorationContext();
  const { cellIndex, drilldownByProperty } = useExplorationCellContext();

  const buildMenu = useCallback(
    (
      property: DataTableProperty,
      value: unknown,
      row: Record<string, unknown>,
      canEditPipeline: boolean,
    ) => {
      const menuItems: DropdownMenuItem[] = [];

      if (property.buildLink !== undefined && value !== null) {
        const link = buildAccountUrl(property.buildLink(row));
        menuItems.push(buildOpenDetailViewMenuItem(property, link, closeEditor, scrollToCell));
      }

      if (canEditPipeline) {
        menuItems.push(
          buildFilterMenuItem(cellIndex, openEditor, openAddForm, property, value, timezone),
        );
      }

      if (property.isAggregated === true) {
        menuItems.push(buildDrilldownMenuItem(property, row, timezone, drilldownByProperty));
      }

      menuItems.push(buildCopyValueMenuItem(property, value, timezone, addToast, copyToClipboard));

      if (isValidLink(value)) {
        menuItems.push(buildLinkMenuItem(value));
      }

      return menuItems;
    },
    [
      buildAccountUrl,
      closeEditor,
      openEditor,
      cellIndex,
      openAddForm,
      drilldownByProperty,
      copyToClipboard,
      timezone,
      addToast,
      scrollToCell,
    ],
  );

  return (params: OpenCellContextMenuParams) => {
    const { position, row, property, value, canEditPipeline } = params;
    openContextMenu({
      position,
      items: buildMenu(property, value, row, canEditPipeline),
    });
  };
};
