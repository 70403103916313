import { useState, useEffect, Fragment, useMemo } from 'react';
import { first, isBoolean, isEmpty, isEqual, isString } from 'lodash';
import classNames from 'classnames';
import { format, isValid, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { common } from '@gosupersimple/types';

import { useTrackEvent } from '@/lib/analytics';
import {
  containsISODateString,
  formatLocalDate,
  getDefaultFormattedLocalDate,
  LocalDateTimeFormat,
} from '@/lib/date';
import { SearchInput } from '@/components/form/search-input';
import { Form } from '@/components/form';
import { Button, IconButton, InlineButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { Select } from '@/components/form/select';
import { PenguinoInput, PenguinoVersion } from '@/components/form/penguino-input';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { DateTimeInput } from '@/components/form/datetime-input';
import { Input } from '@/components/form/input';

import { useDeepCompareEffect } from '@/lib/hooks/use-deep-compare-hook';

import type {
  FilterOperation,
  Field,
  Fields,
  FilterCondition,
  CompositeFilterCondition,
  FilterValue,
  EnumOptions,
  VariableDefinition,
  FilterOperator,
  Model,
  NumberRange,
} from '../types';
import { fieldToOption } from './utils';
import { getVariableColor, getVariableExpression, isNumberType } from '../utils';
import { useMetadataContext } from '../metadata-context';
import { useExplorationContext } from '../exploration/exploration-context';
import {
  getCompositeConditionKeys,
  isSingleFilterCondition,
  isValueExpression,
} from '../pipeline/operation';
import { parseFilterValue } from './utils/filter';
import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';
import { FieldGroup } from '../pipeline/utils';
import { useDirtyContext } from '../dirty-context';
import { VariableReferenceBadge } from '../components/variable-reference-badge';

import { dateRangeFromPrecision } from '../utils/drilldown';

import form from '@/components/form/form.module.scss';
import styles from './pipeline.module.scss';

import {
  variableColor1,
  variableColor2,
  variableColor3,
  variableColor4,
  variableColor5,
  variableColor6,
} from '../exploration/exploration.module.scss';

const filterBinaryOperators = [
  '==',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
  'icontains',
  'noticontains',
  'arrcontains',
] as const;
const filterUnaryOperators = ['istrue', 'isfalse', 'isnull', 'isnotnull'] as const;
const filterOperators = [...filterBinaryOperators, ...filterUnaryOperators] as const;

// UI augments some and omits some operators that the API supports
type BinaryOperator = (typeof filterBinaryOperators)[number];
type UnaryOperator = (typeof filterUnaryOperators)[number];
type FilterOperatorExtended = BinaryOperator | UnaryOperator;

const operatorLabels: { [key in FilterOperatorExtended]: string } = {
  '==': '= (equals)',
  '!=': '≠ (does not equal)',
  '>': '> (is greater than)',
  '<': '< (is less than)',
  '>=': '≥ (is greater than or equal to)',
  '<=': '≤ (is less than or equal to)',
  noticontains: 'does not contain text',
  icontains: 'contains text',
  arrcontains: 'array contains',
  isnull: 'has no value (is null)',
  isnotnull: 'has a value (not null)',
  istrue: 'Yes',
  isfalse: 'No',
};

interface FilterProps {
  fields: Fields;
}

interface FilterFormProps extends FilterProps {
  operation?: FilterOperation;
  setOperation: (operation: FilterOperation) => void;
  onClose: () => void;
  variables: VariableDefinition[];
}

const isBinaryOperation = (
  operator: FilterOperatorExtended | FilterOperator,
): operator is BinaryOperator => filterBinaryOperators.includes(operator as BinaryOperator);

const isEmptyOperationValueAllowed = (operator: FilterOperatorExtended | FilterOperator) =>
  !isBinaryOperation(operator) || operator === '==' || operator === '!=';

const getOperatorsForField = (field: Field | null): readonly FilterOperatorExtended[] => {
  switch (field?.type) {
    case 'Object':
      return ['==', '!=', 'isnull', 'isnotnull', 'icontains', 'noticontains'];
    case 'Boolean':
      return filterUnaryOperators;
    case 'Array':
      return ['arrcontains', ...filterUnaryOperators];
    default:
      return filterOperators.filter(
        (operator) => operator !== 'arrcontains' && operator !== 'istrue' && operator !== 'isfalse',
      );
  }
};

type FieldInputProps = {
  fields: FilterProps['fields'] | null; // if null, then shows text input instead
  fieldKey: string;
  setFieldKey: (field: string) => void;
};

const FieldInput = (props: FieldInputProps) => {
  const { fields, fieldKey, setFieldKey } = props;
  return fields !== null ? (
    <SearchInput
      options={fields.map(fieldToOption)}
      value={fieldKey}
      onChange={setFieldKey}
      autoFocus
    />
  ) : (
    <Input
      required
      type="text"
      value={fieldKey}
      placeholder="Field name"
      onChange={(e) => setFieldKey(e.target.value)}
    />
  );
};

type OperatorInputProps = {
  field: Field | null;
  operator: FilterOperatorExtended;
  setOperator: (operator: string) => void;
};

const OperatorInput = (props: OperatorInputProps) => {
  const { field, operator, setOperator } = props;
  const options = getOperatorsForField(field).map((operator) => ({
    value: operator,
    label: operatorLabels[operator],
  }));
  return <Select options={options} value={operator} onChange={setOperator} />;
};

type ValueInputProps = {
  fields: Fields;
  field: Field;
  fieldsForExpression: (Field | FieldGroup)[];
  enumOptions?: EnumOptions;
  operator: FilterOperatorExtended;
  value: FilterValue;
  setValue: (value: FilterValue) => void;
  model?: Model;
};

const ValueInput = (props: ValueInputProps) => {
  const { field, enumOptions, operator, value, setValue } = props;
  const { getVariables } = useExplorationContext();

  if (!isBinaryOperation(operator)) {
    return null;
  }

  if (isValueExpression(value)) {
    return (
      <div className={form.formRowInner}>
        <PenguinoInput
          fields={props.fieldsForExpression}
          model={props.model}
          variables={getVariables()}
          placeholder="Enter custom formula..."
          value={value.expression}
          onChange={(expression) => setValue({ expression, version: PenguinoVersion })}
          requiredType={field.type}
        />
      </div>
    );
  }

  if (field.type === 'Date' && isString(value)) {
    return (
      <DateTimeInput
        value={isEmpty(value) ? getDefaultFormattedLocalDate() : value}
        onChange={setValue}
      />
    );
  }

  if (field.type === 'Enum') {
    return <Select options={enumOptions ?? []} value={value?.toString()} onChange={setValue} />;
  }

  return (
    <Input
      required={!isEmptyOperationValueAllowed(operator)}
      type={isNumberType(field.type) ? 'number' : 'text'}
      step="any"
      value={value?.toString()}
      placeholder="Value"
      onChange={(e) => setValue(parseFilterValue(e.target.value, field.type))}
    />
  );
};

/**
 * Since the UI supports some operators that the backend doesn't, we need to do
 * a bit of back-and-forth conversion between the two filter shapes.
 * See FilterOperatorExtended vs FilterOperator under types.
 */
function convertFilterToOperationParameters(
  field: Field,
  operator: FilterOperatorExtended,
  value: FilterValue,
): FilterCondition {
  if (operator === 'istrue' || operator === 'isfalse') {
    value = operator === 'istrue' ? true : false;
    operator = '==';
  }
  return isBinaryOperation(operator)
    ? { key: field.key, operator, value }
    : { key: field.key, operator };
}

const convertOperationParametersToFilter = (
  parameters: FilterCondition,
  fields: Fields,
): {
  key: string;
  operator: FilterOperatorExtended;
  value: FilterValue;
} => {
  const field = fields.find((f) => f.key === parameters.key);
  if (field === undefined) {
    throw new Error(`Unknown field ${parameters.key}`);
  }

  let operator = parameters.operator as FilterOperatorExtended;
  let value = parameters.value ?? '';

  if (field.type === 'Boolean' && operator === '==' && isBoolean(value)) {
    operator = parameters.value === true ? 'istrue' : 'isfalse';
    value = '';
  }

  return { key: field.key, operator, value: value };
};

export const parseValueForFilter = (
  value: unknown,
  type: string,
  timezone: string,
): FilterValue => {
  const parsedValue = common.filterValue.parse(value);
  if (type === 'Date' && isString(parsedValue)) {
    // Convert UTC ISO format to account timezone
    const d = parseISO(parsedValue);
    return isValid(d) ? format(toZonedTime(d, timezone), LocalDateTimeFormat) : '';
  }
  return parsedValue;
};

const ensureValidValue = (field?: Field, value?: FilterValue) => {
  switch (field?.type) {
    case 'Date':
      return !isValueExpression(value) && !(isString(value) && containsISODateString(value))
        ? getDefaultFormattedLocalDate()
        : value;
    case 'Boolean':
      return value ?? true;
    default:
      return value ?? '';
  }
};

export const getEmptyFilterParameters = (field?: Field, value?: FilterValue): FilterCondition => ({
  key: field?.key ?? '',
  operator: '==',
  value: ensureValidValue(field, value),
});

export const getRangeFilterParameters = (
  range: NumberRange,
  field?: Field,
): CompositeFilterCondition => {
  const { start, end } = range;
  return {
    operator: 'and',
    operands: [
      {
        key: field?.key ?? '',
        operator: '>=',
        value: start,
      },
      {
        key: field?.key ?? '',
        operator: '<',
        value: end,
      },
    ],
  };
};

export const getDateFilterParameters = (
  field?: Field,
  value?: FilterValue,
): CompositeFilterCondition => {
  const date = new Date(String(value));
  const precision = field?.precision || 'month';
  const { startDate, endDate } = dateRangeFromPrecision(date, precision);

  return {
    operator: 'and',
    operands: [
      {
        key: field?.key ?? '',
        operator: '>=',
        value: precision === 'hour' ? startDate.toISOString() : formatLocalDate(startDate),
      },
      {
        key: field?.key ?? '',
        operator: '<=',
        value: precision === 'hour' ? endDate.toISOString() : formatLocalDate(endDate),
      },
    ],
  };
};

type FilterFormInnerProps = {
  fields: Fields;
  fieldsForExpression: (Field | FieldGroup)[];
  condition: FilterCondition;
  setCondition: (condition: FilterCondition) => void;
  variables: VariableDefinition[];
  model?: Model;
  onRemove?: () => void;
};

export const FilterFormInner = ({
  fields,
  model,
  fieldsForExpression,
  condition,
  setCondition,
  onRemove,
  variables,
}: FilterFormInnerProps) => {
  const { getEnumOptions } = useMetadataContext();
  const [enumOptions, setEnumOptions] = useState<EnumOptions | undefined>();
  const { exploration } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const { key, operator, value } = convertOperationParametersToFilter(condition, fields);
  const valueIsExpression = isValueExpression(value);

  const valueIsVariableReference =
    valueIsExpression &&
    variables.some((variable) => getVariableExpression(variable.key) === value.expression);

  const field = fields.find((f) => f.key === key);

  // Asynchronously fetch enum options
  useEffect(() => {
    const model = field?.model;
    if (field?.type !== 'Enum' || model === undefined) {
      return;
    }

    const fetchEnumOptions = async () => {
      const options = await getEnumOptions(model.modelId, model.propertyKey);
      setEnumOptions(options);
      if (
        field?.type !== 'Enum' ||
        valueIsVariableReference ||
        valueIsExpression ||
        options === undefined ||
        options.length === 0
      ) {
        return;
      }

      const nextValue = (options.find((option) => option.value === value) ?? first(options))?.value;
      if (value !== nextValue) {
        setCondition({ ...condition, value: nextValue });
      }
    };

    fetchEnumOptions();
  }, [
    getEnumOptions,
    field,
    value,
    setCondition,
    condition,
    valueIsVariableReference,
    valueIsExpression,
  ]);

  if (field === undefined) {
    throw new Error(`FilterFormInner passed an unknown field ${key}.`);
  }

  const variableMenuItems: DropdownMenuItem[] =
    variables.length > 0
      ? [
          {
            type: 'divider',
          },
          ...variables.map((variable) => ({
            label: variable.key,
            icon: <Icon name="VariableInstance" size={16} />,
            color: getVariableColor(variable.key, variables, [
              variableColor1,
              variableColor2,
              variableColor3,
              variableColor4,
              variableColor5,
              variableColor6,
            ]),
            onClick: () => {
              trackEvent('Variable Assigned', {
                exploration,
                variable: value,
              });

              return handleChange(field, operator, {
                expression: getVariableExpression(variable.key),
                version: PenguinoVersion,
              });
            },
          })),
        ]
      : [];

  const menuItems: DropdownMenuItem[] = [
    {
      icon: <Icon name="CustomFormula" size={16} />,
      label: 'Custom Formula',
      className: classNames({ [styles.customFormulaActive]: valueIsExpression }),
      onClick: () =>
        handleChange(
          field,
          operator,
          valueIsExpression ? '' : { expression: '', version: PenguinoVersion },
        ),
      disabled: !isBinaryOperation(operator),
    },
    ...variableMenuItems,
  ];

  if (onRemove !== undefined) {
    menuItems.push({
      label: 'Delete',
      onClick: onRemove,
      icon: <Icon name="Trash2" size={16} />,
    });
  }

  const handleChange = (field: Field, operator: FilterOperatorExtended, value: FilterValue) => {
    setCondition(
      convertFilterToOperationParameters(field, operator, ensureValidValue(field, value)),
    );
  };

  if (field.type === 'Boolean') {
    return (
      <BooleanFilterForm
        operator={operator}
        value={value}
        field={field}
        fields={fields}
        variables={variables}
        onChange={handleChange}
        onRemove={onRemove}
      />
    );
  }

  return (
    <>
      <div className={form.formRow}>
        <FieldInput
          fields={fields}
          fieldKey={key}
          setFieldKey={(v) => {
            const newField = fields.find((f) => f.key === v) as Field;
            let newOperator = operator;
            let newValue = value;
            const newOperators = getOperatorsForField(newField);
            if (!newOperators.includes(operator)) {
              newValue = '';
              newOperator = first(newOperators) ?? '==';
            }
            handleChange(newField, newOperator, newValue);
          }}
        />
        <div className={form.narrow}>
          <OperatorInput
            field={field}
            operator={operator}
            setOperator={(v) => {
              handleChange(field, v as FilterOperatorExtended, value);
            }}
          />
        </div>
      </div>
      <div className={classNames(form.formRow, form.alignTop)}>
        {valueIsVariableReference ? (
          <VariableReferenceBadge
            value={value.expression}
            variables={variables}
            onRemove={() => {
              trackEvent('Variable Unassigned', { exploration, variable: value });

              return handleChange(field, operator, field.type === 'Boolean' ? true : '');
            }}
          />
        ) : (
          <ValueInput
            fields={fields}
            field={field}
            model={model}
            fieldsForExpression={fieldsForExpression}
            enumOptions={enumOptions}
            operator={operator}
            value={value}
            setValue={(value) => handleChange(field, operator, value)}
          />
        )}

        {
          <Dropdown
            align="right"
            trigger={(isOpen, setIsOpen) => (
              <IconButton
                icon="MoreHorizontal"
                size="small"
                title="More..."
                type="gray"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
            items={menuItems}
          />
        }
      </div>
    </>
  );
};

const BooleanFilterForm = ({
  operator,
  value,
  field,
  fields,
  onChange,
  onRemove,
  variables,
}: {
  operator: FilterOperatorExtended;
  value: FilterValue;
  field: Field;
  fields: Fields;
  onChange: (field: Field, operator: FilterOperatorExtended, value: FilterValue) => void;
  onRemove?: () => void;
  variables: VariableDefinition[];
}) => {
  const trackEvent = useTrackEvent();
  const { exploration } = useExplorationContext();
  const menuItems: DropdownMenuItem[] = [
    ...(onRemove !== undefined
      ? [
          {
            label: 'Delete',
            onClick: onRemove,
            icon: <Icon name="Trash2" size={16} />,
          },
        ]
      : []),
    ...variables.map((variable) => ({
      label: variable.key,
      icon: <Icon name="VariableInstance" size={16} />,
      color: getVariableColor(variable.key, variables, [
        variableColor1,
        variableColor2,
        variableColor3,
        variableColor4,
        variableColor5,
        variableColor6,
      ]),
      onClick: () => {
        trackEvent('Variable Assigned', {
          explorationId: exploration.explorationId,
          variable: value,
        });

        return onChange(field, '==', {
          expression: getVariableExpression(variable.key),
          version: PenguinoVersion,
        });
      },
    })),
  ];

  return (
    <div className={form.formRow}>
      <FieldInput
        fields={fields}
        fieldKey={field.key}
        setFieldKey={(v) => {
          const newField = fields.find((f) => f.key === v) as Field;
          let newOperator = operator;
          let newValue = value;
          const newOperators = getOperatorsForField(newField);
          if (!newOperators.includes(operator)) {
            newValue = '';
            newOperator = first(newOperators) ?? '==';
          }
          onChange(newField, newOperator, newValue);
        }}
      />
      {isValueExpression(value) ? (
        <VariableReferenceBadge
          value={value.expression}
          variables={variables}
          onRemove={() => {
            trackEvent('Variable Unassigned', {
              explorationId: exploration.explorationId,
              variable: value,
            });
            return onChange(field, operator, true);
          }}
        />
      ) : (
        <div className={form.narrow}>
          <OperatorInput
            field={field}
            operator={operator}
            setOperator={(operator) => onChange(field, operator as FilterOperatorExtended, value)}
          />
        </div>
      )}

      {menuItems.length > 0 && (
        <Dropdown
          align="right"
          trigger={(isOpen, setIsOpen) => (
            <IconButton
              icon="MoreHorizontal"
              size="small"
              title="More..."
              type="gray"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          items={menuItems}
        />
      )}
    </div>
  );
};

type CompositeFilterFormInnerProps = {
  fields: FilterProps['fields'];
  condition: CompositeFilterCondition;
  depth: number;
  canAddOr?: boolean;
  canAddAnd?: boolean;
  canRemove?: boolean;
  setCondition: (condition: CompositeFilterCondition) => void;
  onRemove?: () => void;
  variables: VariableDefinition[];
};

const CompositeFilterFormInner = (props: CompositeFilterFormInnerProps) => {
  const {
    fields,
    condition,
    setCondition,
    onRemove,
    depth,
    canAddAnd,
    canAddOr,
    canRemove,
    variables,
  } = props;

  const handleAddOperand = (operator: 'and' | 'or') => {
    if (operator === condition.operator) {
      // Extend current condition operands by one
      setCondition({
        ...condition,
        operands: [...condition.operands, getEmptyFilterParameters(first(fields))],
      });
    } else {
      // Nest current condition in new one
      setCondition({
        operator,
        operands: [condition, getEmptyFilterParameters(first(fields))],
      });
    }
  };

  return (
    <div className={classNames(form.formHorizontal, styles.compositeFilter)} data-depth={depth}>
      {isSingleFilterCondition(condition) ? (
        <FilterFormInner
          fields={fields}
          fieldsForExpression={fields}
          condition={condition}
          setCondition={setCondition}
          onRemove={(canRemove ?? false) ? onRemove : undefined}
          variables={variables}
        />
      ) : (
        condition.operands.map((operand, index) => (
          <Fragment key={index}>
            <CompositeFilterFormInner
              fields={fields}
              condition={operand}
              depth={depth + 1}
              canAddAnd={depth < 1}
              canAddOr={false}
              canRemove={canRemove}
              setCondition={(newCondition) => {
                const newOperands = [
                  ...condition.operands.slice(0, index),
                  newCondition,
                  ...condition.operands.slice(index + 1),
                ];
                setCondition({ ...condition, operands: newOperands });
              }}
              onRemove={() => {
                if (condition.operands.length === 1 && onRemove) {
                  return onRemove();
                }
                setCondition({
                  ...condition,
                  operands: [
                    ...condition.operands.slice(0, index),
                    ...condition.operands.slice(index + 1),
                  ],
                });
              }}
              variables={variables}
            />
            {index < condition.operands.length - 1 && (
              <div className={styles.operandSeparator}>
                <span>{condition.operator.toUpperCase()}</span>
              </div>
            )}
          </Fragment>
        ))
      )}
      <div className={styles.addOperand}>
        {(canAddAnd ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('and');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> And
          </InlineButton>
        )}
        {(canAddOr ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('or');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> Or
          </InlineButton>
        )}
      </div>
    </div>
  );
};

/**
 * Recursively unwrap filters with a single operand so no unnecessary nesting is created.
 */
const unwrapEmptyFilters = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and' || condition.operator === 'or') {
    const operands = condition.operands.map(unwrapEmptyFilters);
    if (operands.length === 1) {
      return operands[0];
    }
    return {
      ...condition,
      operands,
    };
  }
  return condition;
};

const isValidCondition = (condition: CompositeFilterCondition, fields: Field[] = []): boolean => {
  if (isSingleFilterCondition(condition)) {
    const field = fields.find(({ key }) => key === condition.key);

    if (field === undefined || condition.value === null) {
      return false;
    }

    if (isValueExpression(condition.value)) {
      return condition.value.expression !== undefined && condition.value.expression !== '';
    }

    if (isNumberType(field.type) && isBinaryOperation(condition.operator)) {
      return !isNaN(Number(condition.value));
    }

    if (field.type === 'Date' && condition.value !== undefined) {
      return isValid(new Date(condition.value as string));
    }

    return true;
  }
  return condition.operands.every((c) => isValidCondition(c, fields));
};

/**
 * If there is an and-filter on top level, nest it in an empty or-filter.
 * This is purely to display all and-filters as if they are 2nd level filters even if
 * there is no parent filter. This is removed upon submit.
 */
const ensureTopLevelOrFilter = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and') {
    return {
      operator: 'or',
      operands: [condition],
    };
  }
  return condition;
};

export const CompositeFilterForm = (props: FilterFormProps) => {
  const defaultCondition = useMemo(
    () => getEmptyFilterParameters(first(props.fields)),
    [props.fields],
  );
  const initialCondition = props.operation?.parameters ?? defaultCondition;
  const [condition, setCondition] = useState<CompositeFilterCondition>(initialCondition);
  const { setDirty } = useDirtyContext();

  const fields = useEnsureFieldsExist(props.fields, getCompositeConditionKeys(condition));

  const handleChange = (condition: CompositeFilterCondition) => {
    setCondition(condition);
    const isDirty = !isEqual(initialCondition, condition);
    setDirty(isDirty);
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({
      operation: 'filter',
      parameters: condition,
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  useDeepCompareEffect(() => {
    if (props.operation?.parameters !== undefined) {
      setCondition(props.operation?.parameters);
    }
  }, [props.operation?.parameters]);

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <CompositeFilterFormInner
        fields={fields}
        condition={ensureTopLevelOrFilter(condition)}
        setCondition={(updatedCondition) => handleChange(unwrapEmptyFilters(updatedCondition))}
        depth={0}
        canRemove={!isSingleFilterCondition(condition)}
        canAddAnd={isSingleFilterCondition(condition)}
        canAddOr
        variables={props.variables}
      />
      <div className={form.formControls}>
        <Button size="small" type="submit" disabled={!isValidCondition(condition, props.fields)}>
          {props.operation ? 'Save' : 'Filter'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};
