import classNames from 'classnames';

import styles from './panel.module.scss';

interface PanelProps {
  className?: string;
  title?: string;
  children: React.ReactNode;
}

export const Panel = ({ className, title, children }: PanelProps) => (
  <div className={classNames(styles.panel, className)}>
    {title !== undefined && <div className={styles.title}>{title}</div>}
    {children}
  </div>
);
