import { PyodideInterface } from 'pyodide';
import type { PyProxy } from 'pyodide/ffi';

import { PythonBlockResult } from './types';
import { pythonTypeConverter } from './python';

export function isPyProxy(obj: any) {
  return (
    obj !== undefined &&
    typeof obj === 'object' &&
    'toJs' in obj &&
    'destroy' in obj &&
    typeof obj.toJs === 'function'
  );
}

export function convertPythonResult(pyodide: PyodideInterface, result: any): PythonBlockResult {
  if (result === undefined) {
    return undefined;
  }

  const pyProxies: PyProxy[] = [];
  try {
    const pyConvertFunction = pyodide.runPython(pythonTypeConverter);
    pyProxies.push(pyConvertFunction);

    const pyData = pyConvertFunction!(result);
    pyProxies.push(pyData);

    if (pyData.raw_py_class !== undefined) {
      console.log(`python result is of type "${pyData.raw_py_class}"`);
    }

    if (pyData?.type === 'table') {
      const { type, fields, rows: rows_str } = pyData;
      return {
        type,
        fields,
        rows: JSON.parse(rows_str),
      };
    }
    return pyData;
  } finally {
    for (const pyProxy of pyProxies) {
      if (isPyProxy(pyProxy)) {
        pyProxy.destroy();
      }
    }
  }
}
