import { IconProps } from './types';

export const DragHandleIcon = ({ size = 8, color = 'currentColor', ...rest }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size / 2} height={size} {...rest}>
    <path
      fill={color}
      d="M0 1A.5.5 0 0 1 .5.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 0 1ZM0 4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 0 4ZM0 7a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 0 7Z"
    />
  </svg>
);
