import { first, get, isNumber, isObject } from 'lodash';
import classNames from 'classnames';

import { formatPropertyValue } from '@/explore/utils/format';
import { Icon } from '@/components/icon';
import { ListRecord } from '@/explore/grouping';
import { Field } from '@/explore/types';
import { Skeleton } from '@/components/skeleton-loader';

import styles from './big-number.module.scss';

interface BigNumberProps {
  field: Field;
  comparisonField?: Field;
  data: ListRecord[];
  comparisonType?: 'absolute' | 'percentage';
  direction?: 'higher' | 'lower';
  showLabel?: boolean;
  compact?: boolean;
  loading?: boolean;
}

const getDelta = ({ field, comparisonField, data, comparisonType }: BigNumberProps) => {
  if (
    comparisonField === undefined ||
    data.length === 0 ||
    (field.key === comparisonField.key && data.length < 2)
  ) {
    return undefined;
  }

  const displayPercentage = comparisonType === 'percentage';
  const currentValue = get(first(data), field.key, 0);
  const comparisonValue =
    field.key === comparisonField.key
      ? get(data.at(1), comparisonField.key, 0)
      : get(data.at(0), comparisonField.key, 0);

  if (
    !isNumber(currentValue) ||
    !isNumber(comparisonValue) ||
    (displayPercentage && comparisonValue === 0)
  ) {
    return undefined;
  }

  return displayPercentage
    ? ((currentValue - comparisonValue) / comparisonValue) * 100
    : currentValue - comparisonValue;
};

interface GetDeltaLabelProps extends BigNumberProps {
  deltaValue?: number;
}

const getDeltaLabel = ({
  field,
  comparisonField,
  data,
  showLabel,
  deltaValue,
}: GetDeltaLabelProps) => {
  if (comparisonField === undefined) {
    return undefined;
  }
  if ((data.length < 2 && field.key === comparisonField.key) || deltaValue === undefined) {
    return 'No comparison available';
  }
  if (showLabel === true) {
    return comparisonField?.name;
  }

  return undefined;
};

type BigNumberValueProps = {
  data: ListRecord[];
  field: Field;
  loading: boolean;
};

const BigNumberValue = ({ data, field, loading }: BigNumberValueProps) => {
  const value = get(first(data), field.key, 'N/A');
  if (loading) {
    return (
      <div className={styles.bigNumberLoader}>
        <Skeleton delay={50} light />
      </div>
    );
  }

  return isObject(value) ? (
    <pre className={styles.bigNumberValue}>{JSON.stringify(value, null, 2)}</pre>
  ) : (
    <div className={styles.bigNumberValue}>
      {formatPropertyValue(value, { field, style: 'compactLong' })}
    </div>
  );
};

type BigNumberDeltaProps = {
  field: Field;
  comparisonField?: Field;
  data: ListRecord[];
  comparisonType?: 'absolute' | 'percentage';
  direction?: 'higher' | 'lower';
  showLabel?: boolean;
  loading?: boolean;
};

const BigNumberDelta = ({
  field,
  comparisonField,
  data,
  comparisonType,
  showLabel,
  direction,
  loading = false,
}: BigNumberDeltaProps) => {
  const deltaValue = getDelta({ field, comparisonField, data, comparisonType });
  const deltaLabel = getDeltaLabel({ field, comparisonField, data, showLabel, deltaValue });

  const reversedDeltaColors = direction === 'lower';
  const normalizedDelta =
    deltaValue !== undefined
      ? formatPropertyValue(deltaValue, {
          type: 'Number',
          style: 'compactLong',
          format: comparisonType === 'percentage' ? 'percentage' : undefined,
        })
      : null;
  const formattedDelta = `${deltaValue !== undefined && deltaValue > 0 ? '+' : ''}${normalizedDelta}`;

  const isDeltaPositive =
    deltaValue !== undefined && (reversedDeltaColors ? deltaValue < 0 : deltaValue > 0);

  if (deltaValue === undefined && deltaLabel === undefined) {
    return;
  }

  if (loading) {
    return (
      <div className={styles.bigNumberDeltaLoader}>
        <Skeleton delay={50} light />
      </div>
    );
  }

  return (
    <div className={classNames(styles.delta, styles.bigNumberLabel)}>
      {deltaValue !== undefined && (
        <div
          className={classNames(styles.deltaValue, {
            [styles.positive]: deltaValue !== 0 && isDeltaPositive,
            [styles.negative]: deltaValue !== 0 && !isDeltaPositive,
          })}>
          {deltaValue !== 0 && (
            <Icon
              name="ArrowUp"
              size={14}
              className={classNames(styles.deltaArrow, {
                [styles.arrowFlipped]: deltaValue < 0,
              })}
            />
          )}
          {formattedDelta}
        </div>
      )}
      {deltaLabel !== undefined && <div>{deltaLabel}</div>}
    </div>
  );
};

export const BigNumber = ({
  field,
  comparisonField,
  comparisonType,
  direction,
  showLabel,
  data,
  compact,
  loading = false,
}: BigNumberProps) => {
  return (
    <div
      className={classNames(styles.bigNumber, {
        [styles.compact]: compact === true,
      })}>
      <BigNumberValue field={field} {...{ data, loading }} />
      <div className={styles.bigNumberLabel}>{field.name}</div>
      <BigNumberDelta
        {...{ data, field, comparisonField, comparisonType, direction, showLabel, loading }}
      />
    </div>
  );
};
