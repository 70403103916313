import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { useFocus } from '@/lib/hooks/use-focus';
import { useEventListener } from '@/lib/hooks/use-event-listener';
import { NavigableListItemOutput } from '@/lib/hooks/use-navigable-list';

import { Exploration, RecordsCell } from '../types';
import { Icon } from '../../components/icon';
import { PipelinePreview } from '../exploration/pipeline-preview';
import { buildExplorationHashUrl } from '../utils';
import { CellListItemData } from '.';
import { buildExplorationFromCells, extractCellFromExploration } from '../exploration/utils';

import { useMetadataContext } from '../metadata-context';

import * as styles from './exploration-search.module.scss';

interface CellListProps {
  exploration: Exploration;
  listItems: NavigableListItemOutput<CellListItemData>[];
}

export const CellList = (props: CellListProps) => {
  const buildAccountUrl = useBuildAccountUrl();

  return (
    <ol className={styles.explorationList}>
      {props.listItems.map((item, i) => {
        const { getIsFocused, setIsFocused } = item;
        const { cell } = item.getData<CellListItemData>();
        const { cell: extractedCell, dependencies } = extractCellFromExploration(
          cell.id,
          props.exploration,
        );
        const exploration = buildExplorationFromCells([extractedCell, ...dependencies]);

        return (
          <CellItem
            key={i}
            cell={cell}
            exploration={props.exploration}
            url={buildAccountUrl(buildExplorationHashUrl(exploration))}
            isFocused={getIsFocused()}
            onFocus={() => setIsFocused(true)}
            onClick={item.onClick}
          />
        );
      })}
    </ol>
  );
};

interface CellItemProps {
  cell: RecordsCell;
  exploration: Exploration;
  url: string;
  onClick?: React.MouseEventHandler;
  isFocused?: boolean;
  onFocus?: () => void;
}

const CellItem = (props: CellItemProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { models } = useMetadataContext();

  useFocus(props.isFocused ?? false, ref);
  useEventListener('focus', props.onFocus, ref);

  return (
    <Link to={props.url} className={styles.listItem} onClick={props.onClick} ref={ref}>
      <Icon name="Block" size={32} className={styles.icon} />
      <div className={styles.name}>{props.cell.title}</div>
      <div className={styles.pipeline}>
        <PipelinePreview
          models={models}
          title={props.cell.title ?? 'Untitled'}
          exploration={props.exploration}
          pipeline={props.cell.pipeline}
        />
      </div>
    </Link>
  );
};
