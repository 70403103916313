import classNames from 'classnames';

import { Select } from '../../components/form/select';
import { Aggregation, AggregationExtendedType, Fields, Metric } from '../types';
import { SearchInput } from '../../components/form/search-input';
import { isKeyedAggregation, isSortedAggregation } from '../pipeline/operation';
import { PipelineEditorError } from './errors';
import { metricAggregationOptions, metricById } from '../utils/metrics';
import {
  aggregationTypeToAggregationExtendedType,
  buildMetricAggregation,
  changeAggregationType,
  getAggregationOptionsForFields,
  getFieldOptionsForAggregationType,
} from '../utils/aggregation';

import { SortingEditor } from './sorting-editor';

import form from '../../components/form/form.module.scss';

interface AggregationFormProps {
  aggregation: Aggregation;
  fields: Fields;
  metrics: Metric[];
  setAggregation: (aggregation: Aggregation) => void;
  relationName?: string;
  excludeAggregationTypes?: AggregationExtendedType[];
  autoFocus?: boolean;
}

export const AggregationForm = (props: AggregationFormProps) => {
  const {
    aggregation,
    fields,
    setAggregation,
    metrics,
    excludeAggregationTypes = [],
    autoFocus = false,
  } = props;
  const isKeyed = isKeyedAggregation(aggregation);
  const field = isKeyed ? fields.find((f) => f.key === aggregation.key) : undefined;
  if (field === undefined && isKeyed) {
    throw new PipelineEditorError(
      `Keyed aggregation references unknown field with key '${aggregation.key}'`,
    );
  }

  const aggregationExtendedType = aggregationTypeToAggregationExtendedType(aggregation.type, field);
  if (aggregationExtendedType === undefined) {
    throw new PipelineEditorError(`Unknown aggregation type '${aggregation.type}'`);
  }

  const options = getAggregationOptionsForFields(fields)
    .filter(({ value }) => !excludeAggregationTypes.includes(value))
    .map(({ label, value }) => ({ label, value: value.toString() }))
    .concat(metricAggregationOptions(metrics));

  const value = aggregation.type === 'metric' ? aggregation.metricId : aggregationExtendedType;

  const handleTypeChange = (v: string) => {
    const metric = metricById(metrics, v);
    if (metric !== undefined) {
      setAggregation(buildMetricAggregation(metric));
      return;
    }

    const updatedAggregation = changeAggregationType(
      aggregation,
      v as AggregationExtendedType,
      fields,
      metrics,
    );
    setAggregation(updatedAggregation);
  };

  return (
    <div className={form.formHorizontal}>
      <div className={classNames(form.formRow)}>
        <label className={form.formLabel}>Show</label>
        <Select options={options} value={value} onChange={handleTypeChange} autoFocus={autoFocus} />
      </div>
      {isKeyedAggregation(aggregation) && (
        <div className={classNames(form.formRow)}>
          <label className={form.formLabel}>of</label>
          <SearchInput
            options={getFieldOptionsForAggregationType(aggregationExtendedType, fields)}
            value={aggregation.key}
            onChange={(key) =>
              setAggregation({
                ...aggregation,
                key,
              })
            }
          />
        </div>
      )}
      {isSortedAggregation(aggregation) && (
        <div className={classNames(form.formRow)}>
          <label className={form.formLabel}>sorted by</label>
          <SortingEditor
            fields={fields}
            sorting={aggregation.sort}
            setSorting={(sort) => {
              setAggregation({
                ...aggregation,
                sort,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
