import classNames from 'classnames';

import { Icon } from '@/components/icon';

import { getNumberOfChildPipelines, getPipelineColor } from '../../pipeline/utils';
import { Exploration, Pipeline } from '../../types';

import style from './master-badge.module.scss';

export const MasterBadge = (props: { exploration: Exploration; pipeline: Pipeline }) => {
  const instanceCount = getNumberOfChildPipelines(props.exploration, props.pipeline);
  if (instanceCount === 0) {
    return null;
  }

  const pipelineColor = getPipelineColor(props.exploration, props.pipeline);

  return (
    <div
      className={classNames(style.masterBadge, {
        [style.masterBadgeColor1]: pipelineColor === 1,
        [style.masterBadgeColor2]: pipelineColor === 2,
        [style.masterBadgeColor3]: pipelineColor === 3,
        [style.masterBadgeColor4]: pipelineColor === 4,
        [style.masterBadgeColor5]: pipelineColor === 5,
        [style.masterBadgeColor6]: pipelineColor === 6,
      })}
      title={`Number of child pipelines: ${instanceCount}`}>
      <Icon name="Parent" size={16} />
      <div className={style.masterBadgeLabel}>({instanceCount})</div>{' '}
    </div>
  );
};
