import { useState } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '@/components/icon';
import { IconButton } from '@/components/button';

import styles from './sidebar-section.module.scss';

interface SidebarSectionProps {
  title?: React.ReactNode;
  titleActions?: React.ReactNode;
  iconName?: IconProps['name'];
  collapsible?: boolean;
  scrollable?: boolean;
  collapsed?: boolean;
  children?: React.ReactNode;
}

export const SidebarSection = (props: SidebarSectionProps) => {
  const {
    title,
    titleActions,
    iconName,
    collapsible = false,
    scrollable = false,
    children,
  } = props;
  const [collapsed, setCollapsed] = useState(props.collapsed ?? false);

  return (
    <section
      className={classNames(styles.sidebarSection, {
        [styles.collapsed]: collapsed,
        [styles.collapsible]: collapsible,
        [styles.scrollable]: scrollable,
      })}>
      {title !== undefined && (
        <div className={styles.header} onClick={() => collapsible && setCollapsed(!collapsed)}>
          {iconName ? <Icon name={iconName} className={styles.icon} size={18} /> : null}
          <h2 className={styles.title}>{title}</h2>
          {titleActions !== undefined && <div className={styles.titleActions}>{titleActions}</div>}
          {collapsible && (
            <IconButton
              className={styles.collapseIcon}
              icon="ChevronUp"
              size="small"
              title={collapsed ? 'Expand' : 'Collapse'}
            />
          )}
        </div>
      )}
      {children !== undefined && (
        <div className={styles.content} inert={collapsed ? '' : undefined}>
          <div className={classNames({ [styles.contentInner]: collapsed })}>{children}</div>
        </div>
      )}
    </section>
  );
};
