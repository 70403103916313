import { List, ListItem } from '../common';
import { IconButton } from '../../components/button';
import { Icon } from '../../components/icon';

import styles from './list.module.scss';

export interface NavigationItem {
  kind: string;
  label: string;
  explorationId: string;
}

interface NavigationItemsListProps {
  items: NavigationItem[];
  canEdit: boolean;
  onRemove: (item: NavigationItem) => void;
  onMoveUp: (item: NavigationItem) => void;
  onMoveDown: (item: NavigationItem) => void;
}

export const NavigationItemsList = (props: NavigationItemsListProps) => {
  const { items, canEdit, onRemove, onMoveUp, onMoveDown } = props;
  return (
    <List numColumns={3} columnWidths={{ [0]: 'min-content', [-1]: 'min-content' }}>
      {items.map((item, i) => {
        return (
          <ListItem key={i}>
            <div className={styles.icon}>
              <Icon name="Exploration" />
            </div>
            <div className={styles.name}>{item.label}</div>
            <div>
              {canEdit && (
                <>
                  <IconButton
                    icon="ChevronUp"
                    title="Move backward"
                    iconSize="regular"
                    onClick={() => onMoveUp(item)}
                    disabled={i === 0}
                  />
                  <IconButton
                    icon="ChevronDown"
                    title="Move forward"
                    iconSize="regular"
                    onClick={() => onMoveDown(item)}
                    disabled={i === items.length - 1}
                  />
                  <IconButton
                    icon="Trash2"
                    title="Remove"
                    iconSize="regular"
                    onClick={() => onRemove(item)}
                  />
                </>
              )}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
