import { curveMonotoneX } from '@visx/curve';
import { AreaStack as VisxAreaStack, LinePath } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { Fragment } from 'react';

import { TimeSeriesData } from '../grouped-chart/types';
import { generateStackedChartDataByKeys } from './utils';
import { increaseBrightness } from '../utils';

type LineStackProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
};

export const LineStack = (props: LineStackProps) => {
  const { data, keys, dateScale, valueScale } = props;

  const stackedData = generateStackedChartDataByKeys(data, keys);

  return (
    <VisxAreaStack
      keys={keys}
      data={stackedData}
      order="reverse"
      x={(d) => dateScale(d.data.date)}
      y0={(d) => valueScale(d[0])}
      y1={(d) => valueScale(d[1]) ?? valueScale.range()[0]}
      curve={curveMonotoneX}>
      {({ stacks }) =>
        stacks.map((stack) => {
          const color = data.series.find(({ key }) => key === stack.key)?.color ?? '';
          return (
            <Fragment key={`stack-${stack.key}`}>
              <LinePath
                data={stack.map((item) => ({
                  date: item.data.date,
                  value: item.at(1) ?? 0,
                }))}
                x={({ date }) => dateScale(date) ?? 0}
                y={({ value }) => valueScale(value) ?? 0}
                stroke={increaseBrightness(color, 20)}
                strokeOpacity={1}
                curve={curveMonotoneX}
              />
            </Fragment>
          );
        })
      }
    </VisxAreaStack>
  );
};
