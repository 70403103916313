import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { first } from 'lodash';

import { SearchInput } from '../../components/form/search-input';
import { Icon } from '../../components/icon';
import { Exploration, FunnelStep, Metric, Model } from '../types';
import { SortableItem } from '../components/sortable-item';
import { fieldToOption } from '../edit-pipeline/utils';
import { useExplorationContext } from '../exploration/exploration-context';
import { useEnsureFieldsExist } from '../edit-pipeline/hooks/use-ensure-fields-exist';
import { getStepName, getStepPipelineState, isEmptyStep } from './utils';
import { PipelineModel } from '../components/pipeline/pipeline-model';
import { getParentPipelineColor } from '../pipeline/utils';
import { useEnsurePipelinesExist } from '../edit-pipeline/hooks/use-ensure-pipelines-exist';

import formStyles from '../../components/form/form.module.scss';
import styles from './funnel.module.scss';

interface FunnelStepProps {
  step: FunnelStep;
  index: number;
  models: Model[];
  metrics: Metric[];
  exploration: Exploration;
  setStep: (step: FunnelStep) => void;
  onRemoveStep: () => void;
  onClickEvent: () => void;
  onImportEvent: () => void;
  draggedIndex: number | null;
  setDraggedIndex: (index: number | null) => void;
  onReorder: (fromIndex: number, toIndex: number, after: boolean) => void;
}

const Step = (props: FunnelStepProps) => {
  const { step } = props;

  if (isEmptyStep(step)) {
    return (
      <div className={classNames(formStyles.formHorizontal, styles.step)}>
        <div className={formStyles.formRow}>
          <PipelineModel
            icon={'PlusCircle'}
            title={`Funnel step #${props.index + 1}`}
            onClick={props.onClickEvent}
            empty
            accessories={[
              {
                icon: 'Trash2',
                title: 'Remove Event',
                onClick: props.onRemoveStep,
              },
            ]}>
            Select...
          </PipelineModel>
        </div>
      </div>
    );
  }

  return <StepInner {...props} />;
};

const StepInner = (props: FunnelStepProps) => {
  const { step, models, metrics, exploration } = props;
  const { getVariables } = useExplorationContext();

  const paddedExploration = useEnsurePipelinesExist(
    exploration,
    'parentId' in step.pipeline ? [step.pipeline.parentId] : [],
  );

  const parentPipelineColor = getParentPipelineColor(paddedExploration, step.pipeline);
  const pipelineState = getStepPipelineState(step, paddedExploration, {
    models,
    variables: getVariables(),
    metrics,
  });

  const fieldValue = first(step.fields);
  const idFields = useEnsureFieldsExist(
    pipelineState.fields,
    fieldValue !== undefined ? [fieldValue] : [],
  );
  const sortFields = useEnsureFieldsExist(pipelineState.fields, [step.sortKey]);

  const handleCountingFieldChange = (value: string) => {
    props.setStep({
      ...step,
      fields: [value],
    });
  };

  const handleSortKeyChange = (value: string) => {
    props.setStep({
      ...step,
      sortKey: value,
    });
  };

  const stepName = getStepName(step, models, paddedExploration);
  const hasPipeline = 'parentId' in step.pipeline;

  return (
    <SortableItem
      className={classNames(formStyles.formHorizontal, styles.step)}
      key={props.index}
      scope={'funnel-steps'}
      index={props.index}
      draggedIndex={props.draggedIndex}
      setDraggedIndex={props.setDraggedIndex}
      onReorder={props.onReorder}>
      <div className={classNames(formStyles.formRow, styles.stepHeader)}>
        <Icon name={'DragHandle'} size={10} className={styles.stepDragHandle} />
        <PipelineModel
          title={`Funnel step #${props.index + 1}`}
          onClick={props.onClickEvent}
          color={parentPipelineColor}
          accessories={[
            ...(hasPipeline
              ? []
              : [
                  {
                    icon: 'Instance' as const,
                    title: 'Edit Event Pipeline',
                    onClick: props.onImportEvent,
                  },
                ]),
            {
              icon: 'Trash2',
              title: 'Remove Event',
              onClick: props.onRemoveStep,
            },
          ]}>
          {stepName}
        </PipelineModel>
      </div>
      <div className={formStyles.formRow}>
        <label className={formStyles.formLabel}>User ID field</label>
        <SearchInput
          options={idFields.map(fieldToOption)}
          value={first(step.fields) ?? ''}
          onChange={handleCountingFieldChange}
          autoFocus
        />
      </div>
      <div className={formStyles.formRow}>
        <label className={formStyles.formLabel}>Date field</label>
        <SearchInput
          options={sortFields.map(fieldToOption)}
          value={step.sortKey}
          onChange={handleSortKeyChange}
        />
      </div>
    </SortableItem>
  );
};

interface FunnelStepsProps {
  steps: FunnelStep[];
  models: Model[];
  metrics: Metric[];
  exploration: Exploration;
  setSteps: (steps: FunnelStep[]) => void;
  onClickEvent: (index: number) => void;
  onImportEvent: (index: number) => void;
}

export const FunnelSteps = (props: FunnelStepsProps) => {
  const { steps } = props;

  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const onReorder = (fromIndex: number, toIndex: number, after: boolean) => {
    const [step] = steps.splice(fromIndex, 1);
    const idx = after ? toIndex + 1 : toIndex;
    props.setSteps([...steps.slice(0, idx), step, ...steps.slice(idx)]);
  };

  return (
    <div className={styles.steps}>
      {props.steps.map((step, index) => (
        <Fragment key={index}>
          <Step
            index={index}
            step={step}
            metrics={props.metrics}
            models={props.models}
            exploration={props.exploration}
            setStep={(step) => {
              props.setSteps([...steps.slice(0, index), step, ...steps.slice(index + 1)]);
            }}
            onRemoveStep={() => {
              props.setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);
            }}
            onClickEvent={() => props.onClickEvent(index)}
            onImportEvent={() => props.onImportEvent(index)}
            draggedIndex={draggedIndex}
            setDraggedIndex={setDraggedIndex}
            onReorder={onReorder}
          />
          {index < steps.length - 1 && (
            <Icon name={'DownArrow'} size={14} className={styles.downArrow} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
