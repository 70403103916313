import { common } from '@gosupersimple/types';

import { omit } from 'lodash';

import { Select } from '@/components/form/select';
import {
  getBigNumberComparisonDefaults,
  getBigNumberFields,
  isBigNumberWithComparison,
  setBigNumberOptions,
} from '@/explore/components/visualisation/utils';
import { fieldToOption } from '@/explore/edit-pipeline/utils';
import { SortingEditor } from '@/explore/edit-pipeline/sorting-editor';
import { Toggle } from '@/components/form/toggle';

import { Field, Visualisation, VisualisationViewOptions } from '../../types';

import formStyles from '../../../components/form/form.module.scss';

interface EditBigNumberVisualisationProps {
  visualisation: Visualisation;
  fields: Field[];
  visualisationFields: Field[];
  onChange: (options: Visualisation) => void;
}

export const EditBigNumberVisualisation = (props: EditBigNumberVisualisationProps) => {
  const { visualisation, fields, visualisationFields, onChange } = props;

  const viewOptions = visualisation.viewOptions ?? {};
  const bigNumberOptions = viewOptions.bigNumber;
  const bigNumberFieldKey = bigNumberOptions?.key;
  const sorting = bigNumberOptions?.sort;
  const bigNumberComparisonKey =
    bigNumberOptions && 'comparisonKey' in bigNumberOptions
      ? bigNumberOptions?.comparisonKey
      : undefined;

  const handleChange = (newOptions: VisualisationViewOptions['bigNumber']) => {
    onChange({
      ...visualisation,
      viewOptions: setBigNumberOptions({ bigNumber: newOptions }, visualisation.viewOptions, {
        mainAxisKey: visualisation.mainAxisKey,
      }),
    });
  };

  const handleComparisonToggleChange = (checked: boolean) => {
    if (bigNumberOptions?.key === undefined) {
      throw new Error('Invalid big number state');
    }

    handleChange(
      !checked
        ? omit(bigNumberOptions, 'comparisonKey')
        : {
            ...bigNumberOptions,
            ...getBigNumberComparisonDefaults(bigNumberOptions.key),
          },
    );
  };
  const handleSortingChange = (sort: common.Sorting[]) => {
    if (bigNumberOptions?.key === undefined) {
      throw new Error('Invalid big number state');
    }
    handleChange({ ...bigNumberOptions, sort });
  };

  const handleComparisonChange = (newOptions: Partial<VisualisationViewOptions['bigNumber']>) => {
    if (!isBigNumberWithComparison(viewOptions?.bigNumber)) {
      return;
    }

    const optionsToUpdate: VisualisationViewOptions['bigNumber'] = {
      ...viewOptions.bigNumber,
      ...newOptions,
    };

    return handleChange(optionsToUpdate);
  };
  return (
    <div className={formStyles.formHorizontal}>
      {bigNumberFieldKey !== undefined ? (
        <div className={formStyles.formRow}>
          {sorting !== undefined && <label className={formStyles.formLabel}>Show</label>}
          <Select
            value={bigNumberFieldKey}
            options={getBigNumberFields(visualisationFields).map(fieldToOption)}
            onChange={(key) => handleChange({ key })}
          />
        </div>
      ) : null}
      {sorting !== undefined && (
        <div className={formStyles.formRow}>
          <label className={formStyles.formLabel}>sorted by</label>
          <SortingEditor
            fields={fields}
            sorting={sorting ?? []}
            setSorting={(sort) => {
              handleSortingChange(sort);
            }}
          />
        </div>
      )}
      {bigNumberFieldKey !== undefined ? (
        <>
          <Toggle
            checked={bigNumberComparisonKey !== undefined}
            onChange={handleComparisonToggleChange}
            size="small">
            Compare with
          </Toggle>
          {isBigNumberWithComparison(viewOptions?.bigNumber) ? (
            <>
              <Select
                value={viewOptions.bigNumber.comparisonKey}
                options={[
                  {
                    label: 'Second row of the same column',
                    value: bigNumberFieldKey,
                  },
                  ...getBigNumberFields(visualisationFields)
                    .map(fieldToOption)
                    .filter((field) => field.value !== bigNumberFieldKey),
                ]}
                onChange={(key) => handleComparisonChange({ comparisonKey: key })}
              />
              <Toggle
                checked={viewOptions.bigNumber.comparisonDirection === 'lower'}
                onChange={(state) =>
                  handleComparisonChange({
                    comparisonDirection: state ? 'lower' : 'higher',
                  })
                }
                size="small">
                Lower value is better
              </Toggle>
              <Toggle
                checked={viewOptions.bigNumber?.comparisonType === 'percentage'}
                onChange={(state) =>
                  handleComparisonChange({
                    comparisonType: state ? 'percentage' : 'absolute',
                  })
                }
                size="small">
                Display comparison as percentage
              </Toggle>
              <Toggle
                checked={viewOptions.bigNumber.showComparisonLabel ?? false}
                onChange={(state) => handleComparisonChange({ showComparisonLabel: state })}
                size="small">
                Display comparison field label
              </Toggle>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
