import { BarStack as VisxBarStack, BarRounded } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';

import { TimeSeriesData } from '../grouped-chart/types';
import { generateStackedChartDataByKeys } from './utils';

const StackedBarInnerPadding = 0.5;

type BarStackProps = {
  data: TimeSeriesData;
  keys: string[];
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
};

export const BarStack = (props: BarStackProps) => {
  const { data, keys, dateScale, valueScale } = props;

  const stackedData = generateStackedChartDataByKeys(data, keys);

  return (
    <VisxBarStack
      keys={keys}
      data={stackedData}
      x={(d) => d.date}
      xScale={dateScale}
      yScale={valueScale}
      color={(bar) => data.series.find(({ key }) => key === bar)?.color ?? ''}>
      {(barStacks) => {
        const barWidth = (dateScale.range()[1] / data.items.length) * (1 - StackedBarInnerPadding);
        return barStacks.map((barStack, barStackIdx) =>
          barStack.bars.map((bar) => (
            <BarRounded
              radius={5}
              key={`barstack-${barStack.index}-${bar.index}`}
              x={dateScale(bar.bar.data.date) - barWidth / 2}
              y={bar.y}
              width={barWidth}
              height={bar.height}
              fill={bar.color}
              top={barStackIdx === barStacks.length - 1}
            />
          )),
        );
      }}
    </VisxBarStack>
  );
};
