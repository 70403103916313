import classNames from 'classnames';

interface SegmentedControlProps<T extends Option> {
  options: T[];
  className?: string;
}

interface Option {
  label: string | React.ReactNode;
  onClick: () => void;
  selected: boolean;
  className?: string;
}

import style from './segmented-control.module.scss';

export const SegmentedControl = <T extends Option>(props: SegmentedControlProps<T>) => (
  <div className={classNames(style.container, props.className)}>
    {props.options.map((option, index) => (
      <button
        key={index}
        onClick={option.onClick}
        className={classNames({ [style.selected]: option.selected }, option.className)}>
        {option.label}
      </button>
    ))}
  </div>
);
