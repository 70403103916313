import { IconProps } from './types';

export const Detach = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <path
      fill={color}
      d="M3.471 6.222a.65.65 0 0 1-.65-.65V2.779c0-.47.38-.85.85-.85h2.81a.65.65 0 1 1 0 1.3H5.035l2.242 2.242 2.772-2.773a.65.65 0 1 1 .919.92l-6.45 6.45a.65.65 0 1 1-.92-.919l2.76-2.76L4.12 4.155v1.418a.65.65 0 0 1-.65.65ZM13.272 5.921l-2.76 2.76 2.237 2.236V9.499a.65.65 0 1 1 1.3 0v2.792c0 .47-.381.85-.85.85h-2.81a.65.65 0 1 1 0-1.3h1.446L9.593 9.6l-2.772 2.772a.65.65 0 1 1-.92-.92l6.452-6.45a.65.65 0 1 1 .919.92Z"
    />
  </svg>
);
