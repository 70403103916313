import classNames from 'classnames';

import { Icon } from '../../components/icon';

import styles from './exploration-search.module.scss';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  isCollapsed: boolean;
  onToggleCollapsed: () => void;
}

export const CollapsibleSection = (props: CollapsibleSectionProps) => {
  const { title, children, isCollapsed, onToggleCollapsed } = props;

  return (
    <div className={classNames(styles.collapsibleSection, { [styles.collapsed]: isCollapsed })}>
      <div className={styles.header} onClick={onToggleCollapsed}>
        <h2>{title}</h2>
        <Icon
          name={isCollapsed ? 'ChevronDown' : 'ChevronUp'}
          size={24}
          className={styles.collapseIcon}
        />
      </div>
      <div className={styles.content} inert={isCollapsed ? '' : undefined}>
        {children}
      </div>
    </div>
  );
};
