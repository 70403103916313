import { useState } from 'react';
import classNames from 'classnames';

import { Icon } from '@/components/icon';
import { Button } from '@/components/button';

import { Exploration, JoinPipelineOperation, Pipeline } from '../../types';
import { getCellByPipelineIdOrThrow, getCellIndex, isRecordsCell } from '../utils';
import { useMetadataContext } from '../../metadata-context';
import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { CombineState, CombineItemState } from './types';
import { CombineItemEditor } from './combine-item-editor';
import { getModelOrThrow } from '../../model/utils';
import {
  addCombineToExploration,
  buildOtherItem,
  convertItemToSide,
  getDefaultJoinKey,
  updateCombineInExploration,
} from './utils';

import form from '@/components/form/form.module.scss';
import style from './combine.module.scss';

interface CombineEditorProps {
  operation?: JoinPipelineOperation;
  setExploration: (exploration: Exploration) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const CombineEditor = (props: CombineEditorProps) => {
  const { exploration, getFieldsByCellId, getCellById, selectCell, scrollToCell } =
    useExplorationContext();
  const { cell } = useExplorationCellContext();
  const { models } = useMetadataContext();

  if (!('pipeline' in cell)) {
    throw new Error('CombineEditor requires a cell with a pipeline');
  }

  const buildItem = (
    input: { fromCellId: string } | { pipeline: Pipeline; key: string },
  ): CombineItemState | undefined => {
    if ('pipeline' in input) {
      const pipeline = input.pipeline;
      if ('parentId' in pipeline) {
        const cell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);
        if (isRecordsCell(cell)) {
          const fields = getFieldsByCellId(cell.id);

          return {
            kind: 'cell',
            cells: [cell],
            cellId: cell.id,
            title: cell.title ?? '',
            fields,
            joinKey: input.key,
            allFields: fields,
          };
        }
      }

      if ('baseModelId' in pipeline) {
        const model = getModelOrThrow(models, pipeline.baseModelId);
        const fields = model.properties.map(({ key, name, type }) => ({ key, name, type }));

        return {
          kind: 'model',
          modelId: pipeline.baseModelId,
          title: model.name,
          fields,
          joinKey: input.key,
          allFields: fields,
        };
      }
    }

    if ('fromCellId' in input) {
      const baseCell = getCellById(input.fromCellId);
      if (baseCell !== undefined && isRecordsCell(baseCell)) {
        const fields = getFieldsByCellId(baseCell.id);

        return {
          kind: 'cell',
          cells: [baseCell],
          cellId: baseCell.id,
          title: baseCell.title ?? '',
          fields,
          joinKey: getDefaultJoinKey(fields),
          allFields: fields,
        };
      }
    }

    return undefined;
  };

  const inputLeft =
    props.operation === undefined
      ? buildItem({ fromCellId: cell.id })
      : buildItem({
          pipeline: cell.pipeline,
          key: props.operation.parameters.joinStrategy.joinKeyOnBase,
        });

  const inputRight =
    props.operation === undefined
      ? undefined
      : buildItem({
          pipeline: props.operation.parameters.pipeline,
          key: props.operation.parameters.joinStrategy.joinKeyOnRelated,
        });

  const [leftItem, setLeftItem] = useState<CombineItemState | undefined>(inputLeft);
  const [rightItem, setRightItem] = useState<CombineItemState | undefined>(inputRight);

  const updateOperation = (combine: CombineState) => {
    const updatedExploration = updateCombineInExploration(exploration, combine, cell.id);
    if (updatedExploration === undefined) {
      return;
    }
    props.setExploration(updatedExploration);
  };

  const handleSubmit = (combine: CombineState) => {
    const result = addCombineToExploration(exploration, combine, cell.id);
    if (result === undefined) {
      return;
    }
    const combinedCellIndex = getCellIndex(result.cell.id, result.exploration);
    props.setExploration(result.exploration);
    selectCell(combinedCellIndex);
    scrollToCell(combinedCellIndex);
    props.onSubmit?.();
  };

  const emitOutput = (
    leftItem: CombineItemState | undefined,
    rightItem: CombineItemState | undefined,
  ) => {
    const left = convertItemToSide(leftItem);
    const right = convertItemToSide(rightItem);

    if (left === undefined || right === undefined || props.operation === undefined) {
      return;
    }

    updateOperation({ left, right });
  };

  const leftSide = convertItemToSide(leftItem);
  const rightSide = convertItemToSide(rightItem);
  const canSubmit = leftSide !== undefined && rightSide !== undefined;

  return (
    <div className={form.formContainer}>
      <div className={form.formSection}>
        <div className={form.sectionTitle}>Combined Data</div>

        <CombineItemEditor
          item={leftItem}
          setItem={(item) => {
            setLeftItem(item);

            const nextRightItem = buildOtherItem(item, rightItem);

            setRightItem(nextRightItem);
            emitOutput(item, nextRightItem);
          }}
        />
      </div>

      <div className={style.separator}>
        <div className={style.line} />
        <Icon name="Plus" size={16} color="currentColor" className={style.icon} />
        <div className={style.line} />
      </div>

      <div className={form.separatedFormSection}>
        <CombineItemEditor
          item={rightItem}
          setItem={(item) => {
            const rightItem = buildOtherItem(leftItem, item);

            setRightItem(rightItem);
            emitOutput(leftItem, rightItem);
          }}
        />
      </div>

      {props.operation === undefined && (
        <div className={classNames(form.formButtons)}>
          <Button
            onClick={() => {
              if (leftSide === undefined || rightSide === undefined) {
                return;
              }
              handleSubmit({ left: leftSide, right: rightSide });
            }}
            disabled={!canSubmit}>
            Combine
          </Button>
          {props.onCancel && (
            <Button onClick={props.onCancel} variant="outlined">
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
