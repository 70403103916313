import { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { useMeQuery } from '@/graphql';
import { useTitle } from '@/lib/hooks/use-title';
import { useGAPageview } from '@/lib/ga/use-ga';

import { retainNextUrlParam } from '../utils/url';
import { FullPageLoader } from '../../components/loader';
import { Cards, ErrorCard } from '../../components/gradient-layout/cards';
import { GoogleLogin } from './google-login';
import { PasswordLogin } from './password-login';
import { useAuth } from '../../auth/auth-context';

import commonStyles from '../public.module.scss';

export const LoginPage = () => {
  useTitle('Login');
  const { user } = useAuth();

  useGAPageview();
  const location = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);

  const { loading, error } = useMeQuery({
    skip: !loggedIn,
  });

  if (loading) {
    <FullPageLoader />;
  }

  const hasError = error !== undefined;
  const networkError = error?.networkError ?? {};
  const hasUnauthenticatedError = 'statusCode' in networkError && networkError.statusCode === 401;

  if (hasUnauthenticatedError) {
    if (user !== undefined && user?.emailVerified === false) {
      return <Navigate to={retainNextUrlParam('/verify-email', location)} />;
    }
    return (
      <ErrorCard>
        <p>We couldn’t find an account matching your email</p>
        <p>
          <Link to="/signup">Sign Up</Link>
        </p>
        <p>
          <Link to="/login" onClick={() => setLoggedIn(false)}>
            Try a different account
          </Link>
        </p>
      </ErrorCard>
    );
  }

  if (hasError) {
    return (
      <ErrorCard>
        <p>
          Something went wrong. Please <a href="mailto: hi@supersimple.io">contact us</a> if the
          issue persists.
        </p>
      </ErrorCard>
    );
  }

  const handleLogIn = () => {
    setLoggedIn(true);
  };

  return (
    <div className={commonStyles.layout}>
      <h1>Log In</h1>
      <Cards>
        <GoogleLogin onLogIn={handleLogIn} />
        <div className={commonStyles.separator}>or</div>
        <PasswordLogin onLogIn={handleLogIn} />
      </Cards>
      <p>
        Don&apos;t have an account yet? <a href="/signup">Sign up</a>
      </p>
    </div>
  );
};
