import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { DataTable } from '../datatable';
import { useOverflowHints } from '../datatable/hooks';
import { PaginatedRecordsComponentProps } from '../paginated-records';

import style from './horizontal-scroll-table.module.scss';

export const HorizontalScrollTable = forwardRef(function HorizontalScrollTable(
  props: PaginatedRecordsComponentProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [tableElement, setTableElement] = useState<HTMLElement | null>(null);
  const [scrollContainerElement, setScrollContainerElement] = useState<HTMLElement | null>(null);
  const [hintWrapperElement, setHintWrapperElement] = useState<HTMLElement | null>(null);

  const [shouldShowLeftHint, shouldShowRightHint] = useOverflowHints(
    tableElement,
    scrollContainerElement,
    hintWrapperElement,
  );

  return (
    <div
      ref={setHintWrapperElement}
      className={classNames({
        [style.scrollHintWrapper]: true,
        [style.showLeftOverflowHint]: shouldShowLeftHint,
        [style.showRightOverflowHint]: shouldShowRightHint,
      })}>
      <div
        className={style.scrollContainer}
        ref={(node) => {
          setScrollContainerElement(node);
          if (typeof forwardedRef === 'function') {
            forwardedRef(node);
          } else if (forwardedRef) {
            forwardedRef.current = node;
          }
        }}>
        <DataTable setRef={setTableElement} {...props} />
      </div>
    </div>
  );
});
