import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { useOnboardingStateQuery } from '../graphql';
import { useAccountContext } from '../lib/accounts/context';
import { OnboardingStep, onboardingStateSchema } from './types';
import { getNextStepPath } from './utils';

export const useOnboardingStepCheck = (currentStep: OnboardingStep) => {
  const navigate = useNavigate();
  const { account } = useAccountContext();

  const { data, loading, refetch } = useOnboardingStateQuery({
    variables: { accountId: account.accountId },
    notifyOnNetworkStatusChange: true,
  });

  const onboardingState = useMemo(() => {
    if (!loading) {
      return onboardingStateSchema.parse(data?.account?.onboardingState);
    }
  }, [loading, data]);

  useEffect(() => {
    if (onboardingState !== undefined) {
      return navigate(getNextStepPath(onboardingState, account));
    }
  }, [account, currentStep, onboardingState, navigate]);

  const check = () => {
    refetch({ accountId: account.accountId });
  };

  return { onboardingState, loading, check } as const;
};
