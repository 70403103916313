import { useEffect } from 'react';

import { isNil } from '../utils';

import headerStyles from '../../components/header/header.module.scss';

// Sticky elements will float this many pixels below the top of the window
const PAGE_HEADER_HEIGHT = parseInt(headerStyles.headerHeight, 10);

/**
 * Hook for creating 'sticky' elements that a fixed in place when the page is scrolled.
 * Usage: pass in the sticky (target) element's ref and the parent container's ref. The
 * sticky elemnt will be constrained to stay within the parent element.
 * You can optionally provide a custom `setY` function to control how the sticky
 * element will be positioned but make sure to pass any recalculated Y value through
 * `clampFn` to make sure the sticky element does not break out of its container element.
 */
export const useStickyElement = (
  targetElement: HTMLElement | null,
  containerElement: HTMLElement | null,
  setY = (y: number, targetElement: HTMLElement, clampFn: (y: number) => number) =>
    (targetElement.style.transform = `translate(0, ${clampFn(y)}px)`),
) => {
  useEffect(() => {
    if (isNil(targetElement) || isNil(containerElement)) {
      return;
    }

    const handleScroll = () => {
      const { height: targetHeight } = targetElement.getBoundingClientRect();
      const { top, height: containerHeight } = containerElement.getBoundingClientRect();
      const containerStyle = getComputedStyle(containerElement);
      const containerPadding =
        parseInt(containerStyle.paddingTop, 10) + parseInt(containerStyle.paddingBottom, 10);
      const clampY = (y: number) =>
        Math.min(containerHeight - containerPadding - targetHeight, Math.max(0, y));
      const y = clampY(-top + PAGE_HEADER_HEIGHT);
      setY(y, targetElement, clampY);
    };

    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });
};
