import { useRef, useState } from 'react';
import { nth } from 'lodash';
import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';

import { useExplorationContext } from '../exploration-context';
import { canDropOnRow, isCellDragEvent, trackDropEvent } from './utils';

import styles from './exploration-layout.module.scss';

const CellIndexKey = `application/x-sup-drag-cell`;

interface CellDropZoneProps {
  index: number;
  mergeIntoRow?: boolean;
  addBefore?: boolean;
  vertical?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const CellDropZone = (props: CellDropZoneProps) => {
  const { index, mergeIntoRow, addBefore } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  const { exploration, moveCell } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const cell = nth(exploration.view.cells, index);

  if (cell === undefined) {
    return props.children;
  }

  return (
    <div
      className={classNames(styles.cellDropZone, props.className, {
        [styles.isDragOver]: isDragOver,
        [styles.vertical]: props.vertical,
      })}
      ref={element}
      onDragEnd={() => {
        setIsDragOver(false);
      }}
      onDragOver={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          event.dataTransfer.dropEffect = 'none';
          return;
        }
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
      }}
      onDragEnter={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          return;
        }
        event.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(event) => {
        if (element.current && !element.current.contains(event.relatedTarget as Node)) {
          setIsDragOver(false);
        }
      }}
      onDrop={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        const droppedCellIndex = parseInt(event.dataTransfer.getData(CellIndexKey));
        moveCell(droppedCellIndex, index, addBefore !== true, mergeIntoRow);
        trackDropEvent(
          exploration,
          droppedCellIndex,
          index,
          nth(exploration.view.cells, droppedCellIndex),
          addBefore !== true,
          mergeIntoRow === true,
          trackEvent,
        );
      }}>
      {props.children}
    </div>
  );
};
