import { createContext, useContext, useState } from 'react';

const OverlayContext = createContext<{ overlayContainer: HTMLDivElement | null }>({
  overlayContainer: null,
});

export function useOverlayContainer() {
  return useContext(OverlayContext).overlayContainer;
}

export const OverlayContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <OverlayContext.Provider value={{ overlayContainer: ref }}>
      {children}
      <div ref={setRef} />
    </OverlayContext.Provider>
  );
};
