import { first, last } from 'lodash';
import { common } from '@gosupersimple/types';

import { Field, Grouping, TimeAggregationPeriod } from '../types';
import { getTimeAggregationPeriodLabel } from '../pipeline/format';
import { FieldGroup, isField, isFieldGroup } from '../pipeline/utils';

const DefaultTimePrecision: TimeAggregationPeriod = 'month' as const;

export const createGroupingFromField = (field: Field): Grouping => {
  return field.type === 'Date'
    ? { key: field.key, precision: DefaultTimePrecision }
    : { key: field.key };
};

export const getDefaultGrouping = (fields: Field[]): Grouping => {
  const firstField = first(fields);
  if (firstField === undefined) {
    throw new Error('No fields available for grouping');
  }
  const dateField = fields.find((field) => field.type === 'Date');
  return createGroupingFromField(dateField ?? firstField);
};

export const sortDateGroupingsLast = (groups: Grouping[]): Grouping[] =>
  [...groups].sort((a, b) => {
    const aHasPrecision = Boolean(a.precision);
    const bHasPrecision = Boolean(b.precision);
    return aHasPrecision === bHasPrecision ? 0 : aHasPrecision && !bHasPrecision ? 1 : -1;
  });

export const fillLastDateGroup = (groups: Grouping[], fields: Field[]): Grouping[] => {
  const lastGroup = last(groups);
  if (lastGroup) {
    const field = fields.find((f) => f.key === lastGroup.key);
    if (field?.type === 'Date') {
      return [
        ...groups.slice(0, -1),
        { key: lastGroup.key, fill: true, precision: lastGroup.precision ?? 'month' },
      ];
    }
  }
  return groups;
};

export const ensureValidGroupings = (groupings: Grouping[], fields: Field[]): Grouping[] =>
  groupings.filter((grouping) => fields.some((f) => f.key === grouping.key));

export const getGroupableFields = (
  fields: (Field | FieldGroup)[],
  existingGroupings: Grouping[],
) => {
  const isFieldGroupable = (field: Field | FieldGroup) =>
    isField(field) && !existingGroupings.some(({ key }) => key === field.key);

  return fields
    .map((fieldOrGroup) =>
      isFieldGroup(fieldOrGroup)
        ? { ...fieldOrGroup, fields: fieldOrGroup.fields.filter(isFieldGroupable) }
        : fieldOrGroup,
    )
    .filter((fieldOrGroup) =>
      isField(fieldOrGroup) ? isFieldGroupable(fieldOrGroup) : fieldOrGroup.fields.length > 0,
    );
};

export const timePrecisionOptions = common.timeInterval.options.map(({ value }) => ({
  value,
  label: getTimeAggregationPeriodLabel(value),
}));

export const setFillForDates = (groups: Grouping[]): Grouping[] => {
  return groups.map((group) => ({
    ...group,
    fill: group.precision !== undefined,
  }));
};
