import { useEffect, useMemo, useState } from 'react';
import { first } from 'lodash';
import classNames from 'classnames';

import { useExplorationDataQuery, getNodes, mapSort } from '@/graphql';
import {
  Breakpoint,
  useIsInView,
  useLoadingStatus,
  useQueryLoadCondition,
  useScreenSize,
} from '@/lib/hooks';
import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { useAccountContext, useAccountTimezone } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { isTimeoutError } from '@/lib/error/utils/timeout';

import { useExplorationContext } from './exploration-context';
import { useExplorationCellContext } from './exploration-cell-context';
import { EditColumns } from '../components/edit-columns';
import { Visualisations } from '../components/visualisations';
import { Loader } from '../../components/loader';
import { convertRecordTypeTypes } from '../input';
import {
  addExplorationUrlBuilders,
  dereferenceVariablesInPipeline,
  filterVariablesForPipeline,
  sortProperties,
} from '../utils';
import { detectAggregatedProperties } from '../utils/drilldown';
import { Exploration, RecordsCell, ExplorationParameters, SortItem } from '../types';
import { useMetadataContext } from '../metadata-context';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { PaginatedRecords } from '../components/paginated-records';
import { useContextMenu } from '../../components/context-menu/context-menu-context';
import { ErrorBanner } from '../../components/banner';
import { AlertFormModal } from '../../settings/alerts/edit-alert';
import { MasterBadge } from '../components/master-badge';
import { PipelinePreview } from './pipeline-preview';
import { dereferencePipeline, getParentPipelineCellIndex } from '../pipeline/utils';
import { CollapsibleContainer, CollapseButton, CollapsibleContent } from './collapsible-cell';
import { CellControls, CellOption } from './cell-controls';
import { CellTitle } from './cell-title';
import { HorizontalScrollTable } from '../components/horizontal-scroll-table';
import { CardsList } from '../components/cards-list';
import { containsInvalidOperations } from '../pipeline/operation';
import { getModelOrThrow } from '../model/utils';
import { SqlPreview } from './sql-preview';
import { YamlPreview } from './yaml-preview';
import { metricsByModelId } from '../utils/metrics';
import { getFinalStateOrThrow } from '../pipeline/state';
import { buildRangeFilterMenuItem } from '../components/datatable/context-menu/menu-items';

import style from './exploration.module.scss';
import table from '../../components/table/table.module.scss';

interface RecordsCellViewProps {
  cell: RecordsCell;
  exploration: Exploration;
  excludedColumns?: string[];
  className?: string;
  parameters: ExplorationParameters;
  height?: number;
  onSetDraggable: (value: boolean) => void;
}

export const RecordsCellView = (props: RecordsCellViewProps) => {
  const trackEvent = useTrackEvent();

  const { exploration, createCellInstance, selectCell, scrollToCell } = useExplorationContext();
  const {
    cell,
    cellIndex,
    setCell,
    showTable,
    hideTable,
    isTableVisible,
    addDefaultVisualization,
    isCollapsible,
    getYaml,
    copyCell,
  } = useExplorationCellContext();
  const { hasPermission } = useAccountContext();
  const screenSize = useScreenSize();
  const [isEditingColumns, setIsEditingColumns] = useState(false);
  const [isCreatingAlert, setIsCreatingAlert] = useState(false);
  const [isShowingSql, setIsShowingSql] = useState(false);
  const [isShowingYaml, setIsShowingYaml] = useState(false);
  const [isDragHovered, setIsDragHovered] = useState(false);
  const [containerRef, isInView] = useIsInView();

  const handleCreateCellInstance = () => {
    createCellInstance(cellIndex);
    selectCell(cellIndex + 1);
    scrollToCell(cellIndex + 1);

    trackEvent('Exploration Cell Instance Created', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleSetIsDragHovered = (value: boolean) => {
    props.onSetDraggable(value);
    setIsDragHovered(value);
  };

  const editButtonVisible = screenSize.breakpoint <= Breakpoint.md;

  return (
    <CollapsibleContainer ref={containerRef}>
      <div className={style.cellHeader}>
        <div className={style.cellControlsContainer}>
          <Icon
            name="DragHandle"
            size={10}
            className={style.dragHandle}
            onMouseOver={() => handleSetIsDragHovered(true)}
            onMouseOut={() => handleSetIsDragHovered(false)}
          />
          <MasterBadge exploration={props.exploration} pipeline={props.cell.pipeline} />
          <CellTitle
            exploration={props.exploration}
            value={props.cell.title ?? '(Untitled)'}
            onChange={(value) => setCell({ ...props.cell, title: value })}
          />
          <CellControls
            exploration={props.exploration}
            editButtonVisible={editButtonVisible}
            options={(defaultOptions) => [
              ...defaultOptions,
              {
                key: 'duplicate_as_instance',
                label: 'Duplicate as instance',
                icon: <Icon name="Instance" size={16} />,
                onClick: handleCreateCellInstance,
                sort: 20,
              },
              {
                type: 'divider',
                sort: 30,
              },
              {
                label: 'Copy block',
                icon: <Icon name="Clipboard" size={16} />,
                onClick: () => copyCell(),
                sort: 31,
              },
              {
                label: 'Show YAML',
                icon: <Icon name="Code" size={16} />,
                onClick: () => setIsShowingYaml(true),
                sort: 32,
              },
              {
                label: 'Show SQL',
                icon: <Icon name="Database" size={16} />,
                onClick: () => setIsShowingSql(true),
                sort: 33,
              },
              {
                type: 'divider',
                sort: 40,
              },
              ...(hasPermission('MANAGE_ALERTS')
                ? ([
                    {
                      label: 'Create alert',
                      icon: <Icon name="Alert" size={16} />,
                      onClick: () => setIsCreatingAlert(true),
                      sort: 41,
                      disabled: containsInvalidOperations(props.cell.pipeline.operations),
                    },
                    {
                      type: 'divider',
                      sort: 50,
                    },
                  ] as CellOption[])
                : []),
              {
                label: isTableVisible ? 'Hide table' : 'Show table',
                icon: <Icon name={isTableVisible ? 'EyeOff' : 'Eye'} size={16} />,
                onClick: () => (isTableVisible ? hideTable() : showTable()),
                sort: 51,
                disabled: (props.cell.visualisations?.length ?? 0) === 0,
              },
              {
                label: 'Edit columns',
                icon: <Icon name="Edit3" size={16} />,
                onClick: () => setIsEditingColumns(true),
                sort: 55,
              },
            ]}>
            <Button
              icon={<Icon name="Graphic" />}
              size="compact"
              variant="gray"
              title="Add chart"
              onClick={(e) => {
                e.currentTarget.blur();
                addDefaultVisualization();
              }}>
              Add chart
            </Button>
          </CellControls>

          {isCollapsible && <CollapseButton />}
        </div>
      </div>

      <CollapsibleContent isDragHovered={isDragHovered}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          {isShowingSql && (
            <SqlPreview
              pipeline={props.cell.pipeline}
              exploration={props.exploration}
              onClose={() => setIsShowingSql(false)}
            />
          )}
          {isShowingYaml && (
            <YamlPreview yaml={getYaml()} onClose={() => setIsShowingYaml(false)} />
          )}
          <RecordsCellViewInner
            {...props}
            isEditingColumns={isEditingColumns}
            setIsEditingColumns={setIsEditingColumns}
            isCreatingAlert={isCreatingAlert}
            setIsCreatingAlert={setIsCreatingAlert}
            isInView={isInView}
          />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

interface RecordsCellViewInnerProps extends RecordsCellViewProps {
  isEditingColumns: boolean;
  setIsEditingColumns: (value: boolean) => void;
  isCreatingAlert: boolean;
  setIsCreatingAlert: (value: boolean) => void;
  isInView: boolean;
  height?: number;
}

const RecordsCellViewInner = (props: RecordsCellViewInnerProps) => {
  const sort = mapSort(props.cell.sort);

  const { account } = useAccountContext();
  const { explorations, models, metrics: allMetrics } = useMetadataContext();
  const { openEditor, openAddForm, openEditForm, scrollToCell, getVariables } =
    useExplorationContext();
  const { setCell, cellIndex, setQueryMeta, tableMode, isTableVisible } =
    useExplorationCellContext();
  const { openContextMenu } = useContextMenu();
  const timezone = useAccountTimezone();
  const { isCollapsed, isPipelinePreviewVisible } = useExplorationCellContext();

  const pipeline = dereferencePipeline(props.cell.pipeline, props.exploration);
  const variables = filterVariablesForPipeline(pipeline, getVariables());
  const stateContext = {
    models,
    variables,
    metrics: allMetrics,
  };
  const { fields } = getFinalStateOrThrow(pipeline.baseModelId, pipeline.operations, stateContext);
  const baseModelName = getModelOrThrow(models, pipeline.baseModelId).name;

  const [onCompleted, onError, skip] = useQueryLoadCondition(
    props.isInView,
    isTableVisible,
    !isCollapsed,
  );

  const queryVariables = {
    accountId: account.accountId,
    baseModelId: pipeline.baseModelId,
    pipeline: pipeline.operations,
    sort,
    variables,
  };
  const { data, loading, error, fetchMore, refetch } = useExplorationDataQuery({
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
    onCompleted,
    onError,
  });

  const grouping = useMemo(() => data?.account?.query?.grouping ?? [], [data]);
  const groupedKeys = (grouping ?? []).map(({ key }) => key);

  const recordType = useMemo(
    () => convertRecordTypeTypes(data?.account?.query?.recordType ?? []),
    [data],
  );

  useEffect(
    () => setQueryMeta({ recordType, grouping }),
    [grouping, loading, recordType, setQueryMeta],
  );

  const handleFetchMore = async () => {
    if (loading || !(data?.account?.query?.pageInfo.hasNextPage ?? false)) {
      return;
    }

    const after = data?.account?.query?.pageInfo.endCursor;

    fetchMore({
      variables: {
        accountId: account.accountId,
        baseModelId: pipeline.baseModelId,
        pipeline: pipeline.operations,
        sort,
        after,
      },
    });
  };

  const allProperties = sortProperties(
    detectAggregatedProperties(addExplorationUrlBuilders(fields, explorations), pipeline),
    groupedKeys,
  );

  // Grouped columns cannot be excluded and must be visible at all times. Therefore they are always filtered out
  // from excludedColumns. Also they cannot be turned off in EditColumns view.
  const excludedColumns = (props.excludedColumns ?? props.cell.excludeProperties ?? []).filter(
    (key) => !groupedKeys.includes(key),
  );
  const visibleProperties = allProperties.filter(({ key }) => !excludedColumns.includes(key));

  const setExcludedColumns = (properties: string[]) =>
    setCell({ ...props.cell, excludeProperties: properties });

  const setSort = (sort: SortItem | null) =>
    setCell({ ...props.cell, sort: sort === null ? undefined : [sort] });

  const loaded = data?.account?.query !== undefined;

  const { isFirstLoad, isSubsequentLoad } = useLoadingStatus(loading, loaded);

  const model = getModelOrThrow(models, pipeline.baseModelId);
  const records = getNodes(data?.account?.query);
  const visualisations = props.cell.visualisations ?? [];

  const dereferencedPipeline = useMemo(
    () => dereferencePipeline(props.cell.pipeline, props.exploration),
    [props.cell.pipeline, props.exploration],
  );

  const metrics = useMemo(
    () => metricsByModelId(allMetrics, dereferencedPipeline.baseModelId),
    [allMetrics, dereferencedPipeline.baseModelId],
  );

  if (error !== undefined) {
    const code = first(error.graphQLErrors)?.extensions.code;
    if (code === 'INVALID_OPERATION') {
      return <ErrorBanner title="Invalid operation" description={error.message} />;
    }

    if (isTimeoutError(error)) {
      return (
        <ErrorBanner
          title="Database didn't respond in time"
          actions={[{ label: 'Retry', onClick: () => refetch() }]}
        />
      );
    }

    return <ErrorBanner details={error.message} />;
  }

  const isResized = props.height !== undefined;

  return (
    <>
      {props.isEditingColumns && (
        <EditColumns
          properties={allProperties.filter(({ key }) => !groupedKeys.includes(key))}
          excludedColumns={excludedColumns}
          setExcludedColumns={setExcludedColumns}
          onClose={() => props.setIsEditingColumns(false)}
        />
      )}

      {props.isCreatingAlert && (
        <AlertFormModal
          ctx={stateContext}
          alertConfiguration={{
            name: props.cell.title ?? undefined,
            pipeline: dereferenceVariablesInPipeline(pipeline, variables),
            keyFields: fields.filter((field) => field.pk).map((field) => field.key) ?? [],
          }}
          onClose={() => props.setIsCreatingAlert(false)}
          onAlertSaved={() => props.setIsCreatingAlert(false)}
        />
      )}

      {isPipelinePreviewVisible && (
        <div className={style.cellHeaderAccessory}>
          <PipelinePreview
            title={baseModelName}
            pipeline={props.cell.pipeline}
            exploration={props.exploration}
            variables={variables}
            models={models}
            onClickParent={() =>
              scrollToCell(getParentPipelineCellIndex(props.exploration, props.cell.pipeline))
            }
            onClickOperation={(index) => {
              openEditor({ cellIndex });
              openEditForm({ index });
            }}
          />
        </div>
      )}

      {visualisations.length > 0 && (
        <div className={style.cellSection}>
          <Visualisations
            accountId={account.accountId}
            pipeline={dereferencedPipeline}
            visualisations={visualisations}
            models={models}
            metrics={metrics}
            onValueClick={(x, y, key, value, precision) => {
              const property = allProperties.find((property) => property.key === key);
              if (property === undefined) {
                throw new Error(`Unknown property ${key} on visualisation`);
              }
              if (precision !== undefined) {
                property.precision = precision;
              }
              openContextMenu({
                position: {
                  x: x - 10,
                  y: y + 10,
                },
                items: [
                  buildRangeFilterMenuItem(
                    cellIndex,
                    openEditor,
                    openAddForm,
                    property,
                    value,
                    timezone,
                  ),
                ],
              });
            }}
            isInView={props.isInView}
            isResized={isResized}
          />
        </div>
      )}

      {isTableVisible && (
        <div
          className={classNames(style.cellSection, style.tableSection, {
            [table.groupedTable]: groupedKeys.length > 0,
          })}>
          <PaginatedRecords
            properties={visibleProperties}
            sort={sort}
            setSort={setSort}
            records={records}
            pipeline={pipeline}
            variables={variables}
            cellHeight={props.height}
            defaultPageSize={tableMode === 'cards' ? 3 : 10}
            model={model}
            fetchMore={handleFetchMore}
            footerContent={skip || (isSubsequentLoad && <Loader />)}
            loading={skip || isFirstLoad}
            component={tableMode === 'cards' ? CardsList : HorizontalScrollTable}
          />
        </div>
      )}
    </>
  );
};
