export const ChevronDownIcon = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.177 5.53c.26-.261.683-.261.943 0L7.65 9.057l3.528-3.529a.667.667 0 1 1 .943.943l-4 4a.667.667 0 0 1-.943 0l-4-4a.667.667 0 0 1 0-.943"
    />
  </svg>
);
