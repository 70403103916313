import { omit } from 'lodash';

import { Json } from '@/lib/types';

export type ListRecord = Record<string, Json>;

export type NestedListItem<T extends ListRecord = ListRecord> = T & {
  $leaves?: number;
  $children?: NestedList<T>;
};

/**
 * Nested list is a record that can recursively contain children records. Here's an example of nested list
 * that has root records A, D, F, G. This file performs various operations on such nested lists.
 *
 *       A          D      F      G
 *      / \        / \     |     / \
 *     B   C      E   \    |    /   H
 *    / \   \    /     \   |   /   / \
 *   0   1   2  3       4  5  6   7   8
 *
 * One optimization is to count the number of leaves on each node. For example, in the schema above, the root node A
 * has 3 leaves, B has 2, and C has 1.
 *
 * Number of leaves is re-counted when the nested list is modified.
 */
export type NestedList<T extends ListRecord = ListRecord> = NestedListItem<T>[];

export const countRecords = (list: NestedList): number =>
  list.reduce((memo, item) => {
    if ('$children' in item) {
      return memo + countRecords(item.$children ?? []);
    }

    return memo + 1;
  }, 0);

export const flattenNestedList = <T extends ListRecord = ListRecord>(
  list: NestedList<T>,
  parent: Partial<T> = {},
): T[] => {
  return list.reduce((memo, item) => {
    const inner = { ...parent, ...omit(item, ['$children', '$leaves']) };

    if ('$children' in item) {
      return [...memo, ...flattenNestedList(item.$children ?? [], { ...inner } as T)];
    }

    return [...memo, { ...parent, ...item }];
  }, [] as T[]);
};

/**
 * This function is used to implement pagination. It returns a new nested list by removing the number of "leaves" from
 * the beginning or the end of nested list. For example, calling sliceNestedList(list, 2, 7) on the example above,
 * it'll return a new nested list that looks like this:
 *
 *  A      D      F  G
 *  |     / \     |  |
 *  C    E   \    |  |
 *  |   /     \   |  |
 *  2  3       4  5  6
 *
 */
export const sliceNestedList = (list: NestedList, start: number, end?: number): NestedList =>
  sliceNestedListInner(list, start, end).list;

const sliceNestedListInner = (
  list: NestedList,
  start: number,
  end?: number,
  rangeStart = 0,
): { list: NestedList; index: number } =>
  list.reduce<{ list: NestedList; index: number }>(
    ({ list, index }, item) => {
      if ('$children' in item) {
        const result = sliceNestedListInner(item.$children ?? [], start, end, index);
        const leaves = countLeaves(result.list);

        if (leaves > 0) {
          return {
            list: [...list, { ...item, $children: result.list, $leaves: leaves }],
            index: result.index,
          };
        }

        return { list, index: result.index };
      }

      if (index >= start && (end === undefined || index < end)) {
        return { list: [...list, item], index: index + 1 };
      }

      return { list, index: index + 1 };
    },
    { list: [], index: rangeStart },
  );

const countLeaves = (records: NestedList): number =>
  records.reduce((memo, item) => ('$leaves' in item ? memo + (item.$leaves ?? 0) : memo + 1), 0);
