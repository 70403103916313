import { keymap } from '@codemirror/view';
import { indentLess } from '@codemirror/commands';

// Penguino editor tab indentation is customized from normal tab indentaion behavior.
export const tabIndentExtension = keymap.of([
  {
    key: 'Tab',
    run: ({ state, dispatch }) => {
      const cursorPos = state.selection.main.head;
      const lineSlice = state.doc.sliceString(state.doc.lineAt(cursorPos).from, cursorPos);

      if (/^\s*$/.test(lineSlice)) {
        dispatch(
          state.update(state.replaceSelection('  '), { scrollIntoView: true, userEvent: 'input' }),
        );
        return true;
      }

      return false;
    },
    shift: indentLess,
  },
]);
