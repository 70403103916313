import { MouseEventHandler, ReactNode } from 'react';

import { Icon, IconProps } from '../../components/icon';
import { Button } from '../../components/button';

import styles from './common.module.scss';

interface SectionProps {
  children: ReactNode;
  title: string;
  headerActions?: { label: string; iconName: IconProps['name']; onClick: MouseEventHandler }[];
}

export const Section = (props: SectionProps) => {
  const { children, title, headerActions = [] } = props;
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <div>
          {headerActions.map(({ label, iconName, onClick }, idx) => (
            <Button
              key={idx}
              icon={<Icon name={iconName} size={16} />}
              variant="outlined"
              onClick={onClick}>
              {label}
            </Button>
          ))}
        </div>
      </div>
      <div>{children}</div>
    </section>
  );
};
