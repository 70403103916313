import { Exploration } from '../types';
import { ExplorationStateStore } from './types';

export const createInMemoryStore = (explorations: Exploration[]): ExplorationStateStore => {
  const store = new Map(
    explorations.map((exploration) => [exploration.explorationId, exploration]),
  );

  return {
    setExploration: (exploration) => {
      store.set(exploration.explorationId, exploration);
    },

    getExploration: (explorationId) => store.get(explorationId),
  };
};
