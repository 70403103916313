import { IconProps } from './types';

export const VariableIcon = ({ size = 12, color = 'currentColor' }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.39258C1 2.28801 1.89543 1.39258 3 1.39258H13C14.1046 1.39258 15 2.28801 15 3.39258V13.3926C15 14.4971 14.1046 15.3926 13 15.3926H3C1.89543 15.3926 1 14.4971 1 13.3926V3.39258ZM7.32001 5.38522C7.62226 4.8617 8.3779 4.8617 8.68015 5.38522L11.2871 9.90058C11.5893 10.4241 11.2115 11.0785 10.607 11.0785H5.39314C4.78863 11.0785 4.41081 10.4241 4.71307 9.90058L7.32001 5.38522Z"
      fill={color}
    />
  </svg>
);
