import { common } from '@gosupersimple/types';

import { QuerySortDirection } from '../generated/graphql';

export function sortingToGql(sorting: common.Sorting[]) {
  return sorting.map(({ key, direction }) => {
    switch (direction) {
      case 'ASC':
        return { key, direction: QuerySortDirection.Asc };
      case 'DESC':
        return { key, direction: QuerySortDirection.Desc };
      default:
        throw new Error(`Unknown direction: ${direction}`);
    }
  });
}
