import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';

import { ExplorationDetailsForm, ExplorationDetailsFormData } from '../details-form';

import styles from './create-modal.module.scss';

interface ExplorationCreateModalProps {
  onCreate: (data: ExplorationDetailsFormData) => Promise<void>;
  onClose: () => void;
}

export const ExplorationCreateModal = ({ onCreate, onClose }: ExplorationCreateModalProps) => (
  <Modal onClose={onClose} closeOnClickAway>
    <Panel className={styles.panel}>
      <div className={styles.header}>
        <h1>Save as a new exploration</h1>
      </div>

      <div className={styles.body}>
        <p>This will publish changes to everyone in your team.</p>
        <ExplorationDetailsForm
          data={{ name: '', description: '' }}
          onSubmit={onCreate}
          onCancel={onClose}
        />
      </div>
    </Panel>
  </Modal>
);
