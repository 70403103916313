import { ErrorBoundary } from '@/lib/error';
import { ErrorBanner } from '@/components/banner';

import { Model, DereferencedPipeline, Metric, ValueClickHandler } from '../../types';
import { Visualisation } from '../../types';
import { VisualisationPanel } from '../visualisation';
import { useExplorationCellContext } from '../../exploration/exploration-cell-context';

import style from './visualisations.module.scss';

interface VisualisationsProps {
  accountId: string;
  pipeline: DereferencedPipeline;
  visualisations: Visualisation[];
  onValueClick?: ValueClickHandler;
  models: Model[];
  metrics: Metric[];
  isInView: boolean;
  isResized: boolean;
}

export const Visualisations = (props: VisualisationsProps) => {
  const { accountId, pipeline, models, metrics, onValueClick, visualisations } = props;

  const { setVisualisation, removeVisualisation } = useExplorationCellContext();

  if (visualisations.length === 0) {
    return null;
  }

  return (
    <div className={style.visualisations}>
      {visualisations.map((visualisation, idx) => (
        <ErrorBoundary
          key={idx}
          fallback={(error) => <ErrorBanner description={(error as any).message} />}>
          <VisualisationPanel
            visualisation={visualisation}
            pipeline={pipeline}
            models={models}
            metrics={metrics}
            accountId={accountId}
            onClose={() => removeVisualisation(idx)}
            onValueClick={onValueClick}
            onVisualisationChange={(visualisation) => setVisualisation(idx, visualisation)}
            isInView={props.isInView}
            isResized={props.isResized}
          />
        </ErrorBoundary>
      ))}
    </div>
  );
};
