import { UserRole } from '@/graphql';

export const UserRoles = [
  {
    value: UserRole.Explorer,
    label: 'Explorer',
    description:
      "Explorers have read-only access. This means they can freely explore data, but can't save or change explorations for the broader team. They can still share any of their work with teammates via link.",
  },
  {
    value: UserRole.Editor,
    label: 'Editor',
    description:
      'Editors can explore data, as well as to save, change and delete existing explorations for the entire team.',
  },
  {
    value: UserRole.Admin,
    label: 'Admin',
    description:
      'Admins can explore data, manage saved explorations, configure account settings and manage user accounts.',
  },
];

export const mapUserRole = (value: string): UserRole => {
  if (Object.values(UserRole).includes(value as UserRole)) {
    return value as UserRole;
  }
  throw new Error(`Invalid user role: ${value}`);
};
