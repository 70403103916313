import { common } from '@gosupersimple/types';
import { first } from 'lodash';

import { Fields } from '../types';
import { isNumberType } from '.';

export const getDefaultSlice = (fields: Fields, previousSlice?: common.Slice): common.Slice => {
  const isSortValid =
    previousSlice?.sort?.every(({ key }) => fields.some((field) => field.key === key)) ?? false;
  return {
    limit: 100,
    ...previousSlice,
    sort: isSortValid ? previousSlice?.sort : [getDefaultSorting(fields)],
  };
};

export const getDefaultSorting = (fields: Fields): common.Sorting => {
  const field =
    fields.find(({ type }) => type === 'Date') ??
    fields.find(({ type }) => isNumberType(type)) ??
    first(fields);
  return {
    key: field?.key ?? '',
    direction: field?.type === 'Date' || isNumberType(field?.type) ? 'DESC' : 'ASC',
  };
};
