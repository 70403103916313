import { List, ListItem } from '../common';
import { getCreatedAtLabel } from '../utils';

import styles from './list.module.scss';

export interface ConnectionListItem {
  name: string;
  type: string;
  createdAt: string;
}

interface ConnectionListProps {
  connections: ConnectionListItem[];
}

export const ConnectionList = (props: ConnectionListProps) => {
  return (
    <List numColumns={3}>
      {props.connections.map(({ name, type, createdAt }, i) => {
        return (
          <ListItem key={i}>
            <div className={styles.name}>{name}</div>
            <div>{type}</div>
            <div className={styles.createdAt}>{getCreatedAtLabel(createdAt)}</div>
          </ListItem>
        );
      })}
    </List>
  );
};
