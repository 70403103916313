import { useApolloClient } from '@apollo/client';

import { GetExplorationStateQuery, GetExplorationStateQueryVariables } from '@/graphql';

import * as queries from '../../generated/operation-documents';
import { ExplorationStateStore } from './types';

export const createApolloStore = (
  client: ReturnType<typeof useApolloClient>,
  accountId: string,
): ExplorationStateStore => ({
  setExploration: async (exploration) => {
    await client.mutate({
      mutation: queries.SetExplorationState,
      variables: {
        accountId,
        input: {
          explorationId: exploration.explorationId,
          previousStateId: exploration.options?.previousStateId,
          explorationSourceId: exploration.options?.explorationSourceId,
          state: exploration,
        },
      },
    });
  },

  getExploration: async (explorationId) => {
    const { data } = await client.query<
      GetExplorationStateQuery,
      GetExplorationStateQueryVariables
    >({
      query: queries.GetExplorationState,
      variables: { accountId, explorationId },
    });

    return data?.account?.explorationState?.state ?? undefined;
  },
});
