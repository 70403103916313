import { useEffect, useMemo, useRef, useState } from 'react';

import { Button, InlineButton } from '@/components/button';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Panel } from '@/components/panel';
import { Input } from '@/components/form/input';

import styles from './edit-columns.module.scss';

const FilterVisibilityThreshold = 10;

interface ModelProperty {
  key: string;
  type: string;
  name: string;
}

interface EditColumnsProps {
  properties: ModelProperty[];
  excludedColumns: string[];
  setExcludedColumns(properties: string[]): void;
  onClose(): void;
}

export const EditColumns = (props: EditColumnsProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (panelRef.current === null || panelRef.current.contains(event.target as Node)) {
        return;
      }

      props.onClose();
    };

    // Need to delay the register since click that did open the panel will also trigger this
    setTimeout(() => document.addEventListener('click', handleDocumentClick));

    return () => document.removeEventListener('click', handleDocumentClick);
  }, [props]);

  const showFilter = props.properties.length > FilterVisibilityThreshold;

  const filteredProperties = useMemo(
    () =>
      !showFilter || searchTerm === ''
        ? props.properties
        : props.properties.filter(({ name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
    [props.properties, searchTerm, showFilter],
  );

  const handleResetFilter = () => {
    setSearchTerm('');
  };

  const handleSelectNone = () => {
    props.setExcludedColumns([]);
    handleResetFilter();
  };

  const handleSelectAll = () => {
    props.setExcludedColumns(props.properties.map(({ key }) => key));
    handleResetFilter();
  };

  return (
    <div ref={panelRef} className={styles.editColumns}>
      <Panel title="Edit columns">
        <div className={styles.content}>
          <div>
            Select <InlineButton onClick={handleSelectNone}>all</InlineButton>
            <span>{' / '}</span>
            <InlineButton onClick={handleSelectAll}>none</InlineButton>
          </div>
          {showFilter ? (
            <Input
              value={searchTerm}
              placeholder="Filter"
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
          ) : null}
          {filteredProperties.length === 0 ? (
            <div>No columns found</div>
          ) : (
            <div className={styles.list}>
              {filteredProperties.map(({ name, key }) => (
                <div key={key} className={styles.item}>
                  <CheckboxWithLabel
                    id={key}
                    checked={!props.excludedColumns.includes(key)}
                    onChange={({ checked }) => {
                      const excluded = new Set(props.excludedColumns);
                      if (!checked) {
                        excluded.add(key);
                      } else {
                        excluded.delete(key);
                      }
                      props.setExcludedColumns(Array.from(excluded));
                    }}>
                    {name}
                  </CheckboxWithLabel>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={styles.footer}>
          <Button onClick={props.onClose} size="small">
            Done
          </Button>
        </div>
      </Panel>
    </div>
  );
};
