import { MouseEventHandler, ReactNode, useState } from 'react';

import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { Icon, IconProps } from '../icon';
import { Button, InlineButton } from '../button';

import styles from './banner.module.scss';

const MinHeight = 285;
const IconSize = 48;

type BannerAction = {
  label: string;
  onClick: MouseEventHandler;
};

interface BannerProps {
  image?: ReactNode;
  title?: string;
  description?: React.ReactNode;
  details?: React.ReactNode;
  minHeight?: number;
  requiresAction?: boolean;
  important?: boolean;
  actions?: BannerAction[];
}

const Banner = ({
  image,
  title,
  description,
  details,
  minHeight = MinHeight,
  requiresAction = false,
  important = false,
  actions = [],
}: BannerProps) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const toggleDetailsVisible = () => setDetailsVisible((visible) => !visible);

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.requiresAction]: requiresAction,
        [styles.important]: important,
      })}
      style={{ minHeight: minHeight !== undefined ? `${minHeight}px` : undefined }}>
      {image !== undefined ? image : null}
      {!isEmpty(title) && <div className={styles.title}>{title}</div>}
      {!isEmpty(description) && (
        <div className={styles.description}>
          {description}{' '}
          {details !== undefined && (
            <InlineButton onClick={toggleDetailsVisible}>
              {detailsVisible ? 'Hide Details' : 'Show Details'}
            </InlineButton>
          )}
        </div>
      )}
      {details !== undefined && detailsVisible && (
        <pre className={styles.fineDetails}>{details}</pre>
      )}
      {!isEmpty(description) && !isEmpty(actions) && (
        <div>
          {actions.map((action, i) => (
            <Button key={i} size="small" variant="secondary" onClick={action.onClick}>
              {action.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

interface IconBannerProps {
  iconName: IconProps['name'];
  title?: string;
  description?: React.ReactNode;
  minHeight?: number;
  actions?: BannerAction[];
  requiresAction?: boolean;
}

export const IconBanner = ({
  iconName,
  title,
  description,
  minHeight,
  actions,
  requiresAction = false,
}: IconBannerProps) => (
  <Banner
    image={<Icon name={iconName} size={IconSize} className={styles.icon} />}
    title={title}
    description={description}
    minHeight={minHeight}
    actions={actions}
    requiresAction={requiresAction}
  />
);

interface ErrorBannerProps {
  title?: string;
  description?: React.ReactNode;
  details?: React.ReactNode;
  minHeight?: number;
  actions?: BannerAction[];
}

export const ErrorBanner = ({
  title = 'Oops, something went wrong',
  description = 'Our team has been notified. Please try again later, or get in touch with us for help.',
  details,
  minHeight,
  actions,
}: ErrorBannerProps) => (
  <Banner
    image={<img src="/something-wrong.svg" alt="Error" />}
    title={title}
    description={description}
    details={details}
    minHeight={minHeight}
    actions={actions}
    important
  />
);

interface NoMatchBannerProps {
  title?: string;
  description?: React.ReactNode;
  minHeight?: number;
  actions?: BannerAction[];
}

export const NoMatchBanner = ({
  title = 'No matching data was found',
  description,
  minHeight,
  actions,
}: NoMatchBannerProps) => (
  <Banner
    image={<img src="/no-match.svg" alt="No matching data was found" />}
    title={title}
    description={description}
    minHeight={minHeight}
    actions={actions}
  />
);
