export const SummariseIcon = ({
  size = 12,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.369143 0.696573C0.485707 0.454047 0.730972 0.299805 1.00006 0.299805H8.33339C8.71205 0.299805 9.06455 0.461411 9.31653 0.731391C9.56699 0.99974 9.70006 1.35399 9.70006 1.71409V3.14266C9.70006 3.52926 9.38665 3.84266 9.00006 3.84266C8.61346 3.84266 8.30006 3.52926 8.30006 3.14266V1.71409C8.30006 1.7088 8.29942 1.70398 8.29844 1.6998H2.45649L5.54666 5.56252C5.75119 5.81817 5.75119 6.18144 5.54666 6.43709L2.45649 10.2998H8.29844C8.29942 10.2956 8.30006 10.2908 8.30006 10.2855V8.85695C8.30006 8.47035 8.61346 8.15695 9.00006 8.15695C9.38665 8.15695 9.70006 8.47035 9.70006 8.85695V10.2855C9.70006 10.6456 9.56699 10.9999 9.31653 11.2682C9.06455 11.5382 8.71204 11.6998 8.33339 11.6998H1.00006C0.730972 11.6998 0.485707 11.5456 0.369143 11.303C0.252579 11.0605 0.285352 10.7726 0.453447 10.5625L4.10362 5.9998L0.453447 1.43709C0.285352 1.22697 0.252579 0.939098 0.369143 0.696573Z"
      fill={color}
    />
  </svg>
);
