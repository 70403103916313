import { IconProps } from './types';

export const Graphic = ({ size = 12, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M1.5 1.835c.367 0 .665.298.665.665v10.335H14.5a.665.665 0 0 1 0 1.33H2A1.165 1.165 0 0 1 .835 13V2.5c0-.367.298-.665.665-.665Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.762 2.889a.665.665 0 0 1 .35.873l-2.61 6.087a1.165 1.165 0 0 1-2.077.128L7.07 5.937 4.604 11.28a.665.665 0 1 1-1.208-.558l2.598-5.628a1.165 1.165 0 0 1 2.064-.099l2.35 4.03 2.48-5.786a.665.665 0 0 1 .874-.35Z"
      clipRule="evenodd"
    />
  </svg>
);
