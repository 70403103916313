import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useScrollLock } from 'usehooks-ts';
import classnames from 'classnames';

import { useNavigationQuery, useExplorationsQuery } from '@/graphql';
import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useScreenSize, Breakpoint } from '@/lib/hooks';
import { buildExplorationUrl } from '@/explore/utils/url';
import { IconButton } from '@/components/button';
import { convertModelTypes } from '@/explore/input';
import { getModelExploration } from '@/explore/utils';

import { AccountMenu, DefaultAccountMenu } from '../header/account-menu';
import { Dropdown, DropdownMenuItem } from '../dropdown';
import { Icon } from '../icon';

import styles from './sidebar-layout.module.scss';

interface Layout {
  isLeftSidebarOpen: boolean;
  toggleLeftSidebar: (newVal?: boolean) => void;
  isRightSidebarOpen: boolean;
  toggleRightSidebar: (newVal?: boolean) => void;
  setActiveExplorationSourceId: (explorationId: string | null) => void;
}
const LayoutContext = createContext<Layout>({
  isLeftSidebarOpen: false,
  toggleLeftSidebar: () => {},
  isRightSidebarOpen: false,
  toggleRightSidebar: () => {},
  setActiveExplorationSourceId: () => {},
});
export const useLayoutContext = () => useContext(LayoutContext);

const Logo = () => (
  <img src="/logo-pinguin.svg" style={{ width: 24 }} title="Supersimple" alt="Supersimple logo" />
);

export function LayoutContextProvider({
  onClickLogOut,
  children,
}: {
  children: React.ReactNode;
  onClickLogOut: () => void;
}) {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const [activeExplorationSourceId, setActiveExplorationSourceId] = useState<string | null>(null);
  // Used for re-opening the sidebar when the user expands the window
  const leftSidebarNarrowState = useRef(false);
  // TODO: Add keyboard shortcuts to toggle sidebar
  const toggleLeftSidebar = (newVal?: boolean) => setIsLeftSidebarOpen((prev) => newVal ?? !prev);
  const toggleRightSidebar = (newVal?: boolean) => setIsRightSidebarOpen((prev) => newVal ?? !prev);

  const location = useLocation();
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();
  const screenSize = useScreenSize();
  const { data } = useNavigationQuery({
    variables: {
      accountId: account?.accountId ?? '',
    },
    skip: account === null,
    fetchPolicy: 'cache-first',
  });

  // Scroll lock for mobile
  const { lock, unlock, isLocked } = useScrollLock({
    autoLock: false,
  });
  useEffect(() => {
    if (screenSize.breakpoint <= Breakpoint.lg) {
      isLeftSidebarOpen ? lock() : unlock();
    } else if (screenSize.breakpoint > Breakpoint.lg && isLocked) {
      unlock();
    }
  }, [isLeftSidebarOpen, lock, unlock, isLocked, screenSize.breakpoint]);

  useEffect(() => {
    if (screenSize.breakpoint <= Breakpoint.lg && !leftSidebarNarrowState.current) {
      leftSidebarNarrowState.current = true;
      setIsLeftSidebarOpen(false);
    } else if (screenSize.breakpoint > Breakpoint.lg && leftSidebarNarrowState.current) {
      setIsLeftSidebarOpen(true);
      leftSidebarNarrowState.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize.breakpoint]);

  useEffect(() => {
    if (!location.pathname.includes('/explore/')) {
      setActiveExplorationSourceId(null);
    }
  }, [location.pathname]);

  const { data: explorations } = useExplorationsQuery({
    variables: { accountId: account.accountId },
    fetchPolicy: 'cache-first',
  });

  const models = useMemo(() => {
    if (explorations?.account?.models === undefined) {
      return [];
    }
    return convertModelTypes(explorations.account.models) ?? [];
  }, [explorations]);
  const modelExplorations = useMemo(() => {
    return models.map((model) => getModelExploration(model));
  }, [models]);

  const savedExplorations = useMemo(() => {
    if (explorations === undefined || explorations.account?.explorations === undefined) {
      return [];
    }

    return explorations.account?.explorations ?? [];
  }, [explorations]);

  const pinnedExplorations = useMemo(() => {
    return (
      data?.account?.navigation.map((item) => ({
        explorationId: item.explorationId,
        label: item.label,
        url: buildAccountUrl(buildExplorationUrl(item)),
      })) ?? []
    );
  }, [data?.account, buildAccountUrl]);

  const handleMobileNavigation = useCallback(() => {
    if (screenSize.breakpoint <= Breakpoint.lg) {
      setIsLeftSidebarOpen(false);
    }
  }, [screenSize.breakpoint]);

  const handleNavClick = useCallback(() => {
    setActiveExplorationSourceId(null);
    handleMobileNavigation();
  }, [setActiveExplorationSourceId, handleMobileNavigation]);

  const helpMenuItems: DropdownMenuItem[] = [
    {
      type: 'link',
      label: 'Account Settings',
      icon: <Icon name="Settings" size={16} />,
      href: buildAccountUrl('/settings'),
    },
    { type: 'divider' },
    {
      type: 'button',
      label: 'Get Help',
      icon: <Icon name="MessageSquare" size={16} />,
      className: 'intercom-launcher',
      onClick: () => {
        Intercom('show');
        handleMobileNavigation();
      },
    },
    {
      type: 'link',
      label: 'Documentation',
      icon: <Icon name="Edit2" size={16} />,
      href: 'https://docs.supersimple.io',
      external: true,
    },
    { type: 'divider' },
    {
      type: 'button',
      label: 'Log out',
      icon: <Icon name="X" size={16} />,
      onClick: onClickLogOut,
    },
  ];

  return (
    <LayoutContext.Provider
      value={{
        isLeftSidebarOpen,
        toggleLeftSidebar,
        isRightSidebarOpen,
        toggleRightSidebar,
        setActiveExplorationSourceId,
      }}>
      {isLeftSidebarOpen ? (
        <aside className={classnames(styles.leftSidebar)}>
          <div className={styles.topRow}>
            <NavLink
              to={buildAccountUrl('/explore')}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <Logo />
            </NavLink>

            <IconButton
              icon="ChevronLeft"
              size="small"
              title="Collapse sidebar"
              className={styles.toggleBtn}
              onClick={() => toggleLeftSidebar(false)}
            />
          </div>

          <AccountMenu
            Component={DefaultAccountMenu}
            accountSettingsUrl={buildAccountUrl('/settings')}
            onClickLogOut={onClickLogOut}
          />

          <div className={styles.sidebarContent}>
            <div className={styles.sidebarListings}>
              <NavLink
                end
                to={buildAccountUrl('/explore')}
                className={({ isActive }) =>
                  classnames(styles.sidebarListing, {
                    [styles.active]: isActive,
                  })
                }
                onClick={handleNavClick}>
                Explore
              </NavLink>
            </div>
            {pinnedExplorations.length > 0 && (
              <>
                <div className={styles.sectionTitle}>
                  <Icon name="Bookmark" size={16} />
                  Pinned
                </div>
                <div className={styles.sidebarListings}>
                  {pinnedExplorations.map((item, idx) => (
                    <NavLink
                      key={idx}
                      to={item.url}
                      className={({ isActive }) =>
                        classnames(styles.sidebarListing, {
                          [styles.active]:
                            isActive || item.explorationId === activeExplorationSourceId,
                        })
                      }
                      onClick={handleNavClick}>
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              </>
            )}
            {savedExplorations.length > 0 && (
              <>
                <div className={styles.sectionTitle}>
                  <Icon name="Exploration" size={16} />
                  Explorations
                </div>
                <div className={styles.sidebarListings}>
                  {savedExplorations.map((item, idx) => (
                    <NavLink
                      key={idx}
                      to={buildAccountUrl(buildExplorationUrl(item))}
                      title={item.name}
                      className={({ isActive }) =>
                        classnames(styles.sidebarListing, {
                          [styles.active]:
                            isActive || item.explorationId === activeExplorationSourceId,
                        })
                      }
                      onClick={handleNavClick}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </>
            )}
            {modelExplorations.length > 0 && (
              <>
                <div className={styles.sectionTitle}>
                  <Icon name="Model" size={16} />
                  Models
                </div>
                <div className={styles.sidebarListings}>
                  {modelExplorations.map((item, idx) => (
                    <NavLink
                      key={idx}
                      to={buildAccountUrl(buildExplorationUrl(item))}
                      title={item.name}
                      className={({ isActive }) =>
                        classnames(styles.sidebarListing, {
                          [styles.active]: isActive,
                        })
                      }
                      onClick={handleNavClick}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </>
            )}
          </div>

          <footer className={styles.sidebarFooter}>
            <Dropdown
              items={helpMenuItems}
              align="left"
              className={styles.sidebarListing}
              trigger={(isOpen, setIsOpen) => (
                <button className={styles.footerBtn} onClick={() => setIsOpen(!isOpen)}>
                  <Icon name="Settings" size={16} />
                  Settings
                </button>
              )}
            />
          </footer>
        </aside>
      ) : (
        <aside className={styles.collapsedSidebar}>
          <IconButton
            icon="ChevronRight"
            size="small"
            title="Expand sidebar"
            className={styles.toggleBtn}
            onClick={() => toggleLeftSidebar(true)}
          />
        </aside>
      )}
      {children}
    </LayoutContext.Provider>
  );
}
