import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './gradient-layout.module.scss';

interface CardsProps {
  children: React.ReactNode;
}

export const Cards = ({ children }: CardsProps) => {
  return <div className={styles.cards}>{children}</div>;
};

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

export const Card = ({ className, children }: CardProps) => {
  return <div className={classNames(styles.card, className)}>{children}</div>;
};

interface ErrorCardProps {
  children: React.ReactNode;
}

export const ErrorCard = ({ children }: ErrorCardProps) => {
  return (
    <div className={classNames(styles.card, styles.error)}>
      {children}
      <Link to="/" className={styles.errorFooter}>
        supersimple.io
      </Link>
    </div>
  );
};
