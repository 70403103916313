import { RefObject, useEffect } from 'react';
import { useEventListener } from 'usehooks-ts';

/**
 * Hook for making textarea elements auto-resize to fit their content.
 * Usage: pass in textarea ref.
 */
export const useTextAreaAutoResize = (textAreaRef: RefObject<HTMLTextAreaElement>) => {
  const resize = () => {
    if (textAreaRef.current !== null) {
      textAreaRef.current.style.height = '0';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    resize();
  });

  useEventListener('input', resize, textAreaRef);
};
