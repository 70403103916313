import { notNil } from '@/lib/utils';

import { Exploration } from '../types';

export const isModel = (labels: Exploration['labels']) =>
  typeof labels === 'object' && labels.type === 'model';

export const DefaultHiddenLabels = ['autodiscover.source.catalog'];

export function formatLabels(
  labels: Exploration['labels'],
  hiddenLabels: string[] = DefaultHiddenLabels,
): string[] {
  if (typeof labels !== 'object') {
    return [];
  }
  return Object.keys(labels)
    .map((key) => {
      if (hiddenLabels.includes(key)) {
        return null;
      }
      if (key === 'service') {
        return labels[key].replace(/_/g, ' ');
      }
      if (key === 'type') {
        return labels[key].replace(/_/g, ' ');
      }
      if (key === 'section') {
        return labels[key];
      }
      if (key.startsWith('autodiscover.')) {
        return `${key.replace('autodiscover.', '').replace(/\./g, ' ')} = ${labels[key]}`;
      }
      return `${key} = ${labels[key]}`;
    })
    .filter(notNil);
}
