import classNames from 'classnames';

import { Icon, IconProps } from '@/components/icon';

import {
  Token,
  OperationDescription,
  descriptionToString,
} from '../../components/pipeline/operation-description';

import styles from './pipeline-preview.module.scss';

interface OperationProps {
  icon: IconProps['name'];
  description: Token;
  onClick?: () => void;
  onChangeDisabled?: (e: MouseEvent, disabled: boolean) => void;
  disabled?: boolean;
}

export const Operation = ({ icon, description, onClick, disabled }: OperationProps) => {
  const clickable = !(disabled === true) && Boolean(onClick);
  return (
    <div
      className={classNames(styles.operation, {
        [styles.disabled]: disabled,
        [styles.clickable]: clickable,
      })}
      onClick={clickable ? onClick : undefined}>
      <div className={styles.operationIcon}>
        <Icon name={icon} size={16} />
      </div>
      <div className={styles.operationTitle} title={descriptionToString(description)}>
        <OperationDescription
          description={description}
          classNames={{ expression: styles.expression }}
        />
      </div>
    </div>
  );
};
