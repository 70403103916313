import { z } from 'zod';

export type OnboardingStep =
  | 'start'
  | 'legal'
  | 'welcomeSurvey'
  | 'setupCall'
  | 'connection'
  | 'complete';

export const onboardingStateSchema = z.object({
  hasOnboarded: z.boolean().catch(false),
  hasConsented: z.boolean().catch(false),
  hasConnection: z.boolean().catch(false),
  hasPassedSetupCallStep: z.boolean().catch(false),
  hasBookedSetupCall: z.boolean().catch(false),
  welcomeSurvey: z.record(z.string()).optional().catch(undefined),
});

export type OnboardingState = z.infer<typeof onboardingStateSchema>;
