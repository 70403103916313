import { MouseEventHandler } from 'react';

import classNames from 'classnames';

import { Icon, IconProps } from '@/components/icon';

import styles from './pipeline-preview.module.scss';

interface SavedPipelineHeaderProps {
  icon?: IconProps['name'];
  title: string;
  childCount?: number;
  color?: number;
  onClick?: MouseEventHandler;
}

export const SavedPipelineHeader = ({
  icon = 'Model',
  title,
  childCount,
  color,
  onClick,
}: SavedPipelineHeaderProps) => (
  <div
    className={classNames(styles.savedPipelineHeader, {
      [styles.clickable]: onClick !== undefined,
      [styles.parentPipelineColor1]: color === 1,
      [styles.parentPipelineColor2]: color === 2,
      [styles.parentPipelineColor3]: color === 3,
      [styles.parentPipelineColor4]: color === 4,
      [styles.parentPipelineColor5]: color === 5,
      [styles.parentPipelineColor6]: color === 6,
    })}
    onClick={onClick}>
    <div className={styles.operationIcon}>
      <Icon name={icon} size={16} />
    </div>
    <div className={styles.operationTitle} title={title}>
      &quot;{title}&quot;
    </div>
    {childCount !== undefined && childCount > 0 && (
      <div className={styles.childrenCount} title={`Prent pipeline used ${childCount} instances`}>
        ({childCount})
      </div>
    )}
  </div>
);
