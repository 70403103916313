export const RightArrowIcon = ({
  size = 12,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M5.31531 5.82812L-3.04779e-05 10.8281L-3.00407e-05 0.828125L5.31531 5.82812Z"
      fill={color}
    />
  </svg>
);
