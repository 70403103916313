import { useRef, useState, useEffect } from 'react';
import { DayPicker, ClassNames } from 'react-day-picker';
import { format } from 'date-fns';
import { useOnClickOutside } from 'usehooks-ts';

import { ensureLocalDateFormat, includesTime } from '@/lib/date';

import { Icon } from '../../icon';
import { TimeInput } from '../time-input';
import { Input } from '../input';
import { FormInputSize } from '../types';
import { CheckboxWithLabel } from '../checkbox';

import styles from './datetime-input.module.scss';
import 'react-day-picker/dist/style.css';
import './day-selector.scss';

interface SharedProps {
  value: string; // Format "yyyy-MM-dd"
  isRequired?: boolean;
  onChange: (value: string) => void;
}

interface PickerProps extends SharedProps {
  month?: Date;
  setMonth?: (month: Date) => void;
}

const DatePicker = (props: PickerProps) => {
  const { value, onChange, isRequired, month, setMonth } = props;
  const dateValue = value ? new Date(`${value}T00:00:00`) : new Date();
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 50;
  const handleSelect = (newValue: Date) => {
    const val = format(newValue, 'yyyy-MM-dd');
    return onChange(val);
  };

  return (
    <DayPicker
      mode="single"
      onSelect={(event) => event && handleSelect(event as Date)}
      selected={dateValue}
      month={month}
      onMonthChange={setMonth}
      weekStartsOn={1}
      required={isRequired}
      captionLayout="dropdown-buttons"
      fromYear={fromYear}
      toYear={currentYear}
      classNames={styles as unknown as ClassNames}
    />
  );
};

const DateTimePicker = (props: PickerProps) => {
  const { value, onChange, isRequired, month, setMonth } = props;
  const datePart = value.substring(0, 10);
  const timePart = value.substring(11);
  const includesTime = timePart !== '';

  return (
    <div>
      <DatePicker
        value={datePart}
        onChange={(newValue) => onChange(`${newValue}${includesTime ? `T${timePart}` : ''}`)}
        isRequired={isRequired}
        month={month}
        setMonth={setMonth}
      />
      <div className={styles.timeRow}>
        <CheckboxWithLabel
          checked={includesTime}
          onChange={() => onChange(includesTime ? datePart : `${datePart}T00:00`)}>
          Time
        </CheckboxWithLabel>
        {includesTime && (
          <TimeInput
            value={timePart}
            onChange={(event) =>
              onChange(
                `${datePart}${
                  event.currentTarget.value !== '' ? `T${event.currentTarget.value}` : ''
                }`,
              )
            }
            required={isRequired}
          />
        )}
      </div>
    </div>
  );
};

interface DateInputProps extends SharedProps {
  size?: FormInputSize;
}

export const DateTimeInput = (props: DateInputProps) => {
  const { value, isRequired, onChange, size } = props;
  const [isOpen, setIsOpen] = useState(false);
  const formattedValue = ensureLocalDateFormat(value);
  const [pickerValue, setPickerValue] = useState(formattedValue);
  const [pickerMonth, setPickerMonth] = useState(new Date(formattedValue));
  const overlayRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePickerCancel = (event: MouseEvent | TouchEvent | FocusEvent) => {
    if (event.target === inputRef.current) {
      return;
    }

    handleChange(value);
    setIsOpen(false);
  };

  const handleChange = (value: string) => {
    if (value !== '') {
      onChange(value);
      setPickerMonth(new Date(value));
    }
    setPickerValue(value);
  };

  const handleTimeChange = (value: string) => {
    setPickerValue(value);
    onChange(value);
  };

  useOnClickOutside(overlayRef, handlePickerCancel);

  useEffect(() => {
    if (formattedValue !== pickerValue) {
      setPickerValue(formattedValue);
    }
  }, [formattedValue, pickerValue]);

  return (
    <>
      <div className={styles.dateTimeInput}>
        <Input
          ref={inputRef}
          required={isRequired}
          type={includesTime(ensureLocalDateFormat(value)) ? 'datetime-local' : 'date'}
          value={pickerValue}
          size={size}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={() => onChange(pickerValue)}
          onFocus={(event) => {
            event.preventDefault();
            setIsOpen(true);
          }}
          onClick={(event) => {
            event.preventDefault();
            setIsOpen(true);
          }}
          onKeyDown={(event) => {
            // Prevent Firefox's native calendar icon from toggling the native picker
            if (event.code === 'Space') {
              event.preventDefault();
              setIsOpen(!isOpen);
            }
          }}
        />
        <Icon className={styles.icon} name="Calendar" size={16} />
      </div>
      {isOpen && (
        <div className={styles.overlay} ref={overlayRef}>
          <DateTimePicker
            value={pickerValue}
            month={pickerMonth}
            setMonth={setPickerMonth}
            onChange={handleTimeChange}
            isRequired={isRequired}
          />
        </div>
      )}
    </>
  );
};
