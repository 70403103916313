import { useEffect, useRef } from 'react';
import Cal, { getCalApi } from '@calcom/embed-react';

import { Step, StepSection } from '..';

import styles from './book-a-call-form.module.scss';

interface BookaCallFormProps {
  email?: string;
  notes?: string;
  successMessage?: string;
  onCallBooked?: () => void;
  children?: React.ReactNode;
}

export const BookaCallForm = ({
  email,
  notes,
  successMessage,
  onCallBooked,
  children,
}: BookaCallFormProps) => {
  const isCallBooked = useRef(false);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        theme: 'dark',
        hideEventTypeDetails: false,
        layout: 'month_view',
      });
      cal('on', {
        action: 'bookingSuccessful',
        callback: () => {
          // Use a flag since this callback can be called multiple times
          if (isCallBooked.current) {
            return;
          }
          isCallBooked.current = true;
          onCallBooked?.();
        },
      });
    })();
  }, [onCallBooked]);

  return (
    <Step
      subTitle="Now, Let’s get the data flowing"
      successMessage={successMessage}
      className={styles.panel}>
      <StepSection title="Book an Onboarding Call">
        <Cal
          calLink="supersimple/onboarding"
          style={{ width: '100%', height: '100%', overflow: 'scroll' }}
          config={{
            layout: 'month_view',
            email: email ?? '',
            notes: notes ?? '',
          }}
        />
        {children}
      </StepSection>
    </Step>
  );
};
