import { compact, first, uniq } from 'lodash';

import {
  getFunnelOperation,
  getFunnelPipelineStates,
  getSharedFields,
  getStepPipelineState,
  isEmptyStep,
} from '@/explore/edit-funnel/utils';
import { Exploration, FunnelStep, Pipeline } from '@/explore/types';
import { PipelineStateContext } from '@/explore/pipeline/state';

const getStepFields = (step: FunnelStep, exploration: Exploration, ctx: PipelineStateContext) =>
  isEmptyStep(step) ? [] : getStepPipelineState(step, exploration, ctx).fields;

/**
 * Funnel data will not be fetched if invalid.
 */
export const validateFunnel = (
  pipeline: Pipeline,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const operation = getFunnelOperation(pipeline);

  const { steps, groups } = operation.parameters;
  if (steps.length < 1) {
    return { isValid: false, error: 'Please make sure the funnel has at least one step.' };
  }
  if (steps.some(isEmptyStep)) {
    return { isValid: false, error: 'Please make sure all steps have a model selected.' };
  }
  const sharedFields = getSharedFields(getFunnelPipelineStates(steps, exploration, ctx));
  if (sharedFields.length === 0) {
    return { isValid: false, error: 'Please make sure all events share at least one property.' };
  }

  const stepValidations = steps.map((step) => validateStep(step, exploration, ctx));
  const failedStepValidation = stepValidations.find((validation) => !validation.isValid);
  if (failedStepValidation !== undefined) {
    return failedStepValidation;
  }

  if (
    compact(
      uniq(
        steps.map(
          (step) =>
            getStepFields(step, exploration, ctx).find((field) => field.key === step.sortKey)?.type,
        ),
      ),
    ).length !== 1
  ) {
    return {
      isValid: false,
      error: 'Please make sure sorting fields for all events are of the same type.',
    };
  }
  if (
    groups !== undefined &&
    !groups.every(
      (group) =>
        sharedFields.some((field) => field.key === group.key) ||
        steps.every((step) =>
          step.pipeline.operations.some(
            (operation) =>
              operation.operation === 'addRelatedColumn' &&
              operation.parameters.columns.some((column) => column.property.key === group.key),
          ),
        ),
    )
  ) {
    return {
      isValid: false,
      error: 'Please make sure the funnel grouping is valid.',
    };
  }
  if (
    groups !== undefined &&
    !groups.every((group) => !steps.some((step) => step.fields.includes(group.key)))
  ) {
    throw new Error('Cannot group by a counting field'); // should never happen
  }
  return { isValid: true };
};

const validateStep = (step: FunnelStep, exploration: Exploration, ctx: PipelineStateContext) => {
  const fields = getStepFields(step, exploration, ctx);
  if (!fields.some((field) => field.key === first(step.fields))) {
    return { isValid: false, error: `Field "${first(step.fields)}" not found` };
  }
  if (!fields.some((field) => field.key === step.sortKey)) {
    return { isValid: false, error: `Field "${step.sortKey}" not found` };
  }
  return { isValid: true };
};
