import {
  Exploration,
  Fields,
  Pipeline,
  PipelineOperation,
  RecordsCell,
  SqlCell,
  SqlOperation,
} from '../../types';
import { generatePipelineId, getOrGeneratePipelineId } from '../../pipeline/utils';
import { generateCellId } from '../../utils/exploration';

const isSqlOperation = (op: PipelineOperation): op is SqlOperation => op.operation === 'sql';

export const getSqlOperation = (pipeline: Pipeline): SqlOperation | undefined =>
  pipeline.operations.find(isSqlOperation);

export const setSqlOperation = (
  pipeline: Pipeline,
  sql: string,
  fields?: { key: string; type?: string }[],
): Pipeline => ({
  ...pipeline,
  operations: [{ operation: 'sql', parameters: { sql, fields } }],
});

// Adding instance of SQL cell differs from createExplorationCellInstanceAtIdx function from exploration/utils module.
// Just be aware that more generic version of this exists elsewhere.
export const createSqlCellInstance = (
  cell: SqlCell,
  sql: string,
  fields: Fields,
  exploration: Exploration,
): Exploration => {
  const pipelineId = getOrGeneratePipelineId(cell.pipeline);

  const updatedCell = {
    ...cell,
    pipeline: {
      pipelineId,
      ...setSqlOperation(
        cell.pipeline,
        sql,
        fields.map(({ key, type }) => ({ key, type })),
      ),
    },
  };

  const instanceCell: RecordsCell = {
    id: generateCellId(),
    kind: 'records',
    title: `Instance of ${cell.title}`,
    pipeline: {
      pipelineId: generatePipelineId(),
      parentId: pipelineId,
      operations: [],
    },
  };

  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: exploration.view.cells.flatMap((cell) =>
        cell.id === updatedCell.id ? [updatedCell, instanceCell] : cell,
      ),
    },
  };
};
