import { ReactNode, useState } from 'react';
import classNames from 'classnames';

import { Panel } from '@/components/panel';
import { Button } from '@/components/button';

import { InviteForm } from '../invite-form';

import styles from './step.module.scss';

interface StepProps {
  title?: string;
  subTitle?: string;
  successMessage?: string;
  className?: string;
  showInviteButton?: boolean;
  inviteText?: ReactNode;
  children: ReactNode;
}

export const Step = ({
  title,
  subTitle,
  successMessage,
  className,
  showInviteButton,
  inviteText,
  children,
}: StepProps) => {
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  return (
    <div>
      {successMessage !== undefined ? (
        <div className={styles.success}>
          <h1>{successMessage}</h1>
        </div>
      ) : null}
      {showInviteDialog ? (
        <InviteForm onClose={() => setShowInviteDialog(false)} infoText={inviteText} />
      ) : (
        <Panel className={classNames(styles.panel, className)}>
          <header className={styles.header}>
            <div>
              {title !== undefined ? <h1 className={styles.heading}>{title}</h1> : null}
              {subTitle !== undefined ? <h3 className={styles.subHeading}>{subTitle}</h3> : null}
            </div>
            {showInviteButton === true && (
              <Button variant="outlined" size="small" onClick={() => setShowInviteDialog(true)}>
                Invite a team member
              </Button>
            )}
          </header>
          <div className={styles.content}>{children}</div>
        </Panel>
      )}
    </div>
  );
};
