import { IconProps } from './types';

export const Database = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.333 3.014c0-.568-.346-1.003-.725-1.302-.387-.305-.906-.55-1.488-.744-1.17-.39-2.745-.62-4.453-.62-1.709 0-3.283.23-4.454.62-.582.194-1.1.44-1.487.744-.38.3-.726.734-.726 1.302v9.334c0 .566.342 1.001.721 1.301.386.306.904.552 1.485.746 1.168.39 2.743.62 4.46.62 1.719 0 3.293-.23 4.462-.62.58-.194 1.098-.44 1.484-.745.38-.301.721-.736.721-1.302V3.014Zm-12 0 .002.01a.197.197 0 0 0 .022.045.824.824 0 0 0 .194.2c.22.174.58.36 1.084.527 1.001.334 2.427.552 4.032.552s3.03-.218 4.031-.552c.505-.168.864-.353 1.085-.527a.824.824 0 0 0 .194-.2.2.2 0 0 0 .021-.044l.002-.01-.002-.01a.2.2 0 0 0-.021-.045.824.824 0 0 0-.194-.2c-.22-.174-.58-.36-1.085-.527-1-.334-2.426-.552-4.031-.552-1.605 0-3.031.218-4.032.552-.504.168-.864.353-1.084.526a.824.824 0 0 0-.194.201.197.197 0 0 0-.022.044l-.002.01Zm0 1.685V7.68s0 .005.003.012a.2.2 0 0 0 .021.045.817.817 0 0 0 .192.2c.219.173.576.358 1.079.526.998.333 2.423.55 4.039.55 1.615 0 3.04-.217 4.039-.55.502-.168.86-.353 1.078-.526a.818.818 0 0 0 .192-.2.197.197 0 0 0 .022-.045.05.05 0 0 0 .002-.012V4.699a6.353 6.353 0 0 1-.88.362c-1.17.39-2.745.62-4.453.62-1.709 0-3.283-.23-4.454-.62a6.356 6.356 0 0 1-.88-.362ZM13 9.369c-.265.136-.56.255-.872.36-1.169.39-2.743.619-4.461.619-1.718 0-3.293-.23-4.461-.62a6.316 6.316 0 0 1-.873-.36v2.98l.003.012a.2.2 0 0 0 .021.044.817.817 0 0 0 .192.2c.219.174.576.359 1.079.526.998.333 2.423.551 4.039.551 1.615 0 3.04-.217 4.039-.55.502-.168.86-.354 1.078-.527a.818.818 0 0 0 .192-.2.197.197 0 0 0 .022-.044l.002-.012v-2.98Z"
      fill={color}
    />
  </svg>
);
