import classNames from 'classnames';
import { createRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { first } from 'lodash';
import { useFuzzySearchList } from '@nozbe/microfuzz/react';

import { NavigableListItemOutput, useNavigableList } from '@/lib/hooks/use-navigable-list';
import { useClientRect } from '@/lib/hooks/use-client-rect';

import { FormInputSize, SelectOptions, isOptionGroup, SelectOption, Option } from '../types';
import { Input } from '../input';
import { Icon } from '../../icon';
import { Overlay } from '../../overlay';

import styles from './search-input.module.scss';

type ListData = {
  title: string;
};

type OptionData = {
  option: SelectOption;
  isSelected: boolean;
  isDisabled: boolean;
  icon?: React.ReactNode;
};

type ListItemData = ListData | OptionData;

const optionToListItemData = (option: Option, isSelected: boolean): OptionData => {
  return {
    option,
    isSelected,
    isDisabled: option.disabled === true,
    icon: option.icon !== undefined ? <Icon size={16} name={option.icon} /> : undefined,
  };
};

export interface SearchInputProps {
  options: SelectOptions | Readonly<SelectOptions>;
  value?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  className?: classNames.Argument;
  size?: FormInputSize;
  disabled?: boolean;
}

export const SearchInput = (props: SearchInputProps) => {
  const { options, value, autoFocus, size = 'small', disabled = false } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const triggerRef = createRef<HTMLButtonElement>();
  const triggerRect = useClientRect(triggerRef, [isOpen]);

  const flatOptions = options.flatMap((option) =>
    isOptionGroup(option) ? option.options : option,
  );
  const selectedOption = flatOptions.find((option) => option.value === value);
  const selectedOptionParent = options.find(
    (option) => isOptionGroup(option) && option.options.some((o) => o.value === value),
  );
  const selectedOptionLabel =
    selectedOptionParent !== undefined
      ? `${selectedOption?.label} (${selectedOptionParent.label})`
      : (selectedOption?.label ?? first(flatOptions)?.label ?? '');

  const closePanel = useCallback(() => {
    setIsOpen(false);
    triggerRef.current?.focus();
  }, [triggerRef]);

  useEffect(() => {
    if (autoFocus === true) {
      triggerRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(styles.searchInput, props.className, {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
      })}>
      <SearchInputTrigger
        label={selectedOptionLabel}
        onOpenPanel={(searchTerm) => {
          setSearchTerm(searchTerm ?? '');
          setIsOpen(!isOpen);
        }}
        ref={triggerRef}
        disabled={disabled}
      />
      {isOpen && (
        <Overlay>
          <>
            <div className={styles.backdrop} onClick={() => setIsOpen(false)} />
            <SearchInputPanel
              searchTerm={searchTerm}
              options={options}
              value={value}
              position={{
                top: triggerRect?.top ?? 0,
                left: triggerRect?.left ?? 0,
              }}
              width={triggerRect?.width ?? 0}
              onChangeSearchTerm={setSearchTerm}
              onChange={(value) => {
                props.onChange(value);
                closePanel();
              }}
              onClose={closePanel}
              size={size}
            />
          </>
        </Overlay>
      )}
    </div>
  );
};

type SearchInputTriggerProps = {
  label: string;
  disabled?: boolean;
  onOpenPanel(searchTerm?: string): void;
};

const SearchInputTrigger = forwardRef(function SearchInputTrigger(
  props: SearchInputTriggerProps,
  ref?: React.ForwardedRef<HTMLButtonElement>,
) {
  const { label, disabled = false, onOpenPanel } = props;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        onOpenPanel();
      }
      if (e.key.length === 1) {
        e.preventDefault();
        onOpenPanel(e.key);
      }
    },
    [onOpenPanel],
  );

  return (
    <button
      className={styles.trigger}
      type="button"
      onClick={() => onOpenPanel()}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      ref={ref}>
      <span className={styles.label}>{label}</span>
      <div className={styles.chevron}>
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          aria-hidden="true">
          <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
        </svg>
      </div>
    </button>
  );
});

interface SearchInputPanelProps {
  options: SelectOptions | Readonly<SelectOptions>;
  searchTerm: string;
  value?: string;
  position: { top: number; left: number };
  width: number;
  onChangeSearchTerm: (value: string) => void;
  onChange: (value: string) => void;
  onClose?: () => void;
  size?: FormInputSize;
}

const SearchInputPanel = (props: SearchInputPanelProps) => {
  const {
    options,
    value,
    position,
    width,
    searchTerm,
    onChangeSearchTerm,
    onChange,
    onClose,
    size,
  } = props;
  const flattenedOptions = options.flatMap((option) =>
    isOptionGroup(option) ? option.options : option,
  );

  const inputRef = createRef<HTMLInputElement>();
  const listRef = createRef<HTMLDivElement>();

  const listRect = useClientRect(listRef, [searchTerm]);

  const filteredValues = useFuzzySearchList<Option, string>({
    strategy: 'smart',
    list: flattenedOptions,
    queryText: searchTerm.trim(),
    getText: (option) => [option.label, option.value],
    mapResultItem: ({ item }) => item.value,
  });

  const isMatch = (option: SelectOption) =>
    isOptionGroup(option) || filteredValues.includes(option.value);

  const selectedValueIndex = flattenedOptions
    .filter(isMatch)
    .findIndex((option) => option.value === value);

  const { list, focusIndex, setFocusIndex, leafCount, focusedItem } =
    useNavigableList<ListItemData>({
      items: options.filter(isMatch).map((option) => {
        if (isOptionGroup(option)) {
          return {
            data: { title: option.label },
            children: option.options.filter(isMatch).map((option) => ({
              isFocusable: true,
              data: optionToListItemData(option, option.value === value),
              onClick: () => handleSelectItem(option),
            })),
          };
        }
        return {
          isFocusable: true,
          data: optionToListItemData(option, option.value === value),
          onClick: () => handleSelectItem(option),
        };
      }),
      initialFocusIndex: selectedValueIndex !== -1 ? selectedValueIndex : 0,
      listContainerRef: listRef,
    });

  const handleSelectItem = useCallback(
    (option: SelectOption) => {
      if (!isOptionGroup(option)) {
        onChange(option.value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement === null) {
      return;
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        onClose?.();
      }
      if (leafCount === 0) {
        return;
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusIndex(focusIndex === null ? 0 : focusIndex + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusIndex(focusIndex === null ? -1 : focusIndex - 1);
      } else if ((e.key === 'Enter' || e.key === 'Tab') && focusedItem !== null) {
        e.preventDefault();
        handleSelectItem(focusedItem?.getData<OptionData>().option);
      }
    };
    inputElement.addEventListener('keydown', handleKeyDown);
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusIndex, focusedItem, handleSelectItem, leafCount, inputRef, setFocusIndex, onClose]);

  useEffect(() => {
    setFocusIndex(selectedValueIndex !== -1 ? selectedValueIndex : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafCount]);

  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const itemCount = flattenedOptions.filter(isMatch).length;

  direction === 'down' &&
    listRect !== null &&
    listRect.top + listRect.height > window.innerHeight &&
    setDirection('up');

  return (
    <div
      className={classNames(styles.panel, {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
        [styles.openAbove]: direction === 'up',
      })}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: `${width}px`,
      }}>
      <Input
        size={size}
        value={searchTerm}
        placeholder="Search..."
        onChange={(e) => onChangeSearchTerm(e.target.value)}
        ref={inputRef}
        autoFocus
      />
      <div ref={listRef} className={styles.items} role="listbox">
        {itemCount === 0 ? (
          <div className={styles.empty} role="listbox">
            No matches found
          </div>
        ) : (
          <ItemList items={list} />
        )}
      </div>
    </div>
  );
};

type ItemListProps = {
  items: (NavigableListItemOutput<ListItemData> | null)[];
};

const ItemList = (props: ItemListProps) => {
  const { items } = props;

  const focusedItemIndex = items.findIndex((item) => item !== null && item.getIsFocused());
  const focusedItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    focusedItemRef.current?.scrollIntoView({ block: 'nearest' });
  }, [focusedItemIndex]);

  return (
    <>
      {items.map((item, idx) => {
        if (item === null) {
          return null;
        }
        return <Item key={idx} item={item} ref={item.getIsFocused() ? focusedItemRef : null} />;
      })}
    </>
  );
};

type ItemProps = {
  item: NavigableListItemOutput<ListItemData>;
  ref?: React.Ref<HTMLButtonElement>;
};

const Item = forwardRef(function Item(props: ItemProps, ref: React.Ref<HTMLButtonElement>) {
  const { item } = props;

  const data = item.getData<ListItemData>();

  if ('title' in data) {
    if (item.getChildren().length === 0) {
      return null;
    }
    return (
      <>
        <div className={styles.title}>{data.title}</div>
        <ItemList items={item.getChildren()} />
      </>
    );
  }

  return (
    <button
      type="button"
      role="option"
      aria-selected={data.isSelected}
      title={'title' in data.option ? data.option.title : data.option.label}
      className={classNames(styles.item, {
        [styles.selected]: data.isSelected,
        [styles.focused]: item.getIsFocused(),
      })}
      disabled={data.isDisabled}
      ref={ref}
      tabIndex={-1}
      onMouseOver={() => item.setIsFocused(true)}
      onClick={item.onClick}>
      {data.icon !== undefined && data.icon}
      <span className={styles.label}>{data.option.label}</span>
      {data.isSelected && <Icon size={16} name="Check" className={styles.check} />}
    </button>
  );
});
