import { EditorState, Extension, StateEffect, StateField } from '@codemirror/state';
import { EditorView } from '@codemirror/view';

export type PenguinoEditorState = {
  fields: { key: string; name: string; type: string }[];
  variables: { key: string; value: string | number | boolean; type: string | null }[];
  requiredType?: string;
};

export const penguinoStateField = StateField.define<PenguinoEditorState>({
  create: () => ({ fields: [], variables: [] }),
  update: (value, transaction) => {
    for (const effect of transaction.effects) {
      if (effect.is(updatePenguinoStateEffect)) {
        return effect.value;
      }
    }
    return value;
  },
});

export const penguinoStateProvider = (): Extension => [penguinoStateField];

export const updatePenguinoStateEffect = StateEffect.define<PenguinoEditorState>();

export const updatePenguinoState = (view: EditorView, newData: PenguinoEditorState) =>
  view.dispatch({
    effects: updatePenguinoStateEffect.of(newData),
  });

export const getPenguinoState = (state: EditorState): PenguinoEditorState =>
  state.field(penguinoStateField);
