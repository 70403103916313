import { pick } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';

import { Json, Literal } from '../types';

const SETTINGS_WHITELIST = ['tip.modelKinds.disabledAt'] as const;
export type LocalSettingKey = (typeof SETTINGS_WHITELIST)[number];

export const useLocalSetting = <T extends Literal | Json>(key: string | null, defaultValue: T) => {
  const [settings, setSettings] = useLocalStorage<{ [key: string]: Literal | Json }>(
    'localSettings',
    {},
  );

  if (key === null) {
    return [defaultValue, () => {}] as const;
  }

  const value = settings[key] ?? defaultValue;
  const setValue = (newValue: T) => {
    setSettings(pick({ ...settings, [key]: newValue }, SETTINGS_WHITELIST));
  };

  return [value, setValue] as const;
};
