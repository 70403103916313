export const RightArrowOutlinedIcon = ({
  size = 12,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path d="M5 2.20711L10.7929 8L5 13.7929L5 2.20711Z" stroke={color} />
  </svg>
);
