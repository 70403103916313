import { IconProps } from './types';

export const Duplicate = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <g fill={color} fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M7.334 6.667a.667.667 0 0 0-.667.666v6c0 .368.298.667.667.667h6a.667.667 0 0 0 .666-.667v-6a.667.667 0 0 0-.666-.666h-6Zm-2 .666a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-6Z" />
      <path d="M2.667 2A.667.667 0 0 0 2 2.667v6a.667.667 0 0 0 .667.666h.667a.667.667 0 1 1 0 1.334h-.667a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v.666a.667.667 0 0 1-1.333 0v-.666A.667.667 0 0 0 8.667 2h-6Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
