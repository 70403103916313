import { useState } from 'react';

import { Section } from '../common';
import { UserListItem, UserList } from './list';
import { CreateForm } from './create-form';
import { EditForm } from './edit-form';

interface UsersSectionProps {
  accountId: string;
  currentUserId: string;
  canEdit: boolean;
  users: UserListItem[];
}

export const UsersSection = (props: UsersSectionProps) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editUser, setEditUser] = useState<UserListItem | null>(null);

  return (
    <Section
      title="Users"
      headerActions={
        props.canEdit
          ? [{ label: 'Add user', iconName: 'Plus', onClick: () => setShowCreateForm(true) }]
          : undefined
      }>
      {showCreateForm && (
        <CreateForm accountId={props.accountId} onClose={() => setShowCreateForm(false)} />
      )}
      {editUser !== null && (
        <EditForm
          accountId={props.accountId}
          enableDelete={props.currentUserId !== editUser.userId}
          user={editUser}
          onClose={() => setEditUser(null)}
        />
      )}
      <UserList users={props.users} canEdit={props.canEdit} onEdit={setEditUser} />
    </Section>
  );
};
