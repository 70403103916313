import classNames from 'classnames';
import Rive, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

import styles from './penguin.module.scss';

interface PenguinProps {
  className?: string;
}

export function Penguin(props: PenguinProps) {
  return (
    <div className={classNames(styles.penguin, props.className)}>
      <Rive
        src="/penguin-popup.riv"
        layout={new Layout({ fit: Fit.FitWidth, alignment: Alignment.TopCenter })}
      />
    </div>
  );
}
