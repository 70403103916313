import classNames from 'classnames';

import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { useExplorationContext } from '../exploration-context';
import { ExplorationCellContextProvider } from '../exploration-cell-context';
import { CellSidebar } from './cell-sidebar';
import { ExplorationSidebar } from './exploration-sidebar';

import styles from './sidebar.module.scss';

interface SidebarProps {
  onAddSection: () => void;
}

export const Sidebar = (props: SidebarProps) => {
  const { onAddSection } = props;

  const { exploration, selectedCellIndex, isEditorOpen } = useExplorationContext();

  const selectedCell =
    selectedCellIndex === null ? null : exploration.view.cells[selectedCellIndex];

  return (
    <div
      className={classNames(styles.sidebarContainer, {
        [styles.editorOpen]: isEditorOpen,
      })}>
      <div className={styles.sidebar}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          {selectedCellIndex !== null && selectedCell !== null ? (
            <ExplorationCellContextProvider
              cell={selectedCell}
              cellIndex={selectedCellIndex}
              isCollapsible>
              <CellSidebar />
            </ExplorationCellContextProvider>
          ) : (
            <ExplorationSidebar onAddSection={onAddSection} />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};
