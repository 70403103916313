import { Tooltip, showTooltip, EditorView } from '@codemirror/view';
import { EditorState, StateField, StateEffect } from '@codemirror/state';
import { syntaxTree } from '@codemirror/language';

import { notNil } from '@/lib/utils';

import { PenguinoTokenTree } from './parser';
import { renderSignature } from './signature';
import { getFunctionDefinitionByTokenAtPosition } from './traversal';

const hideArgumentHintsEffect = StateEffect.define<void>();

export const argumentHints = [
  StateField.define<readonly Tooltip[]>({
    create: getCursorTooltips,

    update(tooltips, tr) {
      for (const effect of tr.effects) {
        if (effect.is(hideArgumentHintsEffect)) {
          return [];
        }
      }

      if (!tr.docChanged && !tr.selection) {
        return tooltips;
      }

      return getCursorTooltips(tr.state);
    },

    provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
  }),

  EditorView.focusChangeEffect.of((_, focused) => {
    if (!focused) {
      return hideArgumentHintsEffect.of();
    }
    return null;
  }),
];

function getCursorTooltips(state: EditorState): readonly Tooltip[] {
  return state.selection.ranges
    .filter((range) => range.empty)
    .map((range) => {
      const line = state.doc.lineAt(range.head);
      const pos = range.head - line.from;

      const tokens = syntaxTree(state)
        .children.filter(PenguinoTokenTree.isPenguinoTokenTree)
        .map((item) => item.token);

      if (tokens.length === syntaxTree(state).children.length) {
        const result = getFunctionDefinitionByTokenAtPosition(tokens, pos);

        if (result === undefined) {
          return;
        }

        return {
          pos: 0,
          above: true,
          arrow: false,
          create: () => renderSignature(result.functionDefinition, result.argumentAt),
        };
      }
    })
    .filter(notNil);
}
