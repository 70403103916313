import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { first, isNil, last } from 'lodash';

import { useExplorationsSearchQuery } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import { ErrorBoundary } from '@/lib/error';
import { useNavigableList } from '@/lib/hooks/use-navigable-list';

import { Icon } from '../../components/icon';
import { Loader } from '../../components/loader';
import { Exploration, Model } from '../types';
import { AISuggestions } from './ai-suggestions';
import { ExplorationList } from './exploration-list';
import { convertExplorationsArrayTypes } from '../input';
import { isModel } from './utils';
import { ListItemData } from '.';
import { getCellBaseModel, getExplorationType, getUnparameterizedExplorations } from '../utils';

import styles from './exploration-search.module.scss';

interface SearchResultProps {
  explorations: Exploration[];
  models: Model[];
  searchTerm: string;
  exploration?: Exploration;
  onClickExploration?: (exploration: Exploration) => void;
  setFocusIndexRef?: React.MutableRefObject<((index: number) => void) | undefined>;
  onBlur?: () => void;
}

export const SearchResults = (props: SearchResultProps) => {
  const { explorations, models, searchTerm, exploration, onClickExploration } = props;

  const trackEvent = useTrackEvent();
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const account = useSelectedAccount();

  // Avoid fetching on first letter by using an empty initial value
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const {
    data,
    loading: queryLoading,
    error,
  } = useExplorationsSearchQuery({
    variables: { accountId: account.accountId, search: debouncedSearchTerm },
    skip: debouncedSearchTerm.length === 0,
  });

  const loading = queryLoading || debouncedSearchTerm !== searchTerm;

  const instantResults = useMemo(() => {
    const filteredExplorations = explorations.filter((exploration) =>
      exploration.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
    );

    return filteredExplorations;
  }, [explorations, searchTerm]);

  const semanticResults = getUnparameterizedExplorations(
    convertExplorationsArrayTypes(data?.account?.explorations ?? []),
  ).filter(
    (e) =>
      !instantResults.some(
        (e2) =>
          e.explorationId === e2.explorationId ||
          (getExplorationType(e) === 'model' &&
            getExplorationType(e2) === 'model' &&
            getCellBaseModel(first(e.view.cells), e) ===
              getCellBaseModel(first(e2.view.cells), e2)),
      ),
  );

  const { list: resultList, setFocusIndex } = useNavigableList<ListItemData>({
    items: [
      instantResults.length === 0
        ? null
        : {
            children: instantResults.map((exploration) => ({
              isFocusable: true,
              data: { exploration },
              onClick: (event) => {
                if (onClickExploration) {
                  event.preventDefault();
                  onClickExploration(exploration);
                }
              },
            })),
          },
      semanticResults.length === 0
        ? null
        : {
            children: semanticResults.map((exploration) => ({
              isFocusable: true,
              data: { exploration },
              onClick: (event) => {
                if (onClickExploration) {
                  event.preventDefault();
                  onClickExploration(exploration);
                }
              },
            })),
          },
    ],
    listContainerRef: scrollContainerRef,
    onFocusMovedOutside: props.onBlur,
  });

  useEffect(() => {
    // Allow parent component to interact with list focus
    if (props.setFocusIndexRef === undefined) {
      return;
    }
    props.setFocusIndexRef.current = setFocusIndex;
    return () => {
      props.setFocusIndexRef!.current = undefined;
    };
  }, [props.setFocusIndexRef, setFocusIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      const modelCount = instantResults.filter((e) => isModel(e.labels)).length;
      trackEvent('Exploration Search', {
        searchTerm: searchTerm,
        instantCount: instantResults.length,
        modelCount,
        explorationCount: instantResults.length - modelCount,
      });
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (loading || data === undefined) {
      return;
    }
    const modelCount = semanticResults.filter((e) => isModel(e.labels)).length;
    trackEvent('Exploration Search Results', {
      searchTerm: debouncedSearchTerm,
      instantCount: instantResults.length,
      resultCount: semanticResults.length,
      modelCount,
      explorationCount: semanticResults.length - modelCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.account?.explorations]);

  const instantResultsListItem = first(resultList);
  const semanticResultsListItem = last(resultList);

  return (
    <>
      <div
        className={classNames(styles.searchResults, { [styles.scrolled]: isScrolled })}
        ref={scrollContainerRef}>
        {!isNil(instantResultsListItem) && (
          <ExplorationList listItems={instantResultsListItem.getChildren()} />
        )}
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {!loading && !isNil(semanticResultsListItem) && (
          <ExplorationList listItems={semanticResultsListItem.getChildren()} />
        )}
        {!loading && error && (
          <div className={styles.error}>
            <Icon name="CloudDrizzle" size={16} />
            Semantic search failed. Only exact matches are displayed.
          </div>
        )}
      </div>
      <ErrorBoundary>
        <AISuggestions
          models={models}
          searchTerm={debouncedSearchTerm}
          exploration={exploration}
          onClickSuggestion={onClickExploration}
        />
      </ErrorBoundary>
    </>
  );
};
