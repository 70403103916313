export const RelatedDataIcon = ({
  size = 12,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99959 13.0003C8.99959 14.289 10.0443 15.3337 11.3329 15.3337C12.6216 15.3337 13.6663 14.289 13.6663 13.0003C13.6663 11.7117 12.6216 10.667 11.3329 10.667C10.0443 10.667 8.99959 11.7117 8.99959 13.0003Z"
      fill={color}
    />
    <path
      d="M0.333252 3.00033C0.333252 4.28899 1.37792 5.33366 2.66659 5.33366C3.95525 5.33366 4.99992 4.28899 4.99992 3.00033C4.99992 1.71166 3.95525 0.666992 2.66659 0.666992C1.37792 0.666992 0.333252 1.71166 0.333252 3.00033Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4454 2.33854L3.55575 2.33854V3.67187L10.4454 3.67188V2.33854Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4454 7.32813L3.55575 7.32812V8.66146L10.4454 8.66146V7.32813Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4454 12.3177L3.55575 12.3177V13.651L10.4454 13.651V12.3177Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4454 3.66667C11.4552 3.66667 12.2738 4.48529 12.2738 5.49512C12.2738 6.50494 11.4552 7.32357 10.4454 7.32357V8.6569C12.1916 8.6569 13.6072 7.24132 13.6072 5.49512C13.6072 3.74891 12.1916 2.33333 10.4454 2.33333V3.66667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55575 12.3138C2.54592 12.3138 1.72729 11.4952 1.72729 10.4854C1.72729 9.47553 2.54592 8.6569 3.55574 8.6569V7.32357C1.80954 7.32357 0.393961 8.73915 0.393961 10.4854C0.393961 12.2316 1.80954 13.6471 3.55575 13.6471V12.3138Z"
      fill={color}
    />
  </svg>
);
