import {
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

import { importExplorationCellsAfter } from '../exploration/utils';
import {
  Aggregation,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  Exploration,
  FilterOperation,
  GroupAggregateOperation,
  Pipeline,
  PipelineOperation,
  RecordsCell,
  RelationAggregateOperation,
  TimeAggregationPeriod,
} from '../types';
import { generateCellId } from './exploration';
import { flattenPipeline, generatePipelineId } from '../pipeline/utils';

type Property = { key: string; pk?: boolean; type: string; precision?: TimeAggregationPeriod };

type DrilldownAwareProperty<T extends Property> = T & { isAggregated: boolean };

const extractAggregationKeys = (aggregations: Aggregation[]) =>
  aggregations
    .filter((aggregation) => aggregation.type !== 'custom')
    .map((aggregation) => aggregation.property.key);

const getAggregatedPropertiesFromOperation = (operation: DereferencedPipelineOperation) =>
  operation.operation === 'groupAggregate' || operation.operation === 'relationAggregate'
    ? extractAggregationKeys(operation.parameters.aggregations)
    : [];

const getAggregatedPropertiesFromPipeline = (pipeline: DereferencedPipeline) => {
  return new Set(pipeline.operations.flatMap(getAggregatedPropertiesFromOperation));
};

export const detectAggregatedProperties = <T extends Property>(
  properties: T[],
  pipeline: DereferencedPipeline,
): DrilldownAwareProperty<T>[] => {
  const aggregatedProperties = getAggregatedPropertiesFromPipeline(pipeline);
  return properties.map((property) => ({
    isAggregated: aggregatedProperties.has(property.key),
    ...property,
  }));
};

export const addDrillDownCellToExploration = (input: {
  record: Record<string, unknown>;
  properties: Property[];
  property: Property;
  exploration: Exploration;
  cellId: string;
  timezone: string;
}): Exploration => {
  const { record, properties, property, exploration, cellId, timezone } = input;
  const cell = exploration.view.cells.find((cell) => cell.id === cellId);
  if (cell === undefined || cell.kind !== 'records') {
    return exploration;
  }

  const operation = findOperationIntroducingAggregatingProperty(
    flattenPipeline(cell.pipeline, exploration),
    property.key,
  );
  if (operation === undefined) {
    return exploration;
  }

  const drillDownOperations = drillDownFromAggregatingOperation(
    operation,
    record,
    properties,
    timezone,
  );
  const drillDownCell = createDrilldownCell(cell.title ?? '', {
    ...cell.pipeline,
    operations: [
      ...cell.pipeline.operations.slice(0, cell.pipeline.operations.indexOf(operation)),
      ...drillDownOperations,
    ],
  });

  return importExplorationCellsAfter(exploration, [drillDownCell], cellId);
};

/**
 * Given the aggregation field key of a property, figure out which operation in the pipeline introduces the aggregation.
 */
function findOperationIntroducingAggregatingProperty(
  pipeline: Pipeline,
  key: string,
): GroupAggregateOperation | RelationAggregateOperation | undefined {
  const operation = pipeline.operations
    .filter(isAggregatingOperation)
    .findLast((operation) =>
      operation.parameters.aggregations.some((aggregation) => aggregation.property.key === key),
    );

  if (operation === undefined) {
    return;
  }

  return operation;
}

function isAggregatingOperation(
  operation: PipelineOperation,
): operation is GroupAggregateOperation | RelationAggregateOperation {
  return operation.operation === 'groupAggregate' || operation.operation === 'relationAggregate';
}

/**
 * Given aggregating operation, return its "reverse" operations that builds the query to drill down to the origins of
 * that aggregation.
 */
function drillDownFromAggregatingOperation(
  operation: GroupAggregateOperation | RelationAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
  timezone: string,
) {
  if (operation.operation === 'groupAggregate') {
    return drillDownFromGroupAggregate(operation, record, properties, timezone);
  }
  if (operation.operation === 'relationAggregate') {
    return drillDownFromRelationAggregate(operation, record, properties);
  }
  throw new Error(`Expected groupAggregate or relationAggregate operation`);
}

function filterOperationFromDateRange(
  startDate: Date,
  endDate: Date,
  key: string,
): FilterOperation {
  return {
    operation: 'filter',
    parameters: {
      operator: 'and',
      operands: [
        {
          key,
          operator: '>=',
          value: startDate.toISOString(),
        },
        {
          key,
          operator: '<=',
          value: endDate.toISOString(),
        },
      ],
    },
  };
}

export function dateRangeFromPrecision(date: Date, precision: TimeAggregationPeriod) {
  switch (precision) {
    case 'hour':
      return { startDate: startOfHour(date), endDate: endOfHour(date) };
    case 'day':
      return { startDate: startOfDay(date), endDate: endOfDay(date) };
    case 'week':
      return { startDate: startOfWeek(date), endDate: endOfWeek(date) };
    case 'month':
      return { startDate: startOfMonth(date), endDate: endOfMonth(date) };
    case 'quarter':
      return { startDate: startOfQuarter(date), endDate: endOfQuarter(date) };
    case 'year':
      return { startDate: startOfYear(date), endDate: endOfYear(date) };
    default:
      throw new Error(`Unsupported precision: ${precision}`);
  }
}

function drillDownFromGroupAggregate(
  operation: GroupAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
  timezone: string,
): PipelineOperation[] {
  return operation.parameters.groups.map((group) => {
    const value = record[group.key];
    const property = properties.find((p) => p.key === group.key);
    if (property?.type === 'Date' && property.precision !== undefined) {
      const date = new Date(String(value));
      const { startDate, endDate } = dateRangeFromPrecision(date, property.precision);
      return filterOperationFromDateRange(
        fromZonedTime(startDate, timezone),
        fromZonedTime(endDate, timezone),
        group.key,
      );
    }

    if (value === null) {
      return {
        operation: 'filter',
        parameters: {
          key: group.key,
          operator: 'isnull',
        },
      };
    }

    return {
      operation: 'filter',
      parameters: {
        key: group.key,
        operator: '==',
        value: String(value),
      },
    };
  });
}

function drillDownFromRelationAggregate(
  operation: RelationAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
): PipelineOperation[] {
  return 'relation' in operation.parameters
    ? [
        ...properties
          .filter((p) => p.pk)
          .map(
            (p) =>
              ({
                operation: 'filter',
                parameters: {
                  key: p.key,
                  operator: '==',
                  value: record[p.key] as string,
                },
              }) as const,
          ),
        {
          operation: 'switchToRelation',
          parameters: { relation: operation.parameters.relation },
        },
        ...(operation.parameters.filters ?? []).map(
          (filter) =>
            ({
              operation: 'filter',
              parameters: filter.parameters,
            }) as const,
        ),
      ]
    : [];
}

const createDrilldownCell = (title: string, pipeline: Pipeline): RecordsCell => ({
  id: generateCellId(),
  kind: 'records',
  title: 'Drilldown from ' + title,
  pipeline: {
    ...pipeline,
    pipelineId: generatePipelineId(),
  },
});
