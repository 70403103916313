export const notNil = <T>(input: T | null | undefined): input is T =>
  input !== null && input !== undefined;

export const isNil = <T>(input: T | null | undefined): input is null | undefined =>
  input === null || input === undefined;

export function unlessNil<Input, Result>(
  value: Input | null | undefined,
  fn: (value: Input) => Result,
): Result | undefined {
  if (value === null || value === undefined) {
    return;
  }

  return fn(value);
}

export const generateUUID = () => window.crypto.randomUUID();
