import classnames from 'classnames';

import { Icon, IconProps } from '../icon';

import styles from './alert.module.scss';

export const Alert = ({
  children,
  title,
  kind,
  iconName = 'Info',
}: {
  children: React.ReactNode;
  title: string;
  kind: 'success' | 'error';
  iconName?: IconProps['name'];
}) => {
  return (
    <div
      className={classnames(styles.alert, {
        [styles.kindSuccess]: kind === 'success',
        [styles.kindError]: kind === 'error',
      })}>
      <div>
        <Icon name={iconName} className={styles.icon} size={18} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.caption}>{children}</div>
      </div>
    </div>
  );
};
