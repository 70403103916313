export const AddColumnIcon = ({
  size = 12,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.13086H1.5V11.1309H3.5V1.13086ZM1.5 0.130859C0.947715 0.130859 0.5 0.578574 0.5 1.13086V11.1309C0.5 11.6831 0.947715 12.1309 1.5 12.1309H3.5C4.05228 12.1309 4.5 11.6831 4.5 11.1309V1.13086C4.5 0.578575 4.05228 0.130859 3.5 0.130859H1.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 1.13086H6.5V11.1309H8.5V1.13086ZM6.5 0.130859C5.94772 0.130859 5.5 0.578574 5.5 1.13086V11.1309C5.5 11.6831 5.94772 12.1309 6.5 12.1309H8.5C9.05228 12.1309 9.5 11.6831 9.5 11.1309V1.13086C9.5 0.578575 9.05228 0.130859 8.5 0.130859H6.5Z"
      fill={color}
    />
    <path
      d="M10.5 1.13086C10.5 0.578574 10.9477 0.130859 11.5 0.130859H13.5C14.0523 0.130859 14.5 0.578575 14.5 1.13086V11.1309C14.5 11.6831 14.0523 12.1309 13.5 12.1309H11.5C10.9477 12.1309 10.5 11.6831 10.5 11.1309V1.13086Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 1.13086H11.5V11.1309H13.5V1.13086ZM11.5 0.130859C10.9477 0.130859 10.5 0.578574 10.5 1.13086V11.1309C10.5 11.6831 10.9477 12.1309 11.5 12.1309H13.5C14.0523 12.1309 14.5 11.6831 14.5 11.1309V1.13086C14.5 0.578575 14.0523 0.130859 13.5 0.130859H11.5Z"
      fill={color}
    />
  </svg>
);
