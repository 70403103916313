import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';

import styles from './gradient-layout.module.scss';

const LogoutButton = () => {
  return (
    <Link to="/logout" className={styles.link}>
      Log out
    </Link>
  );
};

export const GradientLayout = ({ showLogout = false }: { showLogout?: boolean }) => {
  useEffect(() => {
    Intercom('update', {
      hide_default_launcher: false,
    });
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.logo} />
        {showLogout && <LogoutButton />}
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
