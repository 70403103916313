import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../../components/icon';

import styles from './pipeline.module.scss';

interface SeparatorProps {
  addable?: boolean;
  onAdd?: MouseEventHandler;
  icon?: IconProps['name'];
  size?: number;
}

export const Separator = ({ addable = false, onAdd, icon, size }: SeparatorProps) => (
  <div
    className={classNames(styles.separator, {
      [styles.addable]: addable,
    })}
    onClick={(event) => {
      addable && onAdd && onAdd(event);
    }}>
    <Icon
      name={icon ?? (addable ? 'DownArrow' : 'DownArrowOutlined')}
      size={size ?? 14}
      className={styles.arrowIcon}
    />
    <Icon name="CirclePlus" size={16} className={styles.circlePlusIcon} />
  </div>
);
