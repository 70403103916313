import { ReactNode } from 'react';

import styles from './step-section.module.scss';

interface StepSectionProps {
  title: string;
  children: ReactNode;
}

export const StepSection = ({ title, children }: StepSectionProps) => {
  return (
    <section className={styles.stepSection}>
      <h1>{title}</h1>
      {children}
    </section>
  );
};
