import { IconProps } from './types';

export const Instance = ({ size = 12, color = 'currentColor' }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 17">
    <g fill={color}>
      <path d="M2.667 2A.667.667 0 0 0 2 2.667v6a.667.667 0 0 0 .667.666h.667a.667.667 0 0 1 0 1.333h-.667a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v.667a.667.667 0 0 1-1.333 0v-.667A.667.667 0 0 0 8.667 2z" />
      <path
        d="M14.73 5.902A2 2 0 0 0 13.3 5.3h-6a2 2 0 0 0-2 2v6c0 .56.231 1.068.604 1.431.363.372.87.602 1.43.602h6a2 2 0 0 0 2-2v-6c0-.56-.231-1.068-.604-1.431M8.3 10.3a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
