import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import { auth } from '@/lib/firebase-init';

import { Card } from '../../components/gradient-layout/cards';
import { GoogleButton } from '../../components/google-button';
import { useToastContext } from '../../components/toast';

interface GoogleSignupCardProps {
  onStartSignup: (email: string) => void;
}

const provider = new GoogleAuthProvider();

export const GoogleSignupCard = ({ onStartSignup }: GoogleSignupCardProps) => {
  const addToast = useToastContext();

  const handleClick = async () => {
    try {
      const credential = await signInWithPopup(auth, provider);
      const user = credential.user;
      if (user.email === null) {
        throw new Error('Email is null');
      }
      onStartSignup(user.email);
    } catch (e) {
      addToast({
        title: 'Signup Error',
        content: () => `Something went wrong. Please contact us if the issue persists.`,
        kind: 'error',
      });
      throw e;
    }
  };

  return (
    <Card>
      <GoogleButton title="Continue with Google" onClick={handleClick} />
    </Card>
  );
};
