import { MouseEventHandler } from 'react';
import { SortDirection } from '@tanstack/react-table';
import classNames from 'classnames';

import { Icon } from '@/components/icon';

import table from '@/components/table/table.module.scss';

interface SortButtonProps {
  sortDirection: SortDirection | false;
  onClick: MouseEventHandler | undefined;
}

export const isSortEnabledForColumn = (column: { type: string }) => column.type !== 'Object';

export const SortButton = ({ sortDirection, onClick }: SortButtonProps) => (
  <button
    title="Sort"
    className={classNames(table.sortButton, {
      [table.sortedAsc]: sortDirection === 'asc',
      [table.sortedDesc]: sortDirection === 'desc',
    })}
    onClick={onClick}>
    <Icon name="ChevronUp" size={11} className={table.sortAsc} />
    <Icon name="ChevronDown" size={11} className={table.sortDesc} />
  </button>
);
