import {
  CSSProperties,
  ReactNode,
  Ref,
  RefObject,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { Overlay } from '../overlay';

import styles from './tooltip.module.scss';

interface TooltipBaseProps {
  size?: 'default' | 'compact';
  color?: 'default' | 'light' | 'error';
  title?: ReactNode;
  className?: classNames.Argument;
  children?: ReactNode;
  style?: CSSProperties;
}

export const TooltipBase = forwardRef(function TooltipBase(
  { size = 'default', color = 'default', title, className, children, style }: TooltipBaseProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      className={classNames(styles.tooltipBase, className, {
        [styles.sizeDefault]: size === 'default',
        [styles.sizeCompact]: size === 'compact',
        [styles.colorDefault]: color === 'default',
        [styles.colorLight]: color === 'light',
        [styles.colorError]: color === 'error',
      })}
      style={style}
      ref={ref}>
      {title !== undefined && <span className={styles.tooltipTitle}>{title}</span>}
      {children}
    </div>
  );
});

interface TooltipProps extends TooltipBaseProps {
  top?: number;
  left?: number;
  offsetTop?: number;
  offsetLeft?: number;
  bound?: boolean | 'x' | 'y';
  boundToRef?: RefObject<HTMLElement>;
}

export const Tooltip = (props: TooltipProps) => {
  const { style, offsetTop, offsetLeft, className, children, ...rest } = props;
  const [rect, setRect] = useState<DOMRect | null>(null);
  const [boundToRect, setBoundToRect] = useState<DOMRect | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }
    setRect(ref.current.getBoundingClientRect());
  }, [props.top, props.left, ref, children]);

  useLayoutEffect(() => {
    if (props.boundToRef === undefined || props.boundToRef.current === null) {
      return;
    }
    setBoundToRect(props.boundToRef.current.getBoundingClientRect());
  }, [props.top, props.left, props.boundToRef, children]);

  const isBoundX = props.bound === 'x' || props.bound === true;
  const isBoundY = props.bound === 'y' || props.bound === true;

  const minX = boundToRect?.left ?? 0;
  const maxX = (boundToRect?.right ?? 0) - (rect?.width ?? 0);
  const left =
    !isBoundX || rect === null || boundToRect === null
      ? (props.left ?? 0)
      : Math.max(minX, Math.min(maxX, props.left ?? 0));

  const minY = boundToRect?.top ?? 0;
  const maxY = (boundToRect?.bottom ?? 0) - (rect?.height ?? 0);
  const top =
    !isBoundY || rect === null || boundToRect === null
      ? (props.top ?? 0)
      : Math.max(minY, Math.min(maxY, props.top ?? 0));

  return (
    <Overlay>
      <TooltipBase
        style={{
          top: top !== undefined ? `${top}px` : undefined,
          left: left !== undefined ? `${left}px` : undefined,
          transform: `translate(${offsetLeft ?? 0}px, ${offsetTop ?? 0}px)`,
          ...style,
        }}
        ref={ref}
        className={classNames(className, styles.tooltip)}
        {...rest}>
        {children}
      </TooltipBase>
    </Overlay>
  );
};
