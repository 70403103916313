import { Section } from '../common';

export const CliSection = () => {
  return (
    <Section title="Command Line Interface">
      <p>
        Download our CLI tool to easily manage your data models. Windows users can set up the CLI by
        installing the Linux version with WSL. Read more in our{' '}
        <a
          href="https://docs.supersimple.io/guides/managing-the-data-model/using-the-cli"
          target="_blank"
          rel="noreferrer noopener">
          CLI docs
        </a>
        .
      </p>
      <ul>
        <li>
          Download for MacOS:{' '}
          <a href="https://assets.supersimple.io/supersimple-cli/latest/supersimple-arm64-apple-darwin">
            Apple Silicon
          </a>
          ,{' '}
          <a href="https://assets.supersimple.io/supersimple-cli/latest/supersimple-x86_64-apple-darwin">
            Intel
          </a>
        </li>
        <li>
          Download for{' '}
          <a href="https://assets.supersimple.io/supersimple-cli/latest/supersimple-x86_64-linux-gnu">
            Linux
          </a>
        </li>
      </ul>
    </Section>
  );
};
