import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { IconButton } from '@/components/button';
import { Icon, IconProps } from '@/components/icon';

import styles from './pipeline-model.module.scss';

interface PipelineModelProps {
  title: string;
  children: string;
  icon?: IconProps['name'];
  color?: number;
  htmlTitle?: string;
  empty?: boolean;
  onClick?: MouseEventHandler;
  accessories: { icon: IconProps['name']; title: string; onClick: () => void }[];
}

export const PipelineModel = (props: PipelineModelProps) => {
  return (
    <div
      className={classNames(styles.model, {
        [styles.empty]: props.empty,
        [styles.clickable]: props.onClick !== undefined,
        [styles.color1]: props.color === 1,
        [styles.color2]: props.color === 2,
        [styles.color3]: props.color === 3,
        [styles.color4]: props.color === 4,
        [styles.color5]: props.color === 5,
        [styles.color6]: props.color === 6,
      })}
      title={props.htmlTitle}
      onClick={props.onClick}>
      <div className={styles.icon}>
        <Icon name={props.icon ?? (props.color === undefined ? 'Model' : 'Instance')} size={16} />
      </div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.name}>{props.children}</div>
      <div className={styles.accessories} onClick={(event) => event.stopPropagation()}>
        {props.accessories.map(({ icon, title, onClick }, index) => (
          <IconButton
            key={index}
            icon={icon}
            title={title}
            onClick={onClick}
            className={styles.accessory}
          />
        ))}
      </div>
    </div>
  );
};
