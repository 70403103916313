import { useCopyToClipboard } from 'usehooks-ts';

import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { ErrorBanner } from '@/components/banner';
import { useToastContext } from '@/components/toast';

import style from './code-preview.module.scss';

interface CodePreviewProps {
  code: string | undefined;
  language: string;
  isLoading?: boolean;
  hasError?: boolean;
  onClose: () => void;
}

export const CodePreview = (props: CodePreviewProps) => {
  const { code, language, isLoading = false, hasError = false, onClose } = props;

  const [, copyToClipboard] = useCopyToClipboard();
  const addToast = useToastContext();

  const handleCopyToClipboard = () => {
    copyToClipboard(code ?? '');

    addToast({
      title: `${language} copied!`,
      content: () => `${language} copied to clipboard`,
      kind: 'success',
    });
  };

  return (
    <Modal onClose={onClose} closeOnClickAway closeOnEsc>
      <Panel title={`${language} for this block`}>
        <div className={style.container}>
          {isLoading ? (
            <div className={style.loader}>
              <Loader size="large" />
            </div>
          ) : hasError ? (
            <ErrorBanner />
          ) : (
            <pre className={style.code}>{code}</pre>
          )}
          <div className={style.buttons}>
            <Button
              onClick={handleCopyToClipboard}
              icon={<Icon name="Clipboard" size={16} />}
              disabled={code === undefined}>
              Copy to Clipboard
            </Button>
            <Button variant="gray" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </Panel>
    </Modal>
  );
};
