import { Fragment, MouseEvent, useMemo, useState } from 'react';
import classNames from 'classnames';
import { first } from 'lodash';

import { pluralize } from '@/lib/utils/string';
import { useTrackEvent } from '@/lib/analytics';

import type {
  Exploration,
  Fields,
  JoinPipelineOperation,
  Pipeline,
  PipelineOperation,
} from '../types';
import {
  removeOperation,
  updateOperation,
  replaceOperations,
  addOperation,
  replacePipeline,
  moveOperation,
  addOperations,
} from './utils';
import { getPipelineStateAtIndexOrThrow, getFinalStateOrThrow } from '../pipeline/state';
import {
  getOperationIcon,
  getOperationTitle,
  getOperationOverview,
} from '../pipeline/format-operation';
import { getVariableDefinitions, restoreInvalidOperation, sortFields } from '../utils';
import {
  formatCellInstances,
  formatModelLabels,
  getJoinedPipelineTitle,
  isJoinedPipeline,
} from './utils/format';
import { createBackNavigationToast, useToastContext } from '../../components/toast';
import { EditOperation } from './edit-operation';
import { SortableItem } from '../components/sortable-item';
import { Icon } from '../../components/icon';
import { Button, IconButton } from '../../components/button';
import { Separator } from './separator';
import { SavedPipelineHeader } from './saved-pipeline-header';
import { PipelineHeader } from './pipeline-header';
import { SavedOperation } from './saved-operation';
import { Operation } from './operation';
import {
  countOperations,
  dereferencePipeline,
  flattenPipeline,
  getChildPipelineCells,
  getParentPipelineColor,
  getParentPipelineTitle,
  getPipelineColor,
} from '../pipeline/utils';
import { FormId, useExplorationContext } from '../exploration/exploration-context';
import { CombineEditor } from '../exploration/combine';
import { AddOperation } from './add-operation';
import { useMetadataContext } from '../metadata-context';
import { isBasePipeline } from '../exploration/utils';
import { getModelOrThrow } from '../model/utils';
import { isValidOperation } from '../pipeline/operation';

import { BasePipelineSearchModal } from '../exploration-search/base-pipeline-search-modal';

import styles from './pipeline.module.scss';
import sortableStyles from '../components/sortable-item/sortable-item.module.scss';

interface EditPipelineProps {
  title: string;
  pipeline: Pipeline;
  exploration: Exploration;
  setExploration: (exploration: Exploration) => void;
  recordType: Fields;
  grouping: string[];
  onCombine: () => void;
}

export const EditPipeline = (props: EditPipelineProps) => {
  const { pipeline } = props;

  const { metrics: metrics, models } = useMetadataContext();

  const {
    addFormData,
    editFormIndex,
    openAddMenu,
    openAddForm,
    closeAddForm,
    openEditForm,
    closeEditForm,
    selectedCell,
    selectedCellIndex,
    getVariables,
  } = useExplorationContext();

  const [isPipelineSearchOpen, setPipelineSearchVisibility] = useState(false);

  const variables = getVariables();
  const stateContext = { models, variables, metrics };
  const variableDefinitions = useMemo(
    () => getVariableDefinitions(props.exploration),
    [props.exploration],
  );

  const flattenedPipeline = flattenPipeline(pipeline, props.exploration);

  const parentPipeline = isBasePipeline(pipeline)
    ? null
    : {
        ...flattenedPipeline,
        operations: flattenedPipeline.operations.slice(
          0,
          flattenedPipeline.operations.length - pipeline.operations.length,
        ),
      };
  const dereferencedPipeline = dereferencePipeline(flattenedPipeline, props.exploration);
  const baseModel = getModelOrThrow(models, dereferencedPipeline.baseModelId);
  const title = getParentPipelineTitle(props.exploration, pipeline) ?? props.title;

  // Dragged index is needed to which element from pipeline is being dragged. This is also stored in drag event
  // but due to browser security it is not readable until drop event. We need to know the index during dragEnter
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [isParentCollapsed, setIsParentCollapsed] = useState(true);

  if (selectedCellIndex === null) {
    throw new Error('EditPipeline requires a selected cell');
  }
  const addToast = useToastContext();

  const trackEvent = useTrackEvent();

  const setMainPipelineOperation = (operation: PipelineOperation, atIndex: number) => {
    const exploration = updateOperation(
      props.exploration,
      selectedCellIndex,
      atIndex,
      operation,
      stateContext,
    );
    warnIfOperationsRemoved(exploration);

    props.setExploration(exploration);
    closeEditForm();
    trackEvent('Explore Pipeline Updated', { title, pipeline, operation, atIndex });
  };

  const setParentPipelineOperation = (operation: PipelineOperation, atIndex: number) => {
    const exploration = updateOperation(
      replacePipeline(props.exploration, selectedCellIndex, flattenedPipeline),
      selectedCellIndex,
      atIndex,
      operation,
      stateContext,
    );
    const removedOperationsCount =
      countOperations(props.exploration) - countOperations(exploration);

    props.setExploration(exploration);
    closeEditForm();

    trackEvent('Explore Pipeline Updated', {
      title,
      pipeline: flattenedPipeline,
      operation,
      atIndex,
    });
    if (removedOperationsCount > 0) {
      addToast(
        createBackNavigationToast(
          'Pipeline Step Deleted and Pipeline Detached from Parent',
          'The change caused the pipeline to be detached from the parent and other pipeline step(s) to be deleted. To revert the changes you can',
          'click undo',
        ),
      );
    } else {
      addToast(
        createBackNavigationToast(
          'Pipeline Detached From Parent',
          'The change caused the pipeline to be detached from the parent. To revert the changes',
          'click undo',
        ),
      );
    }
  };

  const handleRemoveOperation = (event: MouseEvent, atIndex: number) => {
    event.stopPropagation();

    const exploration = removeOperation(props.exploration, selectedCellIndex, atIndex);
    props.setExploration(exploration);

    trackEvent('Explore Pipeline Operation Removed', {
      title,
      pipeline,
      operation: pipeline.operations[atIndex],
    });
  };

  const handleRemoveParentPipelineOperation = (event: MouseEvent, atIndex: number) => {
    event.stopPropagation();

    const exploration = removeOperation(
      replacePipeline(props.exploration, selectedCellIndex, flattenedPipeline),
      selectedCellIndex,
      atIndex,
    );
    props.setExploration(exploration);

    trackEvent('Explore Pipeline Operation Removed', {
      title,
      pipeline: flattenedPipeline,
      operation: flattenedPipeline.operations[atIndex],
    });

    addToast(
      createBackNavigationToast(
        'Pipeline Step Deleted',
        'Please notice that pipeline was detached from the parent. To revert the changes you can',
        'click undo',
      ),
    );
  };

  const handleAddOperation = ({
    position,
    operation,
  }: {
    position: number;
    operation: PipelineOperation;
  }) => {
    trackEvent('Explore Pipeline Operation Added', { title, pipeline, operation });
    const exploration = addOperation(
      props.exploration,
      selectedCellIndex,
      position,
      operation,
      stateContext,
    );
    warnIfOperationsRemoved(exploration);

    props.setExploration(exploration);
    closeAddForm();
  };

  const handleReorder = (fromIndex: number, toIndex: number) => {
    const exploration = moveOperation(props.exploration, selectedCellIndex, fromIndex, toIndex);
    warnIfOperationsRemoved(exploration);
    props.setExploration(exploration);
  };

  const handleDisableOperation = (event: MouseEvent, atIndex: number, disabled: boolean) => {
    trackEvent('Explore Pipeline Operation Disable Toggled', {
      title,
      pipeline,
      operation: pipeline.operations[atIndex],
    });

    event.stopPropagation();
    const operations = pipeline.operations.map((operation, index) => {
      if (index === atIndex) {
        return { ...operation, disabled: disabled ?? undefined };
      }
      return operation;
    });
    props.setExploration(replaceOperations(props.exploration, selectedCellIndex, operations));
  };

  const handleDisableSavedOperation = (event: MouseEvent, atIndex: number, disabled: boolean) => {
    event.stopPropagation();

    const exploration = replacePipeline(props.exploration, selectedCellIndex, {
      ...flattenedPipeline,
      operations: flattenedPipeline.operations.map((operation, index) => {
        if (index === atIndex) {
          return { ...operation, disabled: disabled ?? undefined };
        }
        return operation;
      }),
    });

    trackEvent('Explore Pipeline Saved Operation Disable Toggled', {
      title,
      pipeline,
      operation: flattenedPipeline.operations[atIndex],
    });
    props.setExploration(exploration);

    addToast(
      createBackNavigationToast(
        'Pipeline detached from parent',
        'The change caused the pipeline to be detached from the parent. To revert the changes',
        'click undo',
      ),
    );
  };

  const handleDetachPipeline = () => {
    props.setExploration(replacePipeline(props.exploration, selectedCellIndex, flattenedPipeline));

    trackEvent('Exploration Pipeline Detached', {
      exploration: props.exploration,
      cell: props.exploration.view.cells[selectedCellIndex],
      cellIndex: selectedCellIndex,
    });
  };

  const warnIfOperationsRemoved = (updatedExploration: Exploration) => {
    const removedOperationsCount =
      countOperations(props.exploration) - countOperations(updatedExploration);

    if (removedOperationsCount > 0) {
      addToast(
        createBackNavigationToast(
          'Pipeline Step Deleted',
          'The change caused one or more pipeline steps to be deleted. To revert the changes you can',
          'click undo',
        ),
      );
    }
  };

  const firstOperation = first(pipeline.operations);
  const shouldRenderHeader =
    firstOperation === undefined ||
    restoreInvalidOperation(firstOperation).operation !== 'joinPipeline';
  const shouldRenderModelStep = first(flattenedPipeline.operations)?.operation !== 'sql';

  const parentPipelineColor = shouldRenderHeader
    ? getParentPipelineColor(props.exploration, pipeline)
    : undefined;

  const instanceCells = getChildPipelineCells(props.exploration, pipeline);
  const instanceCount = instanceCells.length;
  const pipelineColor = getPipelineColor(props.exploration, pipeline);

  const modelDescription =
    baseModel.description !== undefined ? `\nDescription: ${baseModel.description}` : '';
  const modelTitle = `Name: ${baseModel.name}${modelDescription}\n${formatModelLabels(baseModel.labels)}`;

  const handleBasePipelineChange = (newPipeline: Pipeline) => {
    // Add operations from the current pipeline to the new pipeline
    const updatedExploration = addOperations(
      replacePipeline(props.exploration, selectedCellIndex, {
        ...newPipeline,
        pipelineId: pipeline.pipelineId, // Preserve the pipeline ID
      }),
      selectedCellIndex,
      newPipeline.operations.length,
      pipeline.operations,
      stateContext,
    );

    props.setExploration(updatedExploration);
    setPipelineSearchVisibility(false);
  };

  return (
    <>
      {instanceCount > 0 && (
        <div
          className={classNames(styles.masterHeader, {
            [styles.headerColor1]: pipelineColor === 1,
            [styles.headerColor2]: pipelineColor === 2,
            [styles.headerColor3]: pipelineColor === 3,
            [styles.headerColor4]: pipelineColor === 4,
            [styles.headerColor5]: pipelineColor === 5,
            [styles.headerColor6]: pipelineColor === 6,
          })}
          title={formatCellInstances(instanceCells)}>
          <PipelineHeader
            title="Master Instance"
            icon="Parent"
            description={title}
            onClick={() => setPipelineSearchVisibility(true)}
          />
          <div className={styles.masterDescription}>
            <Icon name="Instance" size={16} />
            Pipeline used in {instanceCount} {pluralize(instanceCount, 'instance', 'instances')}
          </div>
          <Separator icon="VerticalEquals" size={8} />
        </div>
      )}
      <div
        className={classNames(styles.pipeline, {
          [styles.collapsed]: isParentCollapsed,
          [styles.headerColor1]: parentPipelineColor === 1,
          [styles.headerColor2]: parentPipelineColor === 2,
          [styles.headerColor3]: parentPipelineColor === 3,
          [styles.headerColor4]: parentPipelineColor === 4,
          [styles.headerColor5]: parentPipelineColor === 5,
          [styles.headerColor6]: parentPipelineColor === 6,
        })}>
        {shouldRenderHeader && (
          <>
            {parentPipeline !== null ? (
              <div>
                <SavedPipelineHeader
                  title="Instance"
                  icon="Instance"
                  description={title}
                  collapsed={isParentCollapsed}
                  onClick={() => setIsParentCollapsed(!isParentCollapsed)}
                  actionItems={[
                    {
                      label: 'Replace base model',
                      onClick: () => setPipelineSearchVisibility(true),
                      icon: <Icon name="Repeat" size={16} />,
                    },
                    {
                      label: 'Combine',
                      onClick: props.onCombine,
                      icon: <Icon name="Combine" size={16} />,
                    },
                    {
                      label: 'Detach instance from parent pipeline',
                      onClick: handleDetachPipeline,
                      icon: <Icon name="Detach" size={16} />,
                    },
                  ]}
                />
                {shouldRenderModelStep && (
                  <div
                    className={classNames(styles.collapsibleOperation, {
                      [styles.collapsed]: isParentCollapsed,
                    })}>
                    <SavedOperation
                      icon="Model"
                      title="Data model"
                      description={
                        isJoinedPipeline(flattenedPipeline)
                          ? getJoinedPipelineTitle(
                              flattenedPipeline,
                              flattenedPipeline.operations[0] as JoinPipelineOperation,
                              models,
                              props.exploration,
                            )
                          : baseModel.name
                      }
                      onClick={() => setPipelineSearchVisibility(true)}
                    />
                  </div>
                )}
                {parentPipeline.operations
                  .filter((operation) => operation.operation !== 'joinPipeline')
                  .map((operation, i) => {
                    const pipelineState = sortFields(
                      getPipelineStateAtIndexOrThrow(
                        dereferencedPipeline.baseModelId,
                        dereferencedPipeline.operations,
                        i,
                        stateContext,
                      ),
                      props.recordType,
                      props.grouping,
                    );

                    const editEnabled = operation.operation !== 'sql';
                    const removeEnabled = !isParentCollapsed && operation.operation !== 'sql';

                    return (
                      <div
                        key={i}
                        className={classNames(styles.collapsibleOperation, {
                          [styles.collapsed]: isParentCollapsed,
                        })}>
                        {editFormIndex === i ? (
                          <EditOperation
                            operation={restoreInvalidOperation(operation)}
                            exploration={props.exploration}
                            state={pipelineState}
                            models={models}
                            metrics={metrics}
                            setOperation={(operation) => setParentPipelineOperation(operation, i)}
                            onClose={closeEditForm}
                            onRemove={(event) =>
                              !isParentCollapsed && handleRemoveParentPipelineOperation(event, i)
                            }
                            className={styles.panel}
                            variables={variableDefinitions}
                          />
                        ) : (
                          <SavedOperation
                            icon={getOperationIcon(operation)}
                            title={getOperationTitle(operation, pipelineState)}
                            description={getOperationOverview(operation, {
                              fields: pipelineState.fields,
                              model: pipelineState.model,
                              variables,
                            })}
                            onRemove={
                              removeEnabled
                                ? (event) => handleRemoveParentPipelineOperation(event, i)
                                : undefined
                            }
                            onClick={editEnabled ? () => openEditForm({ index: i }) : undefined}
                            disabled={operation.disabled}
                            onChangeDisabled={
                              operation.operation === 'filter'
                                ? (event, disabled) => {
                                    handleDisableSavedOperation(event, i, disabled);
                                  }
                                : undefined
                            }
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <PipelineHeader
                icon="Model"
                title="Data model"
                htmlTitle={modelTitle}
                description={baseModel.name}
                onClick={() => setPipelineSearchVisibility(true)}>
                <IconButton icon="Combine" title="Combine" onClick={props.onCombine} />
              </PipelineHeader>
            )}
          </>
        )}

        {pipeline.operations.map((operation, i) => {
          const unwrappedOperation = restoreInvalidOperation(operation);
          if (unwrappedOperation.operation === 'joinPipeline') {
            return (
              <Fragment key={i}>
                <CombineEditor
                  operation={unwrappedOperation}
                  setExploration={props.setExploration}
                />
              </Fragment>
            );
          }

          const operationIdx = parentPipeline !== null ? parentPipeline.operations.length + i : i;
          const pipelineState = sortFields(
            getPipelineStateAtIndexOrThrow(
              dereferencedPipeline.baseModelId,
              dereferencedPipeline.operations,
              operationIdx,
              stateContext,
            ),
            props.recordType,
            props.grouping,
          );

          return (
            <Fragment key={i}>
              <Separator
                addable={addFormData?.index !== i}
                onAdd={() => openAddMenu({ index: i })}
              />
              {addFormData?.index === i && (
                <>
                  <AddOperation
                    formData={addFormData}
                    state={pipelineState}
                    models={models}
                    metrics={metrics}
                    exploration={props.exploration}
                    onAdd={(operation: PipelineOperation) =>
                      handleAddOperation({
                        operation,
                        position: i,
                      })
                    }
                    onClose={closeAddForm}
                    onOpenMenu={() => openAddMenu({ index: i })}
                    onOpenForm={(formId: FormId) => openAddForm({ index: i, formId })}
                    variables={variableDefinitions}
                  />
                  <Separator
                    addable={addFormData?.index !== i}
                    onAdd={() => openAddMenu({ index: i })}
                  />
                </>
              )}
              {editFormIndex === operationIdx ? (
                <EditOperation
                  operation={unwrappedOperation}
                  exploration={props.exploration}
                  state={pipelineState}
                  models={models}
                  metrics={metrics}
                  setOperation={(operation) => setMainPipelineOperation(operation, i)}
                  onClose={closeEditForm}
                  onRemove={(event) => handleRemoveOperation(event, i)}
                  variables={variableDefinitions}
                />
              ) : (
                <SortableItem
                  scope={'operation'}
                  index={i}
                  draggedIndex={draggedIndex}
                  setDraggedIndex={setDraggedIndex}
                  onReorder={handleReorder}
                  classNames={{
                    dragBefore: sortableStyles.dragBefore,
                    dragAfter: sortableStyles.dragAfter,
                  }}>
                  <Operation
                    icon={getOperationIcon(operation)}
                    title={getOperationTitle(operation, pipelineState)}
                    description={getOperationOverview(operation, {
                      fields: pipelineState.fields,
                      model: pipelineState.model,
                      variables,
                    })}
                    onRemove={(event) => handleRemoveOperation(event, i)}
                    onClick={() => openEditForm({ index: operationIdx })}
                    disabled={operation.disabled}
                    invalid={!isValidOperation(operation)}
                    onChangeDisabled={
                      operation.operation === 'filter'
                        ? (event, disabled) => {
                            handleDisableOperation(event, i, disabled);
                          }
                        : undefined
                    }
                  />
                </SortableItem>
              )}
            </Fragment>
          );
        })}
        <Separator addable={false} onAdd={() => openAddMenu({ index: -1 })} />
        <div className={styles.addOperations}>
          {addFormData?.index === -1 ? (
            <AddOperation
              formData={addFormData}
              state={sortFields(
                getFinalStateOrThrow(
                  dereferencedPipeline.baseModelId,
                  dereferencedPipeline.operations,
                  stateContext,
                ),
                props.recordType,
                props.grouping,
              )}
              models={models}
              metrics={metrics}
              exploration={props.exploration}
              onAdd={(operation) => handleAddOperation({ operation, position: Infinity })}
              onClose={closeAddForm}
              onOpenMenu={() => openAddMenu({ index: -1 })}
              onOpenForm={(formId) => openAddForm({ index: -1, formId })}
              variables={variableDefinitions}
            />
          ) : (
            <Button
              variant={editFormIndex === null ? 'primary' : 'outlined'}
              icon={<Icon name="PlusCircle" size={16} />}
              onClick={() => openAddMenu({ index: -1 })}>
              Add
            </Button>
          )}
        </div>
      </div>
      {isPipelineSearchOpen ? (
        <BasePipelineSearchModal
          exploration={{
            ...props.exploration,
            view: {
              ...props.exploration.view,
              cells: props.exploration.view.cells.filter((cell) => cell.id !== selectedCell?.id),
            },
          }}
          onSelect={handleBasePipelineChange}
          onClose={() => setPipelineSearchVisibility(false)}
        />
      ) : null}
    </>
  );
};
