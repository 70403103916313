import { useEffect, useRef } from 'react';

import { useKeyPress } from '@/lib/hooks/use-key-press';

import { useTrackEvent } from '@/lib/analytics';

import { Exploration } from '../types';
import { useExplorationCellContext } from './exploration-cell-context';

import style from './exploration.module.scss';

interface CellTitleProps {
  exploration: Exploration;
  value: string;
  onChange: (value: string) => void;
}

export const CellTitle = ({ exploration, value, onChange }: CellTitleProps) => {
  const titleInputRef = useRef<HTMLInputElement>(null);
  const { cell, cellIndex } = useExplorationCellContext();
  const trackEvent = useTrackEvent();

  useKeyPress(
    'Escape',
    (event) => {
      event.stopPropagation();
      titleInputRef.current?.blur();
    },
    false,
    titleInputRef,
  );
  useKeyPress('Enter', () => titleInputRef.current?.blur(), false, titleInputRef);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value;
    if (title !== value) {
      onChange(title);
      trackEvent('Exploration Cell Title Changed', {
        explorationId: exploration.explorationId,
        name: exploration.name,
        cell,
        cellIndex,
        title,
      });
    }
  };

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.value = value ?? '';
    }
  }, [value]);

  return (
    <input
      className={style.title}
      type="text"
      defaultValue={value ?? '(Untitled)'}
      ref={titleInputRef}
      onBlur={handleChange}
      title={value}
    />
  );
};
