import { IconProps } from './types';

export const VerticalEqualsIcon = ({ size = 14, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <rect x="8" width="8" height="2.66667" transform="rotate(90 8 0)" fill={color} />
    <rect x="2.66669" width="8" height="2.66667" transform="rotate(90 2.66669 0)" fill={color} />
  </svg>
);
