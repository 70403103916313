import { useMemo } from 'react';
import classNames from 'classnames';

import { Select } from '@/components/form/select';
import { SearchInput } from '@/components/form/search-input';
import { FieldGroup, isFieldGroup } from '@/explore/pipeline/utils';
import { isValueExpression } from '@/explore/pipeline/operation';
import { getVariableExpression, getVariableDefinitions } from '@/explore/utils';
import { useExplorationContext } from '@/explore/exploration/exploration-context';
import { useTrackEvent } from '@/lib/analytics';

import { Field, Grouping, timeAggregationPeriodZod } from '../../types';
import { getGroupableFields, timePrecisionOptions } from '../../utils/grouping';
import { fieldToOptionGrouped } from '../../edit-pipeline/utils';
import { VariableReferenceBadge } from '../variable-reference-badge';

import styles from './grouping-select.module.scss';

type GroupingSelectProps = {
  grouping?: Grouping;
  groupings: Grouping[];
  fields: (Field | FieldGroup)[];
  onChange: (grouping: Grouping) => void;
  autoFocus?: boolean;
  fullWidth?: boolean;
};

export const GroupingSelect = (props: GroupingSelectProps) => {
  const { grouping, groupings, fields, onChange, autoFocus = false, fullWidth = false } = props;
  const { getVariables, exploration } = useExplorationContext();
  const trackEvent = useTrackEvent();
  const variables = getVariables().filter(({ type }) => type === 'String');

  const flattenedFields = fields.flatMap((field) => (isFieldGroup(field) ? field.fields : [field]));

  const handleFieldKeyChange = (fieldKey: string) => {
    const field = flattenedFields.find((f) => f.key === fieldKey);

    if (field === undefined) {
      return;
    }

    field.type === 'Date'
      ? onChange({
          key: fieldKey,
          precision: grouping?.precision ?? 'month',
        })
      : onChange({
          key: fieldKey,
        });
  };

  const handlePrecisionChange = (precision: string) => {
    if (grouping === undefined) {
      return;
    }

    const variable = variables.find((variable) => variable.key === precision);

    if (variable !== undefined) {
      trackEvent('Variable Assigned To Grouping Precision', {
        exploration,
        grouping,
        variable,
      });
    }

    onChange({
      ...grouping,
      precision:
        variable !== undefined
          ? { expression: getVariableExpression(variable.key), version: '1' }
          : timeAggregationPeriodZod.parse(precision),
    });
  };

  const options = useMemo(
    () =>
      getGroupableFields(
        fields,
        groupings.filter(({ key }) => key !== grouping?.key),
      ).map(fieldToOptionGrouped),
    [fields, groupings, grouping],
  );

  const precision = grouping?.precision;
  const precisionIsExpression = isValueExpression(precision);
  const variable = precisionIsExpression
    ? variables.find((variable) => getVariableExpression(variable.key) === precision.expression)
    : undefined;

  const precisionValue = precisionIsExpression ? variable?.key : precision;

  const variableOptions = variables.map(({ key }) => ({
    label: key,
    value: key,
  }));

  return (
    <div className={classNames(styles.groupingSelect, { [styles.fullWidth]: fullWidth })}>
      <SearchInput
        options={options}
        value={grouping?.key}
        onChange={handleFieldKeyChange}
        autoFocus={autoFocus}
      />
      {precision !== undefined && (
        <div>
          {precisionIsExpression ? (
            <VariableReferenceBadge
              value={precision.expression}
              variables={getVariableDefinitions(exploration)}
              onRemove={() => {
                trackEvent('Variable Unassigned From Grouping Precision', {
                  exploration,
                  grouping,
                  variable,
                });
                handlePrecisionChange(
                  timePrecisionOptions.find(
                    ({ value }) =>
                      value === getVariables().find(({ key }) => key === variable?.key)?.value,
                  )?.value ?? 'month',
                );
              }}
            />
          ) : (
            <Select
              options={
                variableOptions.length === 0
                  ? timePrecisionOptions
                  : [
                      {
                        value: 'precision',
                        label: 'Static Value',
                        options: timePrecisionOptions,
                      },
                      {
                        value: 'variable',
                        label: 'Variable',
                        options: variableOptions,
                      },
                    ]
              }
              value={precisionValue}
              onChange={handlePrecisionChange}
              fullWidth={fullWidth}
            />
          )}
        </div>
      )}
    </div>
  );
};
