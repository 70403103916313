import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { ErrorBanner } from '@/components/banner';
import { Icon } from '@/components/icon';
import { Exploration, InvalidCell } from '@/explore/types';

import { useExplorationCellContext } from '../exploration-cell-context';
import { CellTitle } from '../cell-title';
import { CollapsibleContainer, CollapseButton, CollapsibleContent } from '../collapsible-cell';
import { CellControls } from '../cell-controls';

import styles from '../exploration.module.scss';

type InvalidCellViewProps = {
  cell: InvalidCell;
  exploration: Exploration;
  onSetDraggable: (value: boolean) => void;
};

export const InvalidCellView = (props: InvalidCellViewProps) => {
  const { cell, exploration } = props;
  const { setCell, isCollapsible } = useExplorationCellContext();

  return (
    <CollapsibleContainer className={styles.cohortViewCell}>
      <div className={styles.cellHeader}>
        <div className={styles.cellControlsContainer}>
          <Icon
            name="DragHandle"
            size={10}
            className={styles.dragHandle}
            onMouseOver={() => props.onSetDraggable(true)}
            onMouseOut={() => props.onSetDraggable(false)}
          />
          {'title' in cell && (
            <CellTitle
              exploration={exploration}
              value={('title' in cell ? cell.title : undefined) ?? '(Untitled)'}
              onChange={(value) => setCell({ ...cell, title: value })}
            />
          )}
          <CellControls exploration={exploration} editButtonVisible={false} canDuplicate={false} />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          <ErrorBanner title="Invalid Block" description={cell.message} />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};
