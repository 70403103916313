export const CirclePlusIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <rect y="16" width="16" height="16" rx="8" transform="rotate(-90 0 16)" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66675 8.00016C2.66675 7.63197 2.96522 7.3335 3.33341 7.3335L12.6667 7.3335C13.0349 7.3335 13.3334 7.63197 13.3334 8.00016C13.3334 8.36835 13.0349 8.66683 12.6667 8.66683L3.33341 8.66683C2.96522 8.66683 2.66675 8.36835 2.66675 8.00016Z"
      fill="#F0F0F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 13.3335C7.63189 13.3335 7.33342 13.035 7.33342 12.6668L7.33341 3.3335C7.33341 2.96531 7.63189 2.66683 8.00008 2.66683C8.36827 2.66683 8.66675 2.96531 8.66675 3.3335L8.66675 12.6668C8.66675 13.035 8.36827 13.3335 8.00008 13.3335Z"
      fill="#F0F0F2"
    />
  </svg>
);
