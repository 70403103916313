import { IconProps } from './types';

export const Exploration = ({ size = 16, color = 'currentColor', ...rest }: IconProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4H4L4 8H10V4ZM4 2C2.89543 2 2 2.89543 2 4V8C2 9.10457 2.89543 10 4 10H10C11.1046 10 12 9.10457 12 8V4C12 2.89543 11.1046 2 10 2H4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 4H16V8H28V4ZM16 2C14.8954 2 14 2.89543 14 4V8C14 9.10457 14.8954 10 16 10H28C29.1046 10 30 9.10457 30 8V4C30 2.89543 29.1046 2 28 2H16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 24H24V28H28V24ZM24 22C22.8954 22 22 22.8954 22 24V28C22 29.1046 22.8954 30 24 30H28C29.1046 30 30 29.1046 30 28V24C30 22.8954 29.1046 22 28 22H24Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 24H4L4 28H18V24ZM4 22C2.89543 22 2 22.8954 2 24V28C2 29.1046 2.89543 30 4 30H18C19.1046 30 20 29.1046 20 28V24C20 22.8954 19.1046 22 18 22H4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 14H4V18H28V14ZM4 12C2.89543 12 2 12.8954 2 14V18C2 19.1046 2.89543 20 4 20H28C29.1046 20 30 19.1046 30 18V14C30 12.8954 29.1046 12 28 12H4Z"
      fill={color}
    />
  </svg>
);
