import { isEqual } from 'lodash';
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { notNil } from '../utils';
import { useKeyPress } from './use-key-press';

export type NavigableListItemInput<T> = {
  data?: T;
  children?: (NavigableListItemInput<T> | null)[];
  isCollapsed?: boolean;
  isFocusable?: boolean;
  onClick?: MouseEventHandler;
  collapseOnClick?: boolean;
};

export type NavigableListItemOutput<T> = {
  data?: T;
  isFocusable: boolean;
  collapseOnClick?: boolean;
  getData: <P extends T>() => P;
  children?: (NavigableListItemOutput<T> | null)[];
  getChildren: <P extends T>() => NavigableListItemOutput<P>[];
  getIsCollapsed: () => boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  toggleCollapsed: () => void;
  getIsFocused: () => boolean;
  setIsFocused: (isFocused: boolean) => void;
  onClick: MouseEventHandler;
};

const countFocusableNodes = <T>(node: NavigableListItemOutput<T> | null): number => {
  if (node === null) {
    return 0;
  }
  if (node.children && node.children.length > 0 && !node.getIsCollapsed()) {
    return (
      node.children.reduce((count, child) => count + countFocusableNodes(child), 0) +
      (node.isFocusable ? 1 : 0)
    );
  }
  return node.isFocusable ? 1 : 0;
};

/**
 * Find the set of indexes that lead to the item at the given continuous index among focusable nodes.
 */
const findFocusableIndexPath = <T>(
  node: NavigableListItemOutput<T> | null,
  index: number | null,
  indexPath: number[] = [],
): number[] | null => {
  if (index === null) {
    return null;
  }
  if (node === null) {
    return null;
  }
  if (index === 0 && node.isFocusable) {
    return indexPath;
  }
  if (node.isFocusable) {
    index--;
  }
  if (node.children && node.children.length > 0 && !node.getIsCollapsed()) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      const childCount = countFocusableNodes(child);
      if (index < childCount) {
        return findFocusableIndexPath(child, index, [...indexPath, i]);
      }
      index -= childCount;
    }
  }
  return null;
};

/**
 * Find the continuous index among focusable nodes at the given "index path".
 */
const findFocusableIndex = <T>(
  node: NavigableListItemOutput<T> | null,
  indexPath: number[],
): number => {
  if (node === null) {
    return 0;
  }
  let itemCount = 0;
  if (node.children && node.children.length > 0 && !node.getIsCollapsed() && indexPath.length > 0) {
    const currentIndex = indexPath[0];
    if (currentIndex < 0 || currentIndex >= node.children.length) {
      throw new Error(`Index ${currentIndex} out of range in ${indexPath}`);
    }
    for (let i = 0; i < currentIndex; i++) {
      itemCount += countFocusableNodes(node.children[i]);
    }
    return (
      itemCount +
      (node.isFocusable ? 1 : 0) +
      findFocusableIndex(node.children[indexPath[0]], indexPath.slice(1))
    );
  }
  return 0;
};

const getItemAtIndexPath = <T>(
  node: NavigableListItemOutput<T> | null,
  indexPath: number[],
): NavigableListItemOutput<T> | null => {
  if (node === null) {
    return null;
  }
  if (indexPath.length === 0) {
    return node;
  }
  const child = node.children?.[indexPath[0]] ?? null;
  return getItemAtIndexPath(child, indexPath.slice(1));
};

const findCollapsedIndexes = <T>(
  nodes: (NavigableListItemInput<T> | null)[],
  indexPath: number[] = [],
): number[][] => {
  const collapsedIndexes: number[][] = [];
  nodes.forEach((item, index) => {
    if (item === null) {
      return collapsedIndexes;
    }
    if (item.isCollapsed === true) {
      collapsedIndexes.push([...indexPath, index]);
    }
    collapsedIndexes.push(...findCollapsedIndexes(item.children ?? [], [...indexPath, index]));
  });
  return collapsedIndexes;
};

/**
 * Recursively transform list input nodes to output nodes, adding additional properties
 */
const transformItem = <T>(
  item: NavigableListItemInput<T> | null,
  index: number[],
  getIsCollapsed: (index: number[]) => boolean,
  setIsCollapsed: (index: number[], isCollapsed: boolean) => void,
  getIsFocused: (index: number[]) => boolean,
  setIsFocused: (index: number[], isFocused: boolean) => void,
): NavigableListItemOutput<T> | null => {
  if (item === null) {
    return null;
  }

  const children =
    item.children?.map((child, childIndex) =>
      transformItem(
        child,
        [...index, childIndex],
        getIsCollapsed,
        setIsCollapsed,
        getIsFocused,
        setIsFocused,
      ),
    ) ?? [];

  return {
    ...item,
    isFocusable: item.isFocusable ?? false,
    getIsCollapsed: () => getIsCollapsed(index),
    setIsCollapsed: (isCollapsed: boolean) => setIsCollapsed(index, isCollapsed),
    toggleCollapsed: () => setIsCollapsed(index, !getIsCollapsed(index)),
    getIsFocused: () => getIsFocused(index),
    setIsFocused: (isFocused: boolean) => setIsFocused(index, isFocused),
    getData: <P extends T>() => item.data as P,
    getChildren: <P extends T>() => children.filter(notNil) as NavigableListItemOutput<P>[],
    children,
    onClick: (event) => {
      if (item.collapseOnClick ?? false) {
        event.preventDefault();
        setIsCollapsed(index, !getIsCollapsed(index));
        return;
      }
      item.onClick?.(event);
    },
  };
};

interface UseNavigableListProps<T> {
  items: (NavigableListItemInput<T> | null)[];
  listContainerRef: React.RefObject<HTMLDivElement>;
  initialFocusIndex?: number;
  // Called when focusIndex is moved outside the list via tab/arrow keys
  onFocusMovedOutside?: () => void;
  // "Stable callback" (called only once) for when browser focus enters the list
  onFocus?: () => void;
  // "Stable callback" (called only once) for when browser focus exits the list
  onBlur?: () => void;
}

/**
 * A headless UI hook for nested collapsible lists with keyboard navigation.
 * Give it a tree of items as input and will return an "enriched" tree of items as output.
 * Each node/item in the tree is a list item and/or a list containing other items/lists.
 * Arrow keys can be used to move focus between leaf items in the list. Collapsed lists are ignored.
 */
export const useNavigableList = <T>({
  items,
  listContainerRef,
  initialFocusIndex,
  onFocusMovedOutside,
  onFocus,
  onBlur,
}: UseNavigableListProps<T>) => {
  // Array of index paths that identifying collapsed nodes ([parentIndex, childIndex, grandChildIndex, ...])
  const [collapsedIndexes, setCollapsedIndexes] = useState<number[][]>(findCollapsedIndexes(items));

  // A simple incrementing/decrementing number denoting the "nth focusable item" in the tree
  // This does not necessarily mean the item also has browser focus
  // Browser focus should be kept in sync in item components manually when needed
  const [focusIndex, setFocusIndex] = useState<number | null>(initialFocusIndex ?? null);
  const wasFocused = useRef(false);

  const getIsCollapsed = useCallback(
    (indexPath: number[]) => {
      return collapsedIndexes.some((collapsedIndex) => isEqual(collapsedIndex, indexPath));
    },
    [collapsedIndexes],
  );

  const root = useMemo(() => {
    const getIsFocused = (indexPath: number[]): boolean => {
      return isEqual(indexPath, findFocusableIndexPath(root, focusIndex));
    };

    const setIsFocused = (indexPath: number[], isFocused: boolean): void => {
      if (!isFocused) {
        const focusIndexPath = findFocusableIndexPath(root, focusIndex);
        if (!isEqual(focusIndexPath, indexPath)) {
          return;
        }
        return setFocusIndex(null);
      }
      const index = findFocusableIndex(root, indexPath);
      setFocusIndex(index);
    };

    const setIsCollapsed = (indexPath: number[], isCollapsed: boolean) => {
      if (isCollapsed) {
        setCollapsedIndexes([...collapsedIndexes, indexPath]);
        const focusIndexPath = findFocusableIndexPath(root, focusIndex);
        if (focusIndexPath !== null && indexPath.every((index, i) => index === focusIndexPath[i])) {
          setFocusIndex(null);
        }
      } else {
        setCollapsedIndexes(
          collapsedIndexes.filter((collapsedIndex) => !isEqual(collapsedIndex, indexPath)),
        );
      }
    };

    const root = transformItem(
      {
        children: items,
      },
      [],
      getIsCollapsed,
      setIsCollapsed,
      getIsFocused,
      setIsFocused,
    );
    return root;
  }, [collapsedIndexes, focusIndex, getIsCollapsed, items]);

  const leafCount = useMemo(
    () => root?.getChildren().reduce((count, item) => count + countFocusableNodes(item), 0) ?? 0,
    [root],
  );

  useKeyPress(
    'ArrowDown',
    (event) => {
      event.preventDefault();
      if (focusIndex !== null && focusIndex >= leafCount - 1) {
        setFocusIndex(null);
        onFocusMovedOutside && onFocusMovedOutside();
      }
      setFocusIndex(focusIndex === null ? 0 : focusIndex + 1);
    },
    false,
    listContainerRef,
  );

  useKeyPress(
    'ArrowUp',
    (event) => {
      event.preventDefault();
      if (focusIndex !== null && focusIndex <= 0) {
        setFocusIndex(null);
        onFocusMovedOutside && onFocusMovedOutside();
      }
      setFocusIndex(focusIndex === null ? leafCount - 1 : focusIndex - 1);
    },
    false,
    listContainerRef,
  );

  useEffect(() => {
    const element = listContainerRef.current;
    if (element === null || onFocus === undefined) {
      return;
    }

    const handleFocus = () => {
      if (wasFocused.current) {
        return;
      }
      wasFocused.current = true;
      onFocus();
    };

    element.addEventListener('focusin', handleFocus);
    return () => {
      element.removeEventListener('focusin', handleFocus);
    };
  }, [listContainerRef, onFocus]);

  useEffect(() => {
    // Clear focusIndex when focus moves outside of list
    const element = listContainerRef.current;
    if (element === null) {
      return;
    }

    const handleBlur = () => {
      // Focus moving causes blur, so need to wait for focus to settle before clearing focusIndex
      setTimeout(() => {
        if (
          listContainerRef.current !== null &&
          !listContainerRef.current.contains(document.activeElement)
        ) {
          setFocusIndex(null);
          wasFocused.current = false;
          onBlur?.();
        }
      }, 0);
    };
    element.addEventListener('focusout', handleBlur);
    return () => {
      element.removeEventListener('focusout', handleBlur);
    };
  }, [listContainerRef, onBlur]);

  const focusIndexPath = useMemo(() => {
    return focusIndex === null ? null : findFocusableIndexPath(root, focusIndex);
  }, [root, focusIndex]);

  if (focusIndexPath === null && focusIndex !== null) {
    setFocusIndex(null);
  }

  return {
    list: root?.children ?? [],
    leafCount,
    focusIndex,
    focusedItem: focusIndexPath !== null ? getItemAtIndexPath(root, focusIndexPath) : null,
    setFocusIndex: useCallback(
      (index: number) => setFocusIndex((index + leafCount) % leafCount),
      [leafCount],
    ),
  };
};
