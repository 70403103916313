import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useTitle } from '@/lib/hooks/use-title';

import { useGAPageview } from '@/lib/ga/use-ga';

import { useToastContext } from '../../components/toast';
import { useAuth } from '../../auth/auth-context';
import { FullPageLoader } from '../../components/loader';

export const ConfirmEmailPage = () => {
  useTitle('Confirm Email');
  useGAPageview();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { applyActionCode } = useAuth();
  const addToast = useToastContext();

  const applyActionCodeCalled = useRef(false);

  const code = searchParams.get('oobCode');

  useEffect(() => {
    if (code !== null && !applyActionCodeCalled.current) {
      applyActionCode(code)
        .then((user) => {
          if (user === null) {
            addToast({
              title: `Email verified`,
              content: () => `Verification successful. You can now log in.`,
              kind: 'success',
            });
            navigate('/login');
          } else {
            addToast({
              title: `Email verified`,
              content: () => `Verification successful. You are now logged in.`,
              kind: 'success',
            });
            navigate('/');
          }
        })
        .catch((error) => {
          switch ((error as any).code) {
            case 'auth/expired-action-code':
              addToast({
                title: 'Expired link',
                content: () => `The email verification code has expired.`,
                kind: 'error',
              });
              break;
            case 'auth/invalid-action-code':
              addToast({
                title: 'Invalid link',
                content: () => `The email verification code is invalid.`,
                kind: 'error',
              });
              break;
            default:
              addToast({
                title: 'Error',
                content: () => `Email verification failed.`,
                kind: 'error',
              });
          }
        })
        .finally(() => {
          navigate('/');
        });
      applyActionCodeCalled.current = true;
    }
  }, [addToast, code, navigate, applyActionCode]);

  return <FullPageLoader />;
};
