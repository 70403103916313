import { Fragment } from 'react';
import classNames from 'classnames';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { times } from 'lodash';
import { FunctionArgument, FunctionDefinition } from '@gosupersimple/penguino';

import { unlessNil } from '@/lib/utils';

import styles from './signature.module.scss';

export const renderSignature = (functionDefinition: FunctionDefinition, argumentIndex: number) => {
  const dom = document.createElement('div');
  dom.className = 'cm-argumentHints';

  flushSync(() =>
    createRoot(dom).render(
      <RenderSignature definition={functionDefinition} argumentIndex={argumentIndex} />,
    ),
  );

  return { dom, offset: { x: 0, y: 10 } };
};

interface RenderSignatureProps {
  definition: FunctionDefinition;
  argumentIndex: number;
}

type FunctionDocArgument = FunctionArgument | { variadic: FunctionArgument[] };

export const RenderSignature = (props: RenderSignatureProps) => {
  const { definition, argumentIndex } = props;

  const args = buildArgumentList(definition.arguments, argumentIndex);

  return (
    <div className={styles.signature}>
      <div className={styles.header}>
        {definition.name}(
        {args.map((arg, idx) => {
          if ('variadic' in arg) {
            return (
              <Fragment key={idx}>
                {idx > 0 && ', '}
                <span>[{arg.variadic.map((a) => a.name).join(', ')}...]</span>
              </Fragment>
            );
          }

          return (
            <Fragment key={idx}>
              {idx > 0 && ', '}
              <span
                className={classNames({
                  [styles.activeArgument]: idx === argumentIndex,
                })}>
                {arg.name}
                {arg.optional === true ? '?' : ''}
              </span>
            </Fragment>
          );
        })}
        )
      </div>

      <div className={styles.doc}>
        {unlessNil(args.at(argumentIndex), getArgumentDescription) ?? definition.description}
      </div>
    </div>
  );
};

const buildArgumentList = (args: readonly FunctionDocArgument[], argumentIndex: number) =>
  args
    .map((arg) => ('variadic' in arg ? buildVariadicArgumentList(arg, argumentIndex + 1) : arg))
    .flat();

function buildVariadicArgumentList(arg: { variadic: FunctionArgument[] }, idx: number) {
  const desiredLength = Math.ceil(idx / arg.variadic.length);

  return times(desiredLength, (idx) =>
    arg.variadic.map<FunctionDocArgument>((a) => ({ ...a, name: `${a.name}${idx + 1}` })),
  )
    .flat()
    .concat([
      { variadic: arg.variadic.map((i) => ({ ...i, name: `${i.name}${desiredLength + 1}` })) },
    ]);
}

const getArgumentDescription = (arg: FunctionDocArgument) =>
  'variadic' in arg ? undefined : arg.description;
