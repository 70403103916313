import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const useIsScrolled = <T extends HTMLElement>() => {
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollContainerRef = useRef<T>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer === null) {
      return;
    }
    const onScroll = throttle(() => {
      setIsScrolled(scrollContainer.scrollTop > 0);
    }, 100);
    scrollContainer.addEventListener('scroll', onScroll);
    return () => scrollContainer.removeEventListener('scroll', onScroll);
  }, [isScrolled, scrollContainerRef]);

  return [isScrolled, scrollContainerRef] as const;
};
