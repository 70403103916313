import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownMenuItem } from '../../components/dropdown';
import { IconProps } from '../../components/icon';
import { IconButton } from '../../components/button';
import { PipelineHeader } from './pipeline-header';

import styles from './pipeline.module.scss';

interface SavedPipelineHeaderProps {
  icon?: IconProps['name'];
  title: string;
  description: string;
  collapsed: boolean;
  onClick?: MouseEventHandler;
  actionItems?: DropdownMenuItem[];
}

export const SavedPipelineHeader = ({
  icon = 'Model',
  title,
  description,
  collapsed = false,
  onClick,
  actionItems = [],
}: SavedPipelineHeaderProps) => (
  <PipelineHeader
    icon={icon}
    title={title}
    description={description}
    className={classNames(styles.savedPipelineHeader, {
      [styles.collapsed]: collapsed,
      [styles.clickable]: Boolean(onClick),
    })}
    onClick={onClick}>
    {actionItems.length > 0 && (
      <Dropdown
        align="right"
        trigger={(isOpen, setIsOpen) => (
          <IconButton
            icon="MoreHorizontal"
            className={classNames(styles.moreButton, { [styles.moreMenuOpen]: isOpen })}
            size="small"
            title="More..."
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(!isOpen);
            }}
          />
        )}
        items={actionItems}
      />
    )}
  </PipelineHeader>
);
