import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';

import { Exploration } from '../../types';
import { ExplorationDetailsForm, ExplorationDetailsFormData } from '../details-form';

import styles from './edit-modal.module.scss';

interface ExplorationEditModalProps {
  exploration: Exploration;
  onSubmit: (data: ExplorationDetailsFormData) => Promise<void>;
  onDelete?: () => void;
  onClose: () => void;
}

export const ExplorationEditModal = ({
  exploration,
  onSubmit,
  onDelete,
  onClose,
}: ExplorationEditModalProps) => {
  return (
    <Modal onClose={onClose} closeOnClickAway>
      <Panel className={styles.panel}>
        <div className={styles.header}>
          <h1>Edit saved exploration</h1>
        </div>

        <div className={styles.body}>
          <p>Everyone on your team has access to saved explorations.</p>
          <ExplorationDetailsForm
            data={{
              name: exploration.name,
              description: exploration.description ?? '',
            }}
            onSubmit={onSubmit}
            onCancel={onClose}
            onDelete={onDelete}
          />
        </div>
      </Panel>
    </Modal>
  );
};
