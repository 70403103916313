import classNames from 'classnames';

import { UserRoles } from './utils';

import styles from './user-form.module.scss';

interface RoleSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export const RoleSelect = (props: RoleSelectProps) => (
  <div className={styles.roles}>
    {UserRoles.map((userRole) => (
      <div
        className={classNames(styles.role, {
          [styles.selected]: props.value === userRole.value,
        })}
        key={userRole.value}
        onClick={() => props.onChange(userRole.value)}>
        <div className={styles.title}>{userRole.label}</div>
        <div className={styles.description}>{userRole.description}</div>
      </div>
    ))}
  </div>
);
