import { Fragment, useState } from 'react';
import classNames from 'classnames';

import { IconButton, InlineButton } from '../../components/button';
import { GroupingSelect } from '../components/grouping-select';
import { Field, Grouping } from '../types';
import { Icon } from '../../components/icon';
import { SortableItem } from '../components/sortable-item';
import { isDateField } from '../components/visualisation/utils';
import { createGroupingFromField } from '../utils/grouping';
import { FieldGroup, isFieldGroup } from '../pipeline/utils';

import { useEnsureFieldsExistGrouped } from './hooks/use-ensure-fields-exist';
import { getGroupingKeys } from '../pipeline/operation';

import form from '../../components/form/form.module.scss';

interface GroupingsEditorProps {
  fields: (Field | FieldGroup)[];
  groups: Grouping[];
  setGroups(groups: Grouping[]): void;
  autoFocus?: boolean;
  preferDateGrouping?: boolean;
}

export const GroupingsEditor = (props: GroupingsEditorProps) => {
  const { groups, setGroups, autoFocus = false, preferDateGrouping = false } = props;
  const fields = useEnsureFieldsExistGrouped(props.fields, getGroupingKeys(groups));
  const flattenedFields = fields.flatMap((field) => (isFieldGroup(field) ? field.fields : [field]));

  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const isGrouped = (fieldKey: string) => groups.some((group) => group.key === fieldKey);

  const handleAddGroup = () => {
    const field = (preferDateGrouping ? flattenedFields.filter(isDateField) : flattenedFields).find(
      ({ key }) => !isGrouped(key),
    );
    if (field === undefined) {
      return;
    }

    setGroups([...groups, createGroupingFromField(field)]);
  };

  const setGroupAtIndex = (index: number, group: Grouping) => {
    setGroups([...groups.slice(0, index), group, ...groups.slice(index + 1)]);
  };

  const handleReorder = (fromIndex: number, toIndex: number) => {
    const tempGroups = [...groups.slice(0, fromIndex), ...groups.slice(fromIndex + 1)];
    setGroups([...tempGroups.slice(0, toIndex), groups[fromIndex], ...tempGroups.slice(toIndex)]);
  };

  const sortable = groups.length > 1;

  return (
    <div className={form.formHorizontal}>
      {groups.length === 0 && <span className={form.helpText}>Break values down by groups</span>}

      {groups.map((group, i) => (
        <Fragment key={i}>
          {i > 0 && <hr className={form.dashed} />}
          <SortableItem
            scope={'grouping'}
            index={i}
            draggedIndex={draggedIndex}
            setDraggedIndex={setDraggedIndex}
            onReorder={handleReorder}
            className={classNames(form.formRow, form.alignTop, {
              [form.sortableRow]: sortable,
            })}
            classNames={{
              dragBefore: form.dragBefore,
              dragAfter: form.dragAfter,
            }}>
            {sortable ? <Icon name="DragHandle" size={10} className={form.sortHandle} /> : null}
            <div className={classNames(form.formRow, form.alignTop)}>
              <label className={form.formLabel}>Group by</label>
              <GroupingSelect
                grouping={group}
                groupings={groups}
                fields={fields}
                onChange={(group) => setGroupAtIndex(i, group)}
                autoFocus={autoFocus}
                fullWidth
              />
            </div>
            <IconButton
              icon="Trash2"
              title="Remove group"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setGroups([...groups.slice(0, i), ...groups.slice(i + 1)]);
              }}
            />
          </SortableItem>
        </Fragment>
      ))}

      {groups.length < fields.length ? (
        <div>
          <InlineButton size="small" onClick={handleAddGroup}>
            <Icon name="Plus" size={15} /> {groups.length === 0 ? 'Group' : 'Subgroup'}
          </InlineButton>
        </div>
      ) : null}
    </div>
  );
};
