import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from 'react-router-dom';

import { AccountContextProvider, RedirectToAccount } from './lib/accounts/context';
import { useMeQuery } from './graphql';
import { useAuth } from './auth/auth-context';
import { LoginPage } from './public/login';
import { SignupPage } from './public/signup';
import { LogoutPage } from './public/logout';
import { VerifyEmailPage } from './public/verify-email';
import { ToastContextProvider } from './components/toast';
import { Explore } from './explore';
import { AccountSettingsPage } from './settings';
import { GenericFallback, ErrorBoundary } from './lib/error';
import { OnboardingDatabaseStep } from './onboarding';
import { OnboardingComplete } from './onboarding/step-complete';
import { OnboardingStart } from './onboarding/step-start';
import { OnboardingLegal } from './onboarding/step-legal';
import { WelcomeSurveyStep } from './onboarding/step-welcome-survey';
import { OnboardingSetupCallStep } from './onboarding/step-setup-call';
import { ConfirmEmailPage } from './public/confirm-email';
import { SidebarLayout } from './components/layout';
import { GradientLayout } from './components/gradient-layout';
import { RedirectToAlertExploration } from './alerts/redirect-to-alert-exploration';
import { FullPageLoader } from './components/loader';
import { addNextUrlParam, getNextUrl } from './public/utils/url';

import './styles/app.scss';

const App = () => {
  return (
    <ErrorBoundary fallback={<GenericFallback fullscreen />}>
      <ToastContextProvider>
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <>
                <Route element={<GradientLayout />}>
                  <Route element={<Unauthenticated />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                  </Route>
                  <Route path="/verify-email" element={<VerifyEmailPage />} />
                  <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                  <Route path="/logout" element={<LogoutPage />} />
                </Route>
                <Route element={<Authenticated />}>
                  <Route
                    path=":account/*"
                    element={
                      <AccountContextProvider>
                        <Routes>
                          <Route element={<SidebarLayout />}>
                            <Route
                              path="/alerts/:alertConfigurationId/exploration"
                              element={<RedirectToAlertExploration />}
                            />
                            <Route path="explore/*" element={<Explore />} />
                            <Route path="settings" element={<AccountSettingsPage />} />
                          </Route>
                          <Route path="/onboarding" element={<GradientLayout showLogout />}>
                            <Route index element={<OnboardingStart />} />
                            <Route path="legal" element={<OnboardingLegal />} />
                            <Route path="welcome-survey" element={<WelcomeSurveyStep />} />
                            <Route path="setup-call" element={<OnboardingSetupCallStep />} />
                            <Route path="connection" element={<OnboardingDatabaseStep />} />
                            <Route path="complete" element={<OnboardingComplete />} />
                          </Route>
                          <Route path="*" element={<Navigate to="/explore" replace />} />
                        </Routes>
                      </AccountContextProvider>
                    }
                  />
                  <Route index element={<RedirectToAccount />} />
                  <Route path="explore/*" element={<RedirectToAccount />} />
                  <Route path="settings/*" element={<RedirectToAccount />} />
                </Route>
              </>,
            ),
          )}
        />
      </ToastContextProvider>
    </ErrorBoundary>
  );
};

const Authenticated = () => {
  const { user } = useAuth();
  const location = useLocation();

  const { data, loading } = useMeQuery({
    skip: user === null,
  });

  if (loading) {
    return <FullPageLoader />;
  }

  if (user === null) {
    return <Navigate to={addNextUrlParam('/login', location)} />;
  }

  if (location.pathname !== '/verify-email' && !user.emailVerified) {
    return <Navigate to={addNextUrlParam('/verify-email', location)} />;
  }

  if (data?.me === undefined) {
    return <Navigate to={addNextUrlParam('/login', location)} />;
  }

  return <Outlet />;
};

export const Unauthenticated = () => {
  const { user } = useAuth();
  const location = useLocation();

  const { data, loading } = useMeQuery({
    skip: !user,
  });

  if (loading) {
    <FullPageLoader />;
  }

  if (data?.me !== undefined) {
    return <Navigate to={getNextUrl(location)} />;
  }

  return <Outlet />;
};

export default App;
