import { useEffect, useState } from 'react';

import { Exploration } from '../types';
import { useExplorationStorageContext } from './exploration-storage-context';

export const useSetExploration = () => {
  const { setExploration } = useExplorationStorageContext();

  return setExploration;
};

type UseGetExplorationState = [true, null] | [false, Exploration | null];

export const useGetExploration = (explorationId: string | null | undefined) => {
  const { getExploration } = useExplorationStorageContext();
  const [state, setState] = useState<UseGetExplorationState>([true, null]);

  useEffect(() => {
    let cancelled = false;

    const fetchExploration = async (eId: string) => {
      const exploration = await getExploration(eId);

      if (!cancelled) {
        setState([false, exploration ?? null]);
      }
    };

    if (explorationId === null || explorationId === undefined) {
      setState([false, null]);
      return;
    }

    fetchExploration(explorationId);

    return () => {
      cancelled = true;
    };
  }, [explorationId, getExploration]);

  return state;
};
