import { useEffect, useState } from 'react';

import { isNil } from '@/lib/utils';

const SCROLL_HINT_THRESHOLD = 5; //px

/**
 * Hook for displaying "overflowing hints" (darker shadows) at the edges of a horizontally
 * scrolling container element. The hints are displayed if it's possible to scroll in the
 * given edge direction (left/right).
 * Usage: Requires one non-scrolling wrapping element around the scrolling container.
 * Add ref={setWrapperElement} to the wrapping JSX element.
 * @param tableElement The table that may need scrolling
 * @param containerElement The scrolling container for the table
 */
export const useOverflowHints = (
  tableElement: HTMLElement | null | undefined,
  containerElement: HTMLElement | null,
  wrapperElement: HTMLElement | null,
): [boolean, boolean] => {
  const [shouldShowLeftHint, setShouldShowLeftHint] = useState<boolean>(false);
  const [shouldShowRightHint, setShouldShowRightHint] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(tableElement) || isNil(containerElement) || isNil(wrapperElement)) {
      return;
    }

    const updateHints = () => {
      const { width: containerWidth } = containerElement.getBoundingClientRect();
      setShouldShowLeftHint(containerElement.scrollLeft > SCROLL_HINT_THRESHOLD);
      setShouldShowRightHint(
        containerElement.scrollLeft <
          containerElement.scrollWidth - containerWidth - SCROLL_HINT_THRESHOLD,
      );
    };

    // Listen to container size
    const containerResizeObserver = new ResizeObserver(updateHints);
    containerResizeObserver.observe(containerElement);
    const tableResizeObserver = new ResizeObserver(updateHints);
    tableResizeObserver.observe(tableElement);
    containerElement.addEventListener('scroll', updateHints);

    return () => {
      containerResizeObserver.unobserve(containerElement);
      tableResizeObserver.unobserve(tableElement);
      containerElement.removeEventListener('scroll', updateHints);
    };
  });

  return [shouldShowLeftHint, shouldShowRightHint];
};
