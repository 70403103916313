import { useRef, useState } from 'react';
import classNames from 'classnames';
import { first, isNil, uniq } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { notNil } from '@/lib/utils';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import { NavigableListItemInput, useNavigableList } from '@/lib/hooks/use-navigable-list';

import { useMetadataContext } from '../metadata-context';
import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { getUnparameterizedExplorations } from '../utils';
import { usePersistCell, CopiedCell } from '../utils/use-persist-cell';
import { getHashSearchTerm, setHashSearchTerm } from '../utils/url';
import { Exploration } from '../types';
import { isModelExploration } from '../utils';
import { PipelinePreview } from '../exploration/pipeline-preview';

import {
  ReplicableCell,
  buildExplorationFromCells,
  cloneCell,
  explorationHasReplicableCells,
  extractCellFromExploration,
  isReplicableCell,
} from '../exploration/utils';
import { CollapsibleSection } from './collapsible-section';
import { CellList } from './cell-list';
import { ExplorationList } from './exploration-list';
import { SearchResults } from './search-results';
import { AddButton } from './add-button';
import { AddDropDownButton } from './add-dropdown-button';

import styles from './exploration-search.module.scss';

export type ExplorationListData = {
  title: string;
};

type CellListData = {
  title: string;
};

export type ExplorationListItemData = {
  exploration: Exploration;
};

export type CellListItemData = {
  cell: ReplicableCell;
};

export type ListItemData =
  | ExplorationListData
  | ExplorationListItemData
  | CellListData
  | CellListItemData;

interface ExplorationSearchProps {
  exploration?: Exploration;
  copiedCell?: CopiedCell;
  selectIndividualCells?: boolean;
  className?: string;
  onClickExploration?: (exploration: Exploration) => void;
  onPasteExploration?: () => void;
}

export const ExplorationSearch = (props: ExplorationSearchProps) => {
  const { explorations, models } = useMetadataContext();
  const {
    exploration,
    className,
    selectIndividualCells = false,
    onPasteExploration,
    onClickExploration,
  } = props;
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState(getHashSearchTerm(location.hash) ?? '');
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setResultsListFocusIndex = useRef<((index: number | null) => void) | undefined>();

  const {
    copiedCell,
    removeCopiedCell,
    copiedExploration,
    copiedExplorationPipeline,
    copiedExplorationTitle,
    copiedExplorationUrl,
  } = usePersistCell();

  const trimmedSearchTerm = searchTerm.trim();

  const savedExplorations = explorations.filter((exploration) => !isModelExploration(exploration));
  const modelExplorations = explorations.filter(isModelExploration);
  const modelSections = uniq(modelExplorations.map(({ labels }) => labels.section))
    .filter(notNil)
    .sort();
  const modelsWithoutSections = modelExplorations.filter(({ labels }) => isNil(labels.section));
  const unparameterisedExplorations = getUnparameterizedExplorations(savedExplorations);

  const { list: explorationList, setFocusIndex } = useNavigableList<ListItemData>({
    items: [
      exploration === undefined || !explorationHasReplicableCells(exploration)
        ? null
        : {
            data: { title: 'On This Page' },
            children: exploration?.view.cells.filter(isReplicableCell).map((cell) => ({
              isFocusable: true,
              data: { cell },
              onClick: (event) => {
                event.preventDefault();
                onClickExploration &&
                  onClickExploration(buildExplorationFromCells([cloneCell(cell)]));
              },
            })),
          },
      unparameterisedExplorations.length === 0
        ? null
        : {
            data: { title: 'Saved Explorations' },
            children: unparameterisedExplorations
              .filter(
                (exploration) =>
                  !selectIndividualCells ||
                  exploration.view.cells.filter(isReplicableCell).length > 0,
              )
              .map((exploration) => ({
                isCollapsed: selectIndividualCells,
                isFocusable: true,
                data: { exploration },
                collapseOnClick: selectIndividualCells,
                onClick: (event) => {
                  if (selectIndividualCells || onClickExploration) {
                    event.preventDefault();
                  }
                  if (!selectIndividualCells && onClickExploration) {
                    onClickExploration(exploration);
                  }
                },
                children: selectIndividualCells
                  ? exploration.view.cells.filter(isReplicableCell).map((cell) => ({
                      isFocusable: true,
                      data: { cell },
                      onClick: (event) => {
                        event.preventDefault();
                        const { cell: extractedCell, dependencies } = extractCellFromExploration(
                          cell.id,
                          exploration,
                        );
                        onClickExploration &&
                          onClickExploration(
                            buildExplorationFromCells([extractedCell, ...dependencies]),
                          );
                      },
                    }))
                  : [],
              })),
          },
      ...modelSections.map<NavigableListItemInput<ListItemData>>((section) => ({
        data: { title: section },
        children: modelExplorations
          .filter(({ labels }) => labels.section === section)
          .map((exploration) => ({
            isFocusable: true,
            data: { exploration },
            onClick: (event) => {
              if (onClickExploration) {
                event.preventDefault();
                onClickExploration(exploration);
              }
            },
          })),
      })),
      modelsWithoutSections.length === 0
        ? null
        : {
            data: { title: modelSections.length > 0 ? 'Other Data Models' : 'Raw Data Models' },
            children: modelsWithoutSections.map((exploration) => ({
              isFocusable: true,
              data: {
                exploration,
              },
              onClick: (event) => {
                if (onClickExploration) {
                  event.preventDefault();
                  onClickExploration(exploration);
                }
              },
            })),
          },
    ],
    listContainerRef: scrollContainerRef,
    onFocusMovedOutside: () => inputRef.current?.focus(),
  });

  const showResults = trimmedSearchTerm.length > 0;

  const updateFocusIndex = (index: number) => {
    if (showResults) {
      setResultsListFocusIndex.current?.(index);
    } else {
      setFocusIndex(index);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      updateFocusIndex(0);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      updateFocusIndex(-1);
    } else if (e.key === 'Enter') {
      e.preventDefault(); //  Prevent explicit newlines in textarea
    }
  };

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    const hash = setHashSearchTerm(location.hash, value);
    setSearchTerm(value);
    window.history.replaceState(null, '', `${location.pathname}#${hash}`);
  };

  const onThisPageListItem = first(explorationList);

  return (
    <Panel className={classNames(styles.panel, className)}>
      <section className={styles.explorationSearch}>
        <div className={styles.searchHeader}>
          <h1>What would you like to find?</h1>
          <div className={styles.searchInput}>
            <Icon name="Search" size={24} className={styles.searchIcon} />
            <div className={styles.textareaWrapper} data-value={searchTerm}>
              <textarea
                value={searchTerm}
                onChange={handleSearchTermChange}
                onKeyDown={handleKeyDown}
                placeholder='e.g. "Users who..."'
                autoFocus
                rows={1}
                ref={inputRef}
              />
            </div>
          </div>
        </div>
        <div className={styles.toolsList}>
          <AddButton type="funnel" onClick={onClickExploration} />
          <AddButton type="cohort" onClick={onClickExploration} />
          <AddButton type="variable" onClick={onClickExploration} />
          <AddButton type="text" onClick={onClickExploration} />
          <AddDropDownButton onClick={onClickExploration} />
        </div>
        {notNil(copiedCell) && copiedExploration && (
          <Link
            to={copiedExplorationUrl}
            className={classNames(styles.listItem, styles.copySection)}
            onClick={(event) => {
              removeCopiedCell();

              if (notNil(onPasteExploration)) {
                event.preventDefault();
                onPasteExploration();
              }
            }}>
            <Icon name="Clipboard" size={32} className={styles.icon} />
            <div className={styles.name}>Paste copied block</div>
            {copiedExplorationPipeline !== null && (
              <div className={styles.pipeline}>
                <PipelinePreview
                  models={models}
                  title={copiedExplorationTitle}
                  exploration={copiedExploration}
                  pipeline={copiedExplorationPipeline}
                />
              </div>
            )}
          </Link>
        )}
        {showResults ? (
          <SearchResults
            explorations={unparameterisedExplorations.concat(modelExplorations)}
            models={models}
            searchTerm={trimmedSearchTerm}
            exploration={exploration}
            onClickExploration={onClickExploration}
            setFocusIndexRef={setResultsListFocusIndex}
            onBlur={() => inputRef.current?.focus()}
          />
        ) : (
          <div
            className={classNames(styles.allExplorations, {
              [styles.scrolled]: isScrolled,
            })}
            ref={scrollContainerRef}>
            {exploration !== undefined && !isNil(onThisPageListItem) && (
              <CollapsibleSection
                title={onThisPageListItem.getData<CellListData>().title ?? 'On This Page'}
                isCollapsed={onThisPageListItem.getIsCollapsed()}
                onToggleCollapsed={onThisPageListItem.toggleCollapsed}>
                <CellList exploration={exploration} listItems={onThisPageListItem.getChildren()} />
              </CollapsibleSection>
            )}

            {explorationList.slice(1).map((item, i) => {
              if (item === null) {
                return;
              }
              return (
                <CollapsibleSection
                  key={i}
                  title={item.getData<ExplorationListData>().title ?? 'Models'}
                  isCollapsed={item.getIsCollapsed()}
                  onToggleCollapsed={item.toggleCollapsed}>
                  <ExplorationList listItems={item.getChildren()} hideSectionLabels />
                </CollapsibleSection>
              );
            })}
          </div>
        )}
      </section>
    </Panel>
  );
};

interface ExplorationSearchModalProps extends ExplorationSearchProps {
  onClose: () => void;
}

export const ExplorationSearchModal = ({ onClose, ...rest }: ExplorationSearchModalProps) => (
  <Modal onClose={onClose} closeOnClickAway closeOnEsc>
    <ExplorationSearch {...rest} />
  </Modal>
);
