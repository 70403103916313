import { Icon } from '@/components/icon';

import { DropdownMenuItem } from '@/components/dropdown';

import { chartColors } from '../components/charts/utils';
import { Visualisation } from '../types';

type BuildSeriesMenuItemsProps = {
  seriesKey: string;
  visualisation: Visualisation;
  isChartTypeDisabled: boolean;
  isSecondaryAxisDisabled: boolean;
  isSeriesRemovable: boolean;
  onSeriesChartTypeChange: (seriesKey: string, chartType: 'area' | 'line' | 'bar') => void;
  onSeriesColorChange: (seriesKey: string, color: string) => void;
  onSeriesRemove: (seriesKey: string) => void;
  onSecondaryAxisChange: (seriesKey: string, checked: boolean) => void;
  onShowValuesChange: (seriesKey: string, showValues: boolean) => void;
};

export const buildSeriesMenuItems = (props: BuildSeriesMenuItemsProps): DropdownMenuItem[] => {
  const {
    seriesKey,
    visualisation,
    isChartTypeDisabled,
    isSecondaryAxisDisabled,
    isSeriesRemovable,
    onSeriesChartTypeChange,
    onSeriesColorChange,
    onSeriesRemove,
    onSecondaryAxisChange,
    onShowValuesChange,
  } = props;

  const {
    chartType = 'area',
    showValues = false,
    color = chartColors[0],
  } = visualisation.viewOptions?.series?.[seriesKey] ?? {};

  return [
    {
      type: 'radio' as const,
      label: 'Area',
      checked: chartType === 'area',
      disabled: isChartTypeDisabled,
      onClick: () => onSeriesChartTypeChange(seriesKey, 'area'),
    },
    {
      type: 'radio' as const,
      label: 'Line',
      checked: chartType === 'line',
      disabled: isChartTypeDisabled,
      onClick: () => onSeriesChartTypeChange(seriesKey, 'line'),
    },
    {
      type: 'radio' as const,
      label: 'Bar',
      checked: chartType === 'bar',
      disabled: isChartTypeDisabled,
      onClick: () => onSeriesChartTypeChange(seriesKey, 'bar'),
    },
    {
      type: 'divider' as const,
    },
    {
      type: 'checkbox' as const,
      label: 'Use Secondary axis',
      checked: visualisation.viewOptions?.axes?.right?.keys?.includes(seriesKey),
      disabled: isSecondaryAxisDisabled,
      onChange: (isChecked: boolean) => onSecondaryAxisChange(seriesKey, isChecked),
    },
    {
      type: 'checkbox' as const,
      label: 'Show values',
      checked: showValues,
      onChange: (isChecked: boolean) => onShowValuesChange(seriesKey, isChecked),
    },
    {
      type: 'color' as const,
      label: 'Color',
      color,
      presetColors: chartColors,
      onChange: (color: string) => onSeriesColorChange(seriesKey, color),
    },
    {
      type: 'divider' as const,
    },
    ...(isSeriesRemovable
      ? [
          {
            label: 'Delete',
            onClick: () => onSeriesRemove(seriesKey),
            icon: <Icon name="Trash2" size={16} />,
          },
        ]
      : []),
  ];
};
