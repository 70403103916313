export const CodeIcon = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.595 3.196a.65.65 0 1 0-1.258-.329L5.74 12.804a.65.65 0 1 0 1.258.329zM4.462 4.539a.65.65 0 0 1 0 .919L1.92 8l2.542 2.542a.65.65 0 1 1-.92.92L.542 8.46a.65.65 0 0 1 0-.92L3.543 4.54a.65.65 0 0 1 .919 0M10.636 4.539a.65.65 0 0 1 .92 0l3 3.001a.65.65 0 0 1 0 .92l-3 3.001a.65.65 0 1 1-.92-.92L13.178 8l-2.542-2.542a.65.65 0 0 1 0-.92"
    />
  </svg>
);
