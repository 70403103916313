import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import GA from 'react-ga4';

GA.initialize([{ trackingId: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID }], {
  testMode: false,
});

const anonymizeAccount = (pathname: string, accountSlug: string) => {
  return pathname.replace(accountSlug, 'account');
};

export const useGAPageview = () => {
  const location = useLocation();
  const { account: accountSlug } = useParams();

  useEffect(() => {
    const pathname =
      accountSlug !== undefined
        ? anonymizeAccount(location.pathname, accountSlug)
        : location.pathname;

    GA.send({ hitType: 'pageview', page: pathname, title: document.title });
  }, [location, accountSlug]);
};
