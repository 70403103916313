import { MouseEventHandler, MouseEvent } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../../components/icon';
import {
  Token,
  OperationDescription,
  descriptionToString,
} from '../components/pipeline/operation-description';
import { IconButton } from '../../components/button';

import styles from './pipeline.module.scss';

interface OperationProps {
  icon: IconProps['name'];
  title: string;
  description: Token;
  onClick?: () => void;
  onRemove?: MouseEventHandler;
  onChangeDisabled?: (e: MouseEvent, disabled: boolean) => void;
  disabled?: boolean;
  invalid?: boolean;
}

export const Operation = (props: OperationProps) => {
  const {
    icon,
    title,
    description,
    onClick,
    onRemove,
    onChangeDisabled,
    disabled = false,
    invalid = false,
  } = props;
  const clickable = !disabled && Boolean(onClick);
  return (
    <div
      className={classNames(styles.operation, {
        [styles.clickable]: clickable,
        [styles.disabled]: disabled || invalid,
      })}
      onClick={clickable ? onClick : undefined}
      title={onClick ? 'Click to edit' : title}>
      <div className={styles.operationIcon}>
        <Icon name={icon} size={12} />
      </div>
      <div className={styles.operationTitle} title={title}>
        {title}
      </div>
      <div className={styles.operationDescription} title={descriptionToString(description)}>
        <OperationDescription
          description={description}
          classNames={{ expression: styles.expression }}
        />
      </div>
      <div />
      <div className={styles.actions}>
        {onChangeDisabled &&
          (disabled ? (
            <IconButton
              icon="EyeOff"
              title="Enable operation"
              className={classNames(styles.action, styles.highlight)}
              onClick={(event) => onChangeDisabled(event, false)}
            />
          ) : (
            <IconButton
              icon="Eye"
              title="Disable operation"
              className={styles.action}
              onClick={(event) => onChangeDisabled(event, true)}
            />
          ))}
        {onRemove && (
          <IconButton
            icon="Trash2"
            title="Remove operation"
            className={styles.action}
            onClick={onRemove}
          />
        )}
        {invalid ? (
          <span title="Invalid operation">
            <Icon name="AlertTriangle" size={16} color={styles.errorColor} />
          </span>
        ) : null}
      </div>
    </div>
  );
};
