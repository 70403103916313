import { compact, indexOf } from 'lodash';

import { Exploration, Pipeline, TimeAggregationPeriod } from '../types';
import { getCell } from '../exploration/utils';

export const getParentPipelineColor = (exploration: Exploration, pipeline: Pipeline) => {
  if (!('parentId' in pipeline)) {
    return;
  }

  return getPipelineColorByPipelineId(exploration, pipeline.parentId);
};

export const getPipelineColor = (exploration: Exploration, pipeline: Pipeline) => {
  if (pipeline.pipelineId === undefined) {
    return;
  }

  return getPipelineColorByPipelineId(exploration, pipeline.pipelineId);
};

const NumColors = 6;

const getPipelineColorByPipelineId = (exploration: Exploration, pipelineId: string) => {
  const pipelineIds = compact(
    exploration.view.cells.map((cell) =>
      'pipeline' in cell && 'pipelineId' in cell.pipeline ? cell.pipeline.pipelineId : undefined,
    ),
  );

  const pipelineIdIdx = indexOf(pipelineIds, pipelineId);
  return (pipelineIdIdx % NumColors) + 1;
};

export const getPipelineColorByCellId = (exploration: Exploration, cellId: string) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !('pipeline' in cell)) {
    return;
  }

  return getPipelineColor(exploration, cell.pipeline);
};

export const formatRelationLabel = ({
  relationName,
  relatedModelName,
  baseModelName,
}: {
  relationName: string;
  relatedModelName: string;
  baseModelName: string;
}) => `${relationName} (${relatedModelName}) on ${baseModelName}`;

export const getTimeAggregationPeriodLabel = (precision: TimeAggregationPeriod) => {
  switch (precision) {
    case 'hour':
      return 'Hour';
    case 'day':
      return 'Day';
    case 'week':
      return 'Week';
    case 'day_of_week':
      return 'Day of Week';
    case 'month':
      return 'Month';
    case 'quarter':
      return 'Quarter';
    case 'year':
      return 'Year';
    default:
      return precision;
  }
};
