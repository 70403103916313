import { useEffect } from 'react';

import { useTrackEvent } from '@/lib/analytics';
import { useSelectedAccount } from '@/lib/accounts/context';
import { useGiveConsentMutation } from '@/graphql';
import { useGAPageview } from '@/lib/ga/use-ga';

import { OnboardingStep } from '../types';
import { useOnboardingStepCheck } from '../use-onboarding-step-check';
import { ConsentSubject, ConsentsForm } from '../components/consents-form';

const CurrentStep: OnboardingStep = 'legal';

export const OnboardingLegal = () => {
  const { check: checkOnboardingState, loading: onboardingStateLoading } =
    useOnboardingStepCheck(CurrentStep);
  const trackEvent = useTrackEvent();
  const account = useSelectedAccount();
  useGAPageview();

  const [givingConsentMutation, { loading: givingConsent }] = useGiveConsentMutation();

  useEffect(() => {
    trackEvent('Onboarding Legal Step Opened', { accountId: account.accountId });
  }, [trackEvent, account.accountId]);

  const handleSubmit = async (acceptedSubjects: ConsentSubject[]) => {
    await givingConsentMutation({
      variables: {
        accountId: account.accountId,
        input: {
          subjects: acceptedSubjects,
        },
      },
    });

    trackEvent('Onboarding Legal Step Consents Given', {
      accountId: account.accountId,
      acceptedSubjects,
    });

    checkOnboardingState();
  };

  return <ConsentsForm onSubmit={handleSubmit} loading={onboardingStateLoading || givingConsent} />;
};
