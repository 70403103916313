import { Fragment } from 'react';

import { BasicLegendData, GroupedLegendData, LegendData } from './types';

import styles from './legend.module.scss';

type LegendProps = { data: LegendData };

export const Legend = ({ data }: LegendProps) =>
  data.type === 'basic' ? (
    <BasicLegend items={data.items} />
  ) : data.type === 'grouped' ? (
    <GroupedLegend data={data} />
  ) : null;

type SimpleLegendProps = {
  items: BasicLegendData['items'];
};
export const BasicLegend = ({ items }: SimpleLegendProps) => (
  <div className={styles.legend}>
    {items.map(({ label, color }, idx) => (
      <div className={styles.legendItem} key={idx} title={label}>
        <div className={styles.legendColor} style={{ backgroundColor: color }} />
        <div className={styles.legendLabel}>{label}</div>
      </div>
    ))}
  </div>
);

type GroupedLegendProps = {
  data: GroupedLegendData;
};

export const GroupedLegend = ({ data }: GroupedLegendProps) => (
  <div className={styles.groupedLegend}>
    {data.groups.map((group, groupIdx) =>
      'items' in group ? (
        <div className={styles.groupItem} key={groupIdx}>
          <div className={styles.groupLabel} title={group.label}>
            {group.label}
          </div>
          <BasicLegend items={group.items} />
        </div>
      ) : (
        <div className={styles.groupItem} key={groupIdx}>
          <div className={styles.groupLabel} title={group.label}>
            {group.label}
          </div>
          <div className={styles.valueItems}>
            {group.groups.map(({ label, items }, groupIdx) => (
              <Fragment key={groupIdx}>
                <div className={styles.valueLabel} title={label}>
                  {label}
                </div>
                <BasicLegend items={items} />
              </Fragment>
            ))}
          </div>
        </div>
      ),
    )}
  </div>
);
