import { useRef, useState } from 'react';

import { nth } from 'lodash';

import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';

import { useExplorationContext } from '../exploration-context';
import {
  canDropOnRow,
  getRowIdKey,
  isCellDragEvent,
  trackDropEvent,
  updateCellRowHeight,
} from './utils';

import { CellResizeArea } from './cell-resize-area';

import { useExplorationCellContext } from '../exploration-cell-context';

import styles from './exploration-layout.module.scss';

const CellIndexKey = `application/x-sup-drag-cell`;

const MinCellHeight = 60;
const MaxCellHeight = 5000;

interface DraggableCellProps {
  index: number;
  isDraggable: boolean;
  isResizable?: boolean;
  height?: number;
  heightOverride?: number;
  selected?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  setRowHeightOverride?: (height: number | undefined) => void;
}

export const DraggableCell = (props: DraggableCellProps) => {
  const {
    isDraggable,
    isResizable = true,
    selected = false,
    index,
    height,
    setRowHeightOverride,
    heightOverride,
  } = props;
  const [isDragged, setIsDragged] = useState(false);
  const [isResized, setIsResized] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [dragStartHeight, setDragStartHeight] = useState<number | undefined>(undefined);
  const [side, setSide] = useState<'left' | 'right'>('left');
  const elementRef = useRef<HTMLDivElement>(null);

  const { exploration, moveCell, setExploration } = useExplorationContext();
  const { toggleCollapsed, isCollapsed } = useExplorationCellContext();
  const trackEvent = useTrackEvent();

  const cell = nth(exploration.view.cells, index);

  if (cell === undefined) {
    return props.children;
  }

  const appliedHeight = isCollapsed ? undefined : (heightOverride ?? height);

  const heightValue = appliedHeight === undefined ? 'auto' : `${appliedHeight}px`;

  const containerStyles = {
    height: heightValue,
    maxHeight: isCollapsed || appliedHeight !== undefined ? heightValue : 'none',
    ...props.style,
  };

  const setCellHeight = (newHeight: number | undefined) => {
    setExploration({
      ...exploration,
      view: {
        ...exploration.view,
        cells: updateCellRowHeight(exploration.view.cells, index, newHeight),
      },
    });
  };

  return (
    <div
      style={containerStyles}
      className={classNames(styles.draggableCell, {
        [styles.isDragOver]: isDragOver,
        [styles.isResized]: isResized,
        [styles.droppableLeft]: isDragOver && !isDragged && side === 'left',
        [styles.droppableRight]: isDragOver && !isDragged && side === 'right',
      })}
      data-id={cell.id}
      ref={elementRef}
      draggable={isDraggable}
      onDragStart={(event) => {
        event.stopPropagation(); // Allow nested draggables
        event.dataTransfer.clearData();
        event.dataTransfer.setData(CellIndexKey, index.toString());
        event.dataTransfer.setData(getRowIdKey(cell), 'true');
        event.dataTransfer.effectAllowed = 'all';
        setIsDragged(true);
      }}
      onDragEnd={() => {
        setIsDragOver(false);
        setIsDragged(false);
      }}
      onDragOver={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          event.dataTransfer.dropEffect = 'none';
          return;
        }
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const xPos = (event.clientX - rect.left) / rect.width;
        const currentSide = xPos > 0.5 ? 'right' : 'left';
        if (currentSide !== side) {
          setSide(currentSide);
        }
      }}
      onDragEnter={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          return;
        }
        event.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(event) => {
        if (elementRef.current && !elementRef.current.contains(event.relatedTarget as Node)) {
          setIsDragOver(false);
        }
      }}
      onDrop={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          return;
        }
        setIsDragOver(false);
        setIsDragged(false);
        const droppedCellIndex = parseInt(event.dataTransfer.getData(CellIndexKey));
        if (droppedCellIndex === index) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        moveCell(droppedCellIndex, index, side === 'right', true, elementRef.current?.clientHeight);
        trackDropEvent(
          exploration,
          droppedCellIndex,
          index,
          nth(exploration.view.cells, droppedCellIndex),
          side === 'right',
          true,
          trackEvent,
        );
      }}>
      {props.children}
      {isResizable && (
        <CellResizeArea
          isCellSelected={selected}
          onResizeStart={() => {
            const currentHeight = elementRef.current?.clientHeight ?? undefined;
            setIsResized(true);
            setDragStartHeight(currentHeight);
            if (isCollapsed) {
              toggleCollapsed();
            }
          }}
          onResize={(dy) => {
            setRowHeightOverride?.(
              Math.max(MinCellHeight, Math.min(MaxCellHeight, (dragStartHeight ?? 500) + dy)),
            );
          }}
          onResizeEnd={() => {
            setIsResized(false);
            if (elementRef.current === null || heightOverride === undefined) {
              return;
            }
            height !== heightOverride && setCellHeight(heightOverride);
            setRowHeightOverride?.(undefined);
          }}
          onResetSize={() => {
            height !== undefined && setCellHeight(undefined);
          }}
        />
      )}
    </div>
  );
};
