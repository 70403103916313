import { common } from '@gosupersimple/types';

import { compact } from 'lodash';

import {
  Aggregation,
  CompositeFilterCondition,
  DereferencedPipelineOperation,
  ExpressionAggregation,
  FilterCondition,
  GroupAggregateOperation,
  KeyedAggregation,
  MetricAggregation,
  PipelineOperation,
  SortedAggregation,
  SwitchToRelationOperation,
} from '../types';

export const isKeyedAggregation = (aggregation: Aggregation): aggregation is KeyedAggregation =>
  'key' in aggregation && aggregation.key !== undefined;

export const isExprAggregation = (aggregation: Aggregation): aggregation is ExpressionAggregation =>
  aggregation.type === 'custom';

export const isMetricAggregation = (aggregation: Aggregation): aggregation is MetricAggregation =>
  aggregation.type === 'metric';

export const isSortedAggregation = (aggregation: Aggregation): aggregation is SortedAggregation =>
  'sort' in aggregation;

export const isSingleFilterCondition = (
  condition: FilterCondition | CompositeFilterCondition,
): condition is FilterCondition => !('operands' in condition);

export const isValueExpression = (value: unknown): value is common.ExpressionValue => {
  return typeof value === 'object' && value !== null && 'expression' in value;
};

export const isValidOperation = (operation: PipelineOperation): boolean =>
  operation.operation !== 'invalid';

export const containsInvalidOperations = (operations: PipelineOperation[]): boolean =>
  !operations.every(isValidOperation);

export const isGroupAggregateOperation = (
  operation: PipelineOperation | DereferencedPipelineOperation,
): operation is GroupAggregateOperation => operation.operation === 'groupAggregate';

export const isSwitchToRelationOperation = (
  operation: PipelineOperation | DereferencedPipelineOperation,
): operation is SwitchToRelationOperation => operation.operation === 'switchToRelation';

export const containsGroupAggregateOperation = (operations: DereferencedPipelineOperation[]) =>
  operations.some(isGroupAggregateOperation);

export const getLastGroupAggregate = (operations: DereferencedPipelineOperation[]) =>
  operations.findLast(isGroupAggregateOperation);

export const getLastGroupAggregateIndex = (operations: DereferencedPipelineOperation[]) =>
  operations.findLastIndex(isGroupAggregateOperation);

export const getLastSwitchToRelationIndex = (operations: DereferencedPipelineOperation[]) =>
  operations.findLastIndex(isSwitchToRelationOperation);

export const getCompositeConditionKeys = (condition: CompositeFilterCondition): string[] => {
  if ('operands' in condition) {
    return condition.operands.flatMap(getCompositeConditionKeys);
  }
  return [condition.key];
};

export const getAggregationKeys = (aggregations: Aggregation[]): string[] => {
  return compact([
    ...aggregations.filter(isKeyedAggregation).map((aggregation) => aggregation.key),
    ...aggregations
      .filter(isSortedAggregation)
      .flatMap((aggregation) =>
        aggregation.sort !== undefined ? getSortingKeys(aggregation.sort) : null,
      ),
  ]);
};

export const getSortingKeys = (sorting: common.Sorting[]) => {
  return sorting.map((sort) => sort.key);
};

export const getGroupingKeys = (grouping: common.Grouping[]) => {
  return grouping.map((group) => group.key);
};

export const getOperationPropertyKeys = (
  operation: PipelineOperation | DereferencedPipelineOperation,
) => {
  switch (operation.operation) {
    case 'deriveField':
      return [operation.parameters.key];
    case 'addRelatedColumn':
      return operation.parameters.columns.map((column) => column.property.key);
    case 'groupAggregate':
      return operation.parameters.aggregations.map((aggregation) => aggregation.property.key);
    case 'relationAggregate':
      return operation.parameters.aggregations.map((aggregation) => aggregation.property.key);
    default:
      return [];
  }
};
